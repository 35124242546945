import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const ImageWrapper = styled.div`
    // height: 120px;
    padding: 16px 0 20px;
    position: relative;
`;

export const IconButtonWrapper = styled(({ imageSet, ...props }) => <IconButton {...props} />)`
    position: absolute;
    left: 4rem;
    color: #fff;
    display: ${(props) => (props.imageSet ? '' : 'none')};
`;

export const Image = styled(({ ...props }) => <img {...props} />)`
    height: 100%;
`;
