import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    IconButton,
    Stack,
    TextField
} from '@mui/material';
import { IconDismissSquare, IconEdit } from 'constants/icons';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import ImageCropperDialog from 'hooks/common/useImageCropperDialog';
import { v4 as uuidv4 } from 'uuid';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { getVideoCover } from './useVideoImage';
import VideoDialog from 'hooks/common/useVideoDialog';

type imageModalProps = {
    openDialog: boolean;
    photos: [];
    settingPhoto: () => void;
    handleDialogClose: () => void;
    multiple?: boolean;
    showisCoverField?: boolean;
    labelAddnewImageBtn?: string;
    labelSaveBtn?: string;
    limitUpload?: number;
    savePhoto?: () => void;
    gallery?: boolean;
};

const CHARACTER_LIMIT = 150;

const ImageUploadDialog = ({
    openDialog,
    photos,
    settingPhoto,
    handleDialogClose,
    multiple = false,
    showisCoverField = true,
    labelAddnewImageBtn = 'Add More Media',
    labelSaveBtn = 'Save Changes',
    limitUpload = 7,
    savePhoto,
    gallery = false
}) => {
    const [images, setImages] = useState<any>([]);
    const [isHasVideo, setIsVideo] = useState(false);
    const [openCropper, setOpenCropper] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const hasVideo = images.filter((item) => item.isVideo);
        if (hasVideo.length === 1) {
            setIsVideo(true);
        } else {
            setIsVideo(false);
        }
    }, [images]);

    useEffect(() => {
        setImages(photos);
        // settingPhoto(photos);
        // images?.map((media, ind) => {
        //     const imageURL = media.url || URL.createObjectURL(media.file);
        //     const isVideo = media.file.type.startsWith('video/');
        //     if (isVideo) {
        //         getVideoCover(imageURL, 4)
        //             .then((thumbnail) => {
        //                 console.log(thumbnail);
        //                 setVideoUrl(thumbnail);
        //                 // videoURL = thumbnail;
        //             })
        //             .catch((error) => {
        //                 console.error(error); // Handle errors if any occur
        //             });
        //     }
        // });
    }, [photos]);

    useEffect(() => {
        if (!openDialog) {
            settingPhoto([]);
        }
    }, [openDialog]);

    const onclickedRemoveImage = (index) => {
        const newImages = images.filter((img, k) => {
            if (index === k && img.isVideo) {
                setIsVideo(false);
            }
            return index !== k;
        });
        setImages(newImages);
        settingPhoto(newImages);
    };

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState<any>({});
    const [videoDialogOpen, setVideoDialogOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState<any>('');

    const handleCropperOpen = (media, imageURL, index) => {
        setSelectedIndex(index);
        setSelectedImage(imageURL);
        setSelectedMedia(media);
        setOpenCropper(true);
    };

    const handleCropperClose = () => {
        setOpenCropper(false);
    };

    const playVideo = (url) => {
        setVideoUrl(url);
        setVideoDialogOpen(true);
    };

    const closeVideo = (ind) => {
        setVideoDialogOpen(false);
    };

    const handleCroppedData = async (cropDataBlob) => {
        const fileData = new File([cropDataBlob], `${uuidv4()}-cropped_image.png`, { type: 'image/png' });
        await setImages((prvImages) => {
            const allImages = [...prvImages];
            const newImage = {
                file: fileData,
                caption: selectedMedia.caption || '',
                isCover: selectedMedia.isCover || false,
                imageURL: URL.createObjectURL(cropDataBlob)
            };
            allImages[selectedIndex] = newImage;
            return allImages;
        });
        await settingPhoto((prvImages) => {
            const allImages = [...prvImages];
            const newImage = {
                file: fileData,
                caption: selectedMedia.caption || '',
                isCover: selectedMedia.isCover || false,
                imageURL: URL.createObjectURL(cropDataBlob)
            };
            allImages[selectedIndex] = newImage;
            return allImages;
        });
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            disableEnforceFocus
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        >
            {openDialog && (
                <>
                    <form>
                        <DialogTitle>Add media</DialogTitle>
                        <DialogContent>
                            <Stack className="media-upload">
                                {images &&
                                    images.map((media: any, ind) => {
                                        const imageURL = media.url || media.thumbnail || URL.createObjectURL(media.file);

                                        return (
                                            <Card sx={{ maxWidth: 345, display: 'inline-block', position: 'relative' }} key={ind}>
                                                <Box
                                                    component="span"
                                                    className="image-upload-dialog-remove-icon"
                                                    onClick={() => onclickedRemoveImage(ind)}
                                                >
                                                    <IconDismissSquare />
                                                </Box>
                                                {media.file && !media.isVideo && (
                                                    <Box
                                                        component="span"
                                                        className="image-upload-dialog-edit-icon"
                                                        onClick={() => handleCropperOpen(media, imageURL, ind)}
                                                    >
                                                        <IconEdit htmlColor="#CB3E19" />
                                                    </Box>
                                                )}

                                                {/* <CardMedia
                                                    crossOrigin="anonymous"
                                                    component="img"
                                                    width={345}
                                                    height={345}
                                                    image={imageURL}
                                                    alt={media.caption}
                                                    sx={{ borderRadius: '16px' }}
                                                /> */}

                                                {media.isVideo ? (
                                                    // <video height="194" width="100%" controls>
                                                    //     <track
                                                    //         kind="captions"
                                                    //         src="path/to/caption-file.vtt"
                                                    //         srcLang="en"
                                                    //         label="English Captions"
                                                    //         default
                                                    //     />
                                                    //     <source src={`${imageURL}`} type="video/mp4" />
                                                    //     Your browser does not support the video tag.
                                                    // </video>
                                                    <Box className="video-wrap">
                                                        <IconButton
                                                            aria-label="Play"
                                                            color="secondary"
                                                            onClick={() => playVideo(media.vurl || URL.createObjectURL(media.file))}
                                                        >
                                                            <PlayArrowRoundedIcon
                                                                sx={{
                                                                    color: '#cb3e19',
                                                                    position: 'absolute',
                                                                    zIndex: 1
                                                                }}
                                                            />
                                                        </IconButton>
                                                        <img
                                                            height="194"
                                                            src={`${media.imageURL || imageURL}`}
                                                            srcSet={`${media.imageURL || imageURL}`}
                                                            alt={media.caption}
                                                        />
                                                    </Box>
                                                ) : (
                                                    !media.isVideo && (
                                                        <CardMedia
                                                            crossOrigin="anonymous"
                                                            component="img"
                                                            width={345}
                                                            height={345}
                                                            image={imageURL}
                                                            alt={media.caption}
                                                            sx={{ borderRadius: '16px' }}
                                                        />
                                                    )
                                                )}

                                                <CardContent
                                                    sx={{
                                                        paddingLeft: '0px !important',
                                                        paddingRight: '0px !important'
                                                    }}
                                                >
                                                    {showisCoverField && (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={images[ind]?.isCover}
                                                                    name="isCover[]"
                                                                    color="primary"
                                                                    onChange={(event) => {
                                                                        const newimages = [...images];
                                                                        newimages.forEach((img, i) => {
                                                                            if (ind === i) newimages[i].isCover = true;
                                                                            else newimages[i].isCover = false;
                                                                        });
                                                                        setImages(newimages);
                                                                        settingPhoto(newimages);
                                                                    }}
                                                                />
                                                            }
                                                            label="Make cover image"
                                                        />
                                                    )}
                                                    <TextField
                                                        inputProps={{ maxLength: CHARACTER_LIMIT }}
                                                        fullWidth
                                                        placeholder="Caption"
                                                        value={media?.caption}
                                                        name="caption[]"
                                                        onChange={(event) => {
                                                            const newimages = [...images];
                                                            newimages[ind].caption = event.target.value;
                                                            setImages(newimages);
                                                            settingPhoto(newimages);
                                                        }}
                                                        helperText={`${media?.caption?.length}/${CHARACTER_LIMIT}`}
                                                        sx={{
                                                            '& .MuiFormHelperText-root': {
                                                                textAlign: 'right !important'
                                                            }
                                                        }}
                                                    />
                                                </CardContent>
                                            </Card>
                                        );
                                    })}
                            </Stack>
                            <Stack>
                                <Button
                                    variant="outlined"
                                    component={FormLabel}
                                    size="large"
                                    color="secondary"
                                    className="image-upload-button"
                                    disabled={images.length === limitUpload}
                                >
                                    <input
                                        multiple={multiple}
                                        type="file"
                                        name="image"
                                        accept={`image/png, image/gif, image/jpeg, ${!isHasVideo && 'video/mp4, video/mov'}`}
                                        id="image"
                                        onChange={async (event) => {
                                            invariant(event.target.files, 'file not empty');
                                            const totalImage = images.length + event.target.files.length;

                                            const vid = Array.from(event.target.files).map((item) => item.type.split('/')[0]);
                                            const findVId = images.filter((item) => item?.isVideo);

                                            const numberofVid = vid.filter((value) => {
                                                return value === 'video';
                                            }).length;

                                            if (((vid.includes('video') && findVId.length) || numberofVid > 1) && gallery) {
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        // eslint-disable-next-line
                                                        message: "Can't select more than one video.",
                                                        anchorOrigin: { horizontal: 'center' },
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'error'
                                                        }
                                                    })
                                                );
                                                return;
                                            }

                                            if (totalImage > limitUpload) {
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        message: `Upload Files limit exceeded. Please limit to ${limitUpload} files only.`,
                                                        anchorOrigin: { horizontal: 'center' },
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'error'
                                                        }
                                                    })
                                                );
                                            } else if (totalImage > 0 && totalImage <= limitUpload) {
                                                Array.from(event.target.files).forEach(async (img) => {
                                                    const isVideo = img.type.startsWith('video/');
                                                    const url = URL.createObjectURL(img);
                                                    const imageURL = isVideo ? await getVideoCover(url, 4) : url;

                                                    // Check and handle video duration if it's a video
                                                    if (isVideo) {
                                                        const video = document.createElement('video');
                                                        video.preload = 'metadata';
                                                        video.onloadedmetadata = function () {
                                                            if (video.duration <= 120) {
                                                                // If video duration is less than or equal to 2 mins, add it to state
                                                                setImages((prvImages) => [
                                                                    ...prvImages,
                                                                    {
                                                                        file: img,
                                                                        caption: '',
                                                                        isCover: false,
                                                                        isVideo,
                                                                        imageURL
                                                                    }
                                                                ]);
                                                                settingPhoto((prevPhotos) => [
                                                                    ...prevPhotos,
                                                                    {
                                                                        file: img,
                                                                        caption: '',
                                                                        isCover: false,
                                                                        isVideo,
                                                                        imageURL
                                                                    }
                                                                ]);
                                                            } else {
                                                                // Show an alert or handle the case when the video exceeds duration limit
                                                                alert('Video duration should be less than 2 minutes.');
                                                            }
                                                        };
                                                        video.src = url;
                                                    } else {
                                                        // For images, directly add them to state
                                                        setImages((prvImages) => [
                                                            ...prvImages,
                                                            {
                                                                file: img,
                                                                caption: '',
                                                                isCover: false,
                                                                isVideo,
                                                                imageURL
                                                            }
                                                        ]);
                                                        settingPhoto((prevPhotos) => [
                                                            ...prevPhotos,
                                                            {
                                                                file: img,
                                                                caption: '',
                                                                isCover: false,
                                                                isVideo,
                                                                imageURL
                                                            }
                                                        ]);
                                                    }
                                                });
                                            }
                                        }}
                                        hidden
                                    />
                                    <span>{labelAddnewImageBtn || 'Add more media'}</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    style={{ marginBottom: '10px' }}
                                    onClick={() => {
                                        savePhoto();
                                        handleDialogClose();
                                    }}
                                >
                                    <span>{labelSaveBtn || 'Save changes'}</span>
                                </Button>
                            </Stack>
                            <>
                                {openCropper && (
                                    <ImageCropperDialog
                                        openDialog={openCropper}
                                        imageUrl={selectedImage}
                                        handleCroppedData={handleCroppedData}
                                        handleDialogClose={() => {
                                            handleCropperClose();
                                        }}
                                    />
                                )}
                            </>
                        </DialogContent>
                    </form>
                    <VideoDialog
                        openDialog={videoDialogOpen}
                        // photos={photo}
                        // settingPhoto={setPhoto}
                        handleDialogClose={closeVideo}
                        imageUrl={videoUrl}
                    />
                </>
            )}
        </Dialog>
    );
};

export default ImageUploadDialog;
