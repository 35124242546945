import React, { Ref } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, CardProps, CardHeaderProps, CardContentProps } from '@mui/material';

// project imports
import { KeyedObject } from 'types';
import SearchIcon from '@mui/icons-material/Search';

// constant
// const headerSX = {
//     flexDirection: { xs: 'column', sm: 'row' },
//     justifyContent: { xs: 'flex-start', sm: 'space-between' },
//     alignItems: { xs: 'flex-start', sm: 'center' },
//     gap: 2
// };

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
    border?: boolean;
    boxShadow?: boolean;
    children: React.ReactNode | string;
    style?: React.CSSProperties;
    content?: boolean;
    className?: string;
    contentClass?: string;
    mainClass?: string;
    headerSX?: CardContentProps['sx'];
    contentSX?: CardContentProps['sx'];
    darkTitle?: boolean;
    sx?: CardProps['sx'];
    secondary?: CardHeaderProps['action'];
    shadow?: string;
    elevation?: number;
    title?: React.ReactNode | string;
}

const MainCard = React.forwardRef(
    (
        {
            border = false,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            mainClass = '',
            headerSX = {},
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            filter,
            ...others
        }: MainCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();

        return (
            <Card
                className={mainClass}
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                              (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        title={
                            <Typography sx={headerSX} variant="h3">
                                {title}
                            </Typography>
                        }
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        title={
                            <Typography sx={headerSX} variant="h3">
                                {title}
                            </Typography>
                        }
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {/* {title && <Divider />} */}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
function styled(arg0: string) {
    throw new Error('Function not implemented.');
}

function alpha(white: any, arg1: number) {
    throw new Error('Function not implemented.');
}
