import { CardContent, Grid, TextField, InputAdornment, Button, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

type Props = {
    debouncedSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void;
};

export const FilterList = ({ debouncedSearch }: Props) => {
    return (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center" spacing={2}>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className="app-user-account-btn"
                component={Link}
                to="/enquiryform-templates/add"
            >
                Add new
            </Button>
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: '1rem' }} />
                        </InputAdornment>
                    )
                }}
                onChange={debouncedSearch}
                placeholder="Search"
                size="small"
                className="SearchField"
            />
        </Stack>
    );
};
