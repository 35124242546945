import { useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import { FETCH_PUT_PRESIGN_URL } from 'grapqhl/aws/mutations';
import { FETCH_GET_PRESIGNURL } from 'grapqhl/aws/queries';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

const useImageUpload = () => {
    const [getAWSUploadURL, { loading }] = useMutation(FETCH_PUT_PRESIGN_URL);
    const [getBucketURL, { loading: awsLoading, data: awsData, refetch }] = useLazyQuery(FETCH_GET_PRESIGNURL, {
        variables: {
            key: 'null'
        }
    });

    const dispatch = useDispatch();

    const handleUpload = async (file, key) => {
        getAWSUploadURL({
            variables: {
                presignedUrlInput: {
                    key,
                    contentType: file.type
                }
            },
            onCompleted: async (getAWSURL) => {
                // upload to url
                const awsURL = await getAWSURL?.FetchPutPresignedUrl?.url;
                try {
                    await axios
                        .put(awsURL, file, {
                            headers: {
                                'Content-Type': `${file.type}`
                            }
                        })
                        .then(async (res) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Image uploaded successful!',
                                    anchorOrigin: { horizontal: 'center' },
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    }
                                })
                            );
                        })
                        .catch((axiosError) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: axiosError.message || 'Image uploading failed. Try again...',
                                    anchorOrigin: { horizontal: 'center' },
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    }
                                })
                            );
                        });
                } catch (error: any) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message || 'Image uploading failed. Try again...',
                            anchorOrigin: { horizontal: 'center' },
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            }
                        })
                    );
                }
            },
            onError: (awsError) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: awsError.message || 'Image uploading failed. Try again...',
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        });
    };

    const getImageFromAWS = async (key, thumb = false) => {
        if (key) {
            if (thumb) {
                const sep_key = key.split('/');
                key = `${sep_key.slice(0, -1).join('/')}/thumb/msmall/${sep_key.slice(-1)}`;
            }
            // to check whether image or video
            const distinct_key = key.split('/');
            // key = `${distinct_key.slice(0, -1).join('/')}/thumb/msmall/${distinct_key.slice(-1)}`;
            if (distinct_key[1] === 'videos') {
                const new_key = `${distinct_key.slice(0, -1).join('/')}/video-thumb/${distinct_key.slice(-1)}`;
                const lastPart = new_key.split('.');
                const fileExtension = lastPart[lastPart.length - 1];
                key = new_key.replace(fileExtension, 'png');
            }
            const { data: refetchData } = await refetch({ key });
            if (refetchData && refetchData?.FetchGetPresignedUrl?.url) {
                return refetchData?.FetchGetPresignedUrl?.url;
            }
        }
        return process.env.REACT_APP_IMAGE_PLACEHOLDER;
    };

    const getVideoFromAWS = async (key, thumb = false) => {
        if (key) {
            if (thumb) {
                const sep_key = key.split('/');
                key = `${sep_key.slice(0, -1).join('/')}/thumb/msmall/${sep_key.slice(-1)}`;
            }
            // to check whether image or video

            const { data: refetchData } = await refetch({ key });
            if (refetchData && refetchData?.FetchGetPresignedUrl?.url) {
                return refetchData?.FetchGetPresignedUrl?.url;
            }
        }
        return process.env.REACT_APP_IMAGE_PLACEHOLDER;
    };

    return { handleUpload, getImageFromAWS, getVideoFromAWS };
};

export default useImageUpload;
