import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialStateProps {
    page: number;
    limit: number;
    searchText: string | null;
    filter: string[];
    fromDate: string | null;
    toDate: string | null;
}

const initialState: InitialStateProps = {
    page: 1,
    limit: 10,
    searchText: null,
    filter: [],
    fromDate: null,
    toDate: null
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,

    reducers: {
        setLimit: (state, action: PayloadAction<number>) => {
            state.limit = action.payload;
        },

        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },

        setSearchText: (state, action: PayloadAction<string>) => {
            state.page = 1;
            state.searchText = action.payload;
        },
        setFilter: (state, action: PayloadAction<string[] | null>) => {
            state.page = 1;
            if (action.payload?.length) {
                state.filter = action.payload;
            } else {
                state.filter = [];
            }
        },
        setFromDate: (state, action: PayloadAction<string | null>) => {
            state.page = 1;
            state.fromDate = action.payload;
        },
        setToDate: (state, action: PayloadAction<string | null>) => {
            state.page = 1;
            state.toDate = action.payload;
        },
        resetReportsSlice: () => initialState
    }
});

export const { setLimit, setPage, setSearchText, setFilter, setFromDate, setToDate, resetReportsSlice } = reportSlice.actions;

export const selectReport = (state: any) => state.report;

export default reportSlice.reducer;
