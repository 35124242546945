/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import PinAnimalTable from 'views/animals/tables/pinAnimalTable';
import BreedersTable from './breeders-table';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { orderBy } from 'lodash';
import { headCellsPinAnimal } from 'views/animals/constants/variables';
import { orange } from '@mui/material/colors';
import { IconMenu2 } from '@tabler/icons';
import { IconDelete } from 'constants/icons';
import moment from 'moment';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from 'hooks/common/useSnackbar';
import useGQL from 'hooks/useGQL';
import { GET_PIN_BREEDS } from 'grapqhl/pinanimals/quries';
import { AppUser } from './constants/types';
import { headCellsBreedersPin } from './constants/header-cell';
import useImageUpload from 'hooks/common/useImageUpload';
import { useAWS } from 'hooks/aws/useAWS';
import Spinner from 'components/spinner';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { Animal } from 'views/animals/constants/types';

const PinPets = () => {
    const [pets, setPets] = useState<Animal[]>([]);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const { PINNED_PETS_LIST, UNPIN_PETS_MUTATION, UPDATE_PETS_INDEX_MUTATION } = useGQL();
    const [handleIndex, { loading: lodingUpdate }] = UPDATE_PETS_INDEX_MUTATION();
    const [handleUnpin, { loading: unpinLoading }] = UNPIN_PETS_MUTATION();
    const { data: petsData, loading: petsLoading, refetch } = PINNED_PETS_LIST({ limit: 50, skip: 0 });
    const { getImageFromAWS } = useImageUpload();
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handelUnPinSubmit = async (id: string) => {
        setPageLoading(true);
        const breedersnew = pets.filter((item) => item._id != id);
        setPets(breedersnew);
        await handleUnpin({
            variables: {
                id: `${id}`
            },
            onCompleted: (updateImages) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: updateImages.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            },
            onError: (updateImageError: any) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: updateImageError?.message || "Pets Can't be pinnes",
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        });
        setPageLoading(false);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const temp = [...pets];
        const reorderAnimalList: any[] = reorder(temp, result.source.index, result.destination.index);
        setPets(reorderAnimalList);

        const { data } = await handleIndex({
            variables: {
                input: { id: result.draggableId, index: result.destination.index + 1 }
            }
        });
        const mapped = data?.ChangePetsIndex?.salesAnimalList?.map(async (item) => {
            const media = item.media.find((mediaItem, index) => mediaItem.isCover === true || index === 0);
            return {
                imageUrl: await getImageFromAWS(media.file),
                ...item
            };
        });

        Promise.all(mapped)
            .then((results) => {
                setPets(results);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    useEffect(() => {
        if (petsData?.GetAppPinPets?.salesAnimalList) {
            const mapped = petsData?.GetAppPinPets?.salesAnimalList?.map(async (item) => {
                const medias = item.media.find((mediaItem, index) => mediaItem.isCover === true || index === 0);
                return {
                    imageUrl: await getImageFromAWS(medias?.file),
                    ...item
                };
            });

            Promise.all(mapped)
                .then((results) => {
                    setPets(results);
                })
                .catch((e) => {
                    console.error(e);
                });
            setPageLoading(false);
        }
    }, [petsData?.GetAppPinPets]);

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    return (
        <TableContainer>
            <Table sx={{ minWidth: 750, 'td:first-child,th:first-child': { display: '' } }} aria-labelledby="tableTitle">
                <TableHead>
                    <TableRow>
                        {headCellsPinAnimal.map((headCell) => {
                            if (headCell.id === 'action') {
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        <Box component="span">{headCell.label}</Box>
                                    </TableCell>
                                );
                            }
                            return (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    <TableSortLabel>{headCell.label}</TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                {/* table body */}
                {pageLoading ? (
                    <Spinner />
                ) : (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <StrictModeDroppable droppableId="animalList">
                            {(provided) => (
                                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                    {pets.length !== 0 &&
                                        pets.map((row, animalIndex) => {
                                            const isItemSelected = isSelected(animalIndex);

                                            return (
                                                // @ts-ignore
                                                <Draggable index={animalIndex} draggableId={row._id} key={row._id}>
                                                    {(draggableProvided) => (
                                                        <TableRow
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            onClick={(event) => handleClick(event, animalIndex)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            sx={{
                                                                position: 'relative !important',
                                                                top: '0 !important',
                                                                left: '0 !important'
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                <Stack justifyContent="center">
                                                                    <IconMenu2 />
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Avatar
                                                                    sx={{ bgcolor: orange[500] }}
                                                                    alt="Avatar1"
                                                                    // @ts-ignore
                                                                    src={row.imageUrl || process.env.REACT_APP_IMAGE_PLACEHOLDER}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">{row.listingName}</TableCell>
                                                            <TableCell align="left">{row.animalType}</TableCell>
                                                            <TableCell align="left">{row.breed}</TableCell>
                                                            <TableCell align="left">{row.desexed}</TableCell>
                                                            <TableCell align="left">{row.gender}</TableCell>
                                                            <TableCell align="left">{row.price}</TableCell>
                                                            <TableCell align="left">{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="flex-end"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        size="medium"
                                                                        // eslint-disable-next-line no-underscore-dangle
                                                                        // @ts-ignore
                                                                        onClick={(event) => handelUnPinSubmit(row._id)}
                                                                    >
                                                                        <IconDelete color="primary" />
                                                                    </Button>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                )}
            </Table>
        </TableContainer>
    );
};

export default PinPets;
