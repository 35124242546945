import { HeadCell1 } from 'types';
import * as Yup from 'yup';

// table header options
export const headCells: HeadCell1[] = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     label: 'S.No.',
    //     align: 'center',
    //     sort: false
    // },
    {
        id: 'templateName',
        numeric: false,
        label: 'Template Name',
        align: 'left',
        sort: true
    },
    {
        id: 'createdAt',
        numeric: false,
        label: 'Date Created',
        align: 'left',
        sort: true
    },
    {
        id: 'updatedAt',
        numeric: false,
        label: 'Last modified',
        align: 'left',
        sort: true
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'center',
        sort: true
    }
];
