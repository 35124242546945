import { SubTitle, Title } from './index.styles';

// assets
import {
    IconDash,
    IconActivity,
    IconAdmin,
    IconAppUser,
    IconEmail,
    IconEvent,
    IconHistory,
    IconPackages,
    IconPages,
    IconReport,
    IconSale,
    IconSubs,
    IconPets,
    IconSettings
} from '../constants/icons';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
    items: [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDash,
                    breadcrumbs: false
                },
                {
                    id: 'page-management',
                    title: 'Manage Pages',
                    type: 'item',
                    icon: IconPages,
                    url: '/page-management/list',
                    breadcrumbs: false
                },
                {
                    id: 'email-templates',
                    title: 'Email Templates',
                    type: 'item',
                    icon: IconEmail,
                    url: '/email-template/list',
                    breadcrumbs: false
                },
                {
                    id: 'activity-log',
                    title: 'Activity Log',
                    type: 'item',
                    icon: IconActivity,
                    url: '/activity-log/list',
                    breadcrumbs: false
                },
                {
                    id: 'report-forms',
                    title: 'Report Forms',
                    type: 'item',
                    icon: IconReport,
                    url: '/report-form/list',
                    breadcrumbs: false
                },
                {
                    id: 'transaction-history',
                    title: 'Transaction History',
                    type: 'item',
                    icon: IconHistory,
                    url: '/transaction-history/list',
                    breadcrumbs: false
                },
                {
                    id: 'enquiryform-templates',
                    title: 'Enquiry Templates',
                    type: 'item',
                    icon: IconHistory,
                    url: '/enquiryform-templates/list',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'menu',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'menu',
        //             title: "Menu's",
        //             type: 'item',
        //             icon: LayersIcon,
        //             url: '/menu/list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },

        {
            id: 'subscription-management',
            title: <Title>Subscription Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'Packages',
                    title: 'Packages',
                    type: 'item',
                    icon: IconPackages,
                    url: '/packages/list',
                    breadcrumbs: true
                },
                {
                    id: 'account-subscriptions',
                    title: 'Account Subscriptions',
                    type: 'item',
                    icon: IconSubs,
                    url: '/account-subscriptions/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'user-management',
            title: <Title>User Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'app-users',
                    title: 'App Users',
                    type: 'item',
                    icon: IconAppUser,
                    url: '/app-user/list',
                    breadcrumbs: true
                },
                {
                    id: 'admin',
                    title: 'Admin',
                    type: 'item',
                    icon: IconAdmin,
                    url: '/admin/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'animals',
                    title: 'Animals',
                    type: 'item',
                    icon: IconPets,
                    url: '/animals/list',
                    breadcrumbs: false
                },
                {
                    id: 'for-sale',
                    title: 'For Sale',
                    type: 'item',
                    icon: IconSale,
                    url: '/for-sale/list',
                    breadcrumbs: false
                },
                /* ,
                {
                    id: 'events',
                    title: 'Events',
                    type: 'item',
                    icon: IconEvent,
                    url: '/events/list',
                    breadcrumbs: false
                } */
                {
                    id: 'pin-animals',
                    title: 'Pin Animals',
                    type: 'item',
                    icon: IconPets,
                    url: '/pin/animals-breeders',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'setting',
            type: 'group',
            children: [
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'item',
                    icon: IconSettings,
                    url: 'settings',
                    breadcrumbs: false
                }
            ]
        }

        /* {
            id: 'user',
            title: <Title>User Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'admins',
                    title: 'Admins',
                    type: 'item',
                    icon: PersonIcon,
                    url: '/admin/list',
                    breadcrumbs: false
                },
                {
                    id: 'app-users',
                    title: 'App Users',
                    type: 'item',
                    icon: PhoneAndroidIcon,
                    url: '/app-user/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'faq',
            type: 'group',
            children: [
                {
                    id: 'faq-list',
                    title: 'FAQs',
                    type: 'item',
                    icon: QuestionMarkIcon,
                    url: '/faq/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'email-template',
            type: 'group',
            children: [
                {
                    id: 'email-template',
                    title: 'Email Template',
                    type: 'item',
                    icon: EmailIcon,
                    url: '/email-template/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'testimonials',
            type: 'group',
            children: [
                {
                    id: 'Testimonials',
                    title: 'Testimonials',
                    type: 'item',
                    url: '/testimonials/list',
                    icon: ReviewsIcon,
                    breadcrumbs: false
                }
            ]
        }
        */
    ]
};

export default MenuItems;
