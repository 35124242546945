import { Pagination, PaginationItem, Stack } from '@mui/material';
// assets
import Spinner from 'components/spinner';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';
import useGQL from 'hooks/useGQL';
import { useEffect, useState } from 'react';
import { RootState, useDispatch, useSelector } from 'store';

import { setPage, setRowsPerPage, setSearchText } from 'store/slices/livestock-table.slice';
// import FilterModal from './form/filter-reportFrom';
import { useDialog } from './hooks/useDialogs';
import { Button } from './index.styles';
import AnimalTable from './pets';
import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router-dom';

import { Search } from 'hooks/common/useSearch';

import { Type } from 'views/forSale/constants/types';
import FailureLoad from 'components/spinner/fail';
import AnimalFilterList from './Filter';
import { Animal } from 'views/animals/constants/types';
import useImageUpload from 'hooks/common/useImageUpload';
import AnimalLivestockTable from './livestock-table';

type Meta = { limit: number; skip: number };
type Sort = { order: string; orderBy: string } | null;

const AnimalsLivestockIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { GET_APP_LIVESTOCK_QUERY } = useGQL();
    const { data, loading, error, refetch } = GET_APP_LIVESTOCK_QUERY({ limit: 10, skip: 0 });
    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target) {
            dispatch(setSearchText(event.target.value));
        }
    });

    const { searchText, page, rowsPerPage, sort } = useSelector((state: RootState) => state.livestockTable);

    // local states
    const [count, setCount] = useState<number>(1);
    const [animals, setAnimals] = useState<any[]>([]);
    const [meta, setMeta] = useState<Meta>();

    const [purebredRadio, setPurebredRadio] = useState('');
    const [genderRadio, setGenderRadio] = useState('');
    const [ageRadio, setAgeRadio] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedBreedOption, setSelectedBreedOption] = useState<string[]>([]);
    const [selectedCategoryOption, setSelectedCategoryOption] = useState('');
    const [selectedSubCategoryOption, setSelectedSubCategoryOption] = useState('');
    const [filterPureBredAnimals, setFilterPureBredAnimals] = useState<string>('');
    const [filterGenderAnimals, setFilterGenderAnimals] = useState<string>('');
    const [filterAgeAnimals, setFilterAgeAnimals] = useState<number>(0);
    const [filterAnimalTypeAnimals, setFilterAnimalTypeAnimals] = useState<string>('');
    const [filterCategoryAnimals, setFilterCategoryAnimals] = useState<string>('');
    const [filterSubCategoryAnimals, setFilterSubCategoryAnimals] = useState<string>('');
    const [filterBreedAnimals, setFilterBreedAnimals] = useState<string>('');
    const [filterGeneticTraitsAnimals, setFilterGeneticTraitsAnimals] = useState<string>('');
    const [filterHandRaisedAnimals, setFilterHandRaisedAnimals] = useState<string>('');
    const [filterSuitedForAnimals, setFilterSuitedForAnimals] = useState<string>('');
    const [filterChickensBantamAnimals, setFilterChickensBantamAnimals] = useState<string>('');
    const [filterLocation, setFilterLocation] = useState<any>();
    const { getImageFromAWS } = useImageUpload();
    useEffect(() => {
        dispatch(setPage(1));
        dispatch(setRowsPerPage(10));
    }, []);

    useEffect(() => {
        if (data?.GetAppLivestock) {
            const mapped = data?.GetAppLivestock?.salesAnimalList?.map(async (item) => {
                const medias = item.media.find((mediaItem, index) => mediaItem.isCover === true || index === 0);
                return {
                    imageUrl: await getImageFromAWS(medias?.file),
                    ...item
                };
            });

            Promise.all(mapped)
                .then((results) => {
                    setAnimals(results);
                })
                .catch((e) => {
                    console.error(e);
                });
            setCount(data.GetAppLivestock.pagination.total);
        }
    }, [data?.GetAppLivestock]);

    useEffect(() => {
        // const limit = rowsPerPage;
        // const skip = page > 0 ? limit * (page - 1) : 0;
        // setMeta({ limit, skip });
        handleRefetch();
    }, [rowsPerPage, page]);

    useEffect(() => {
        handleRefetch();
    }, [sort.order, sort.orderBy]);

    const handleRefetch = () => {
        let age: any = null;
        if (ageRadio === 'max') {
            age = {
                // min: 0,
                max: 6,
                // minType: Type.MONTHS,
                maxType: Type.MONTHS
            };
        } else if (ageRadio === 'min') {
            age = {
                min: 6,
                // max: 6,
                minType: Type.MONTHS
                // maxType: Type.MONTHS
            };
        }
        const locationCoordinates =
            filterLocation?.long && filterLocation?.lat
                ? {
                      coordinates: [filterLocation?.long, filterLocation?.lat]
                  }
                : undefined;

        refetch({
            input: {
                limit: rowsPerPage,
                skip: rowsPerPage * (page - 1) < 0 ? 0 : rowsPerPage * (page - 1),
                order: sort.order,
                orderBy: sort.orderBy,
                searchText,
                kind: 'normal',
                pureBred: purebredRadio,
                gender: genderRadio,
                age,
                animalType: selectedOption,
                animalCategory: selectedCategoryOption,
                animalSubCategory: selectedSubCategoryOption,
                breed: selectedBreedOption,
                geneticTraits: filterGeneticTraitsAnimals,
                handRaised: filterHandRaisedAnimals,
                suitedFor: filterSuitedForAnimals,
                chickensBantam: filterChickensBantamAnimals,
                ...(locationCoordinates ? { location: locationCoordinates } : {}), // Include location conditionally
                radius: 1
            }
        });
    };

    useEffect(() => {
        dispatch(setPage(1));

        handleRefetch();
    }, [
        searchText,
        filterPureBredAnimals,
        filterGenderAnimals,
        filterAgeAnimals,
        filterAnimalTypeAnimals,
        filterCategoryAnimals,
        filterSubCategoryAnimals,
        filterBreedAnimals,
        filterGeneticTraitsAnimals,
        filterChickensBantamAnimals,
        filterHandRaisedAnimals,
        filterSuitedForAnimals
    ]);

    const clearFilters = async () => {
        setFilterLocation({});
        setPurebredRadio('');
        setGenderRadio('');
        setAgeRadio('');
        setSelectedOption('');
        setSelectedBreedOption([]);
        setSelectedCategoryOption('');
        setSelectedSubCategoryOption('');
        setFilterPureBredAnimals('');
        setFilterGenderAnimals('');
        setFilterAgeAnimals(0);
        setFilterAnimalTypeAnimals('');
        setFilterCategoryAnimals('');
        setFilterSubCategoryAnimals('');
        setFilterBreedAnimals('');
        setFilterGeneticTraitsAnimals('');
        setFilterHandRaisedAnimals('');
        setFilterSuitedForAnimals('');
        setFilterChickensBantamAnimals('');
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return () => {
            dispatch(setSearchText(''));
            clearFilters();
        };
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(setPage(newPage));
    };

    const OpenFilterModal = () => {
        handleDialogOpen();
    };

    const settingFilterValue = (value: any) => {
        setFilterPureBredAnimals(value);
    };
    const settingGenderFilterValue = (value: any) => {
        setFilterGenderAnimals(value);
    };
    const settingAgeFilterValue = (value: any) => {
        setFilterAgeAnimals(value);
    };
    const settingAnimalTypeFilterValue = (value: any) => {
        setFilterAnimalTypeAnimals(value);
    };
    const settingCategoryFilterValue = (value: any) => {
        setFilterCategoryAnimals(value);
    };
    const settingSubCategoryFilterValue = (value: any) => {
        setFilterSubCategoryAnimals(value);
    };
    const settingBreedFilterValue = (value: any) => {
        setFilterBreedAnimals(value);
    };
    const settingGeneticTraitsFilterValue = (value: any) => {
        setFilterGeneticTraitsAnimals(value);
    };
    const settingHandRaisedFilterValue = (value: any) => {
        setFilterHandRaisedAnimals(value);
    };
    const settingSuitedForFilterValue = (value: any) => {
        setFilterSuitedForAnimals(value);
    };
    const settingChickensBantamFilterValue = (value: any) => {
        setFilterChickensBantamAnimals(value);
    };

    const settingLocationValue = (value: any) => {
        setFilterLocation(value);
    };

    const closeDialog = () => {
        handleDialogClose();
        refetch();
    };

    const cardActions = (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center" spacing={2}>
            <Button variant="outlined" color="secondary" size="large" onClick={OpenFilterModal}>
                Filter
            </Button>

            <Search size="small" placeholder="Search" adornmentPosition="start" debouncedSearch={debouncedSearch} />
        </Stack>
    );

    const onSelect = (animal: any) => {
        const mapped = animals?.map(async (item) => {
            // eslint-disable-next-line
            const medias = animal?.media.find((mediaItem, index) => mediaItem.isCover === true || index === 0);
            // eslint-disable-next-line
            if (item._id === animal._id) {
                return {
                    imageUrl: await getImageFromAWS(medias?.file),
                    ...animal
                };
            }
            // eslint-disable-next-line
            const medias2 = item?.media.find((mediaItem, index) => mediaItem.isCover === true || index === 0);
            return { ...item, imageUrl: await getImageFromAWS(medias2?.file) };
        });

        Promise.all(mapped)
            .then((results) => {
                setAnimals(results);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    return (
        <MainCard title="Livestock Animals" content={false} secondary={cardActions}>
            <AnimalFilterList
                {...{ openDialog }}
                getFilterValue={settingFilterValue}
                getGenderFilterValue={settingGenderFilterValue}
                getAgeFilterValue={settingAgeFilterValue}
                getAnimalTypeFilterValue={settingAnimalTypeFilterValue}
                getCategoryFilterValue={settingCategoryFilterValue}
                getSubCategoryFilterValue={settingSubCategoryFilterValue}
                getBreedFilterValue={settingBreedFilterValue}
                getGeneticTraitsValue={settingGeneticTraitsFilterValue}
                getHandRaisedValue={settingHandRaisedFilterValue}
                getSuitedForValue={settingSuitedForFilterValue}
                getChickensBantamValue={settingChickensBantamFilterValue}
                handleDialogClose={closeDialog}
                purebredRadio={purebredRadio}
                setPurebredRadio={setPurebredRadio}
                genderRadio={genderRadio}
                setGenderRadio={setGenderRadio}
                ageRadio={ageRadio}
                setAgeRadio={setAgeRadio}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedCategoryOption={selectedCategoryOption}
                setSelectedCategoryOption={setSelectedCategoryOption}
                selectedSubCategoryOption={selectedSubCategoryOption}
                setSelectedSubCategoryOption={setSelectedSubCategoryOption}
                selectedBreedOption={selectedBreedOption}
                setSelectedBreedOption={setSelectedBreedOption}
                getLocationValue={settingLocationValue}
                clearFilters={clearFilters}
                category="livestock"
            />
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <AnimalLivestockTable {...{ onSelect, animals, handleRefetch }} />

                    <Pagination
                        count={Math.ceil(count / rowsPerPage)}
                        color="primary"
                        shape="rounded"
                        page={page}
                        onChange={handleChangePage}
                        renderItem={(item) => <PaginationItem {...item} />}
                    />
                </>
            )}

            <Stack direction="row" justifyContent="flex-end" spacing={2.5} px={4} pb={3.5}>
                <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        navigate('/pin/animals-breeders');
                    }}
                >
                    Cancel
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        navigate('/pin/animals-breeders');
                    }}
                >
                    Done
                </Button>
            </Stack>
        </MainCard>
    );
};

export default AnimalsLivestockIndex;
