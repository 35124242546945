/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import useGQL from 'hooks/useGQL';
import { Autocomplete, Avatar, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import useImageUpload from 'hooks/common/useImageUpload';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

interface Option {
    _id: string;
    listingName: string;
    // Add other properties if they exist in your 'option' object
}

const SelectAnimalList = ({ name, placeholder, classes, chosenAnimal, gender, userId, setField, preChosenAnimal }) => {
    const { GET_USER_ANIMALS } = useGQL();
    const { error, loading, data, refetch } = GET_USER_ANIMALS({
        userId,
        gender
    });

    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loadingList, setLoadingList] = useState(open && users.length === 0);
    const [image, setImage] = useState<any>([]);
    const { getImageFromAWS } = useImageUpload();

    const handleRefetch = async () => {
        await setLoadingList(true);
        await refetch({
            input: {
                limit: 100,
                skip: 0,
                order: 'asc',
                orderBy: 'listingName',
                userId,
                gender
            }
        });
        await setLoadingList(false);
    };

    const handleSetField = async () => {
        if (name === 'sire' || name === 'father') {
            // await setField('father', {});
            // await setField('fatherId', null);
            await chosenAnimal({});
        }
        if (name === 'dam' || name === 'mother') {
            // await setField('mother', {});
            // await setField('motherId', null);
            await chosenAnimal({});
        }
    };

    useEffect(() => {
        if (inputValue !== '') {
            handleRefetch();
        }
    }, [inputValue]);

    useEffect(() => {
        const handlefetch = async () => {
            setLoadingList(true);
            if (data?.getUsersAnimals?.salesAnimalList) {
                await setUsers(data.getUsersAnimals.salesAnimalList);
            }
            await setLoadingList(false);
        };
        handlefetch();
    }, [data]);

    useEffect(() => {
        const fetchImages = async () => {
            if (data?.getUsersAnimals?.salesAnimalList && Array.isArray(data.getUsersAnimals.salesAnimalList)) {
                const imagePromises = data?.getUsersAnimals?.salesAnimalList.map(async (item) => {
                    const coverMedia = item.media.find((mediaObj) => mediaObj.isCover); // Find the media object with isCover set to true
                    if (coverMedia && coverMedia.file) {
                        const url = await getImageFromAWS(coverMedia.file);
                        return url;
                    }
                    return process.env.REACT_APP_IMAGE_PLACEHOLDER; // Return null if there is no image for this item
                });

                // Wait for all image promises to resolve
                const resolvedImages = await Promise.all(imagePromises);

                // Filter out null values (items with no image)
                const validImages = resolvedImages.filter((url) => url !== null);

                setImage(validImages);
            }
        };

        fetchImages().catch(console.error);
    }, [data]);

    return (
        <>
            {users && (
                <Autocomplete
                    id={name}
                    className={classes}
                    placeholder={placeholder}
                    size="small"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        handleRefetch();
                        setOpen(false);
                        // handleSetField();
                    }}
                    // eslint-disable-next-line no-prototype-builtins
                    {...(preChosenAnimal?.hasOwnProperty('listingName')
                        ? {
                              defaultValue: preChosenAnimal
                          }
                        : null)}
                    isOptionEqualToValue={(option: any, value) => option.listingName === value.listingName}
                    getOptionLabel={(option: any) => `${option.listingName}`}
                    options={users}
                    loading={loadingList}
                    onChange={(event: any, newValue: any | null, reason) => {
                        console.log(reason, 'reason');
                        if (reason === 'clear') {
                            handleSetField();
                        }
                        chosenAnimal(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderOption={(props, option: Option) => {
                        const imageIndex = data?.getUsersAnimals?.salesAnimalList.findIndex((item) => item._id === option._id);
                        // const imageIndex = index as number;
                        return (
                            // eslint-disable-next-line no-underscore-dangle
                            // <li style={{ display: 'flex', alignItems: 'center' }} {...props} key={option._id}>
                            //     <Avatar
                            //         alt={option.listingName}
                            //         src={image || process.env.REACT_APP_IMAGE_PLACEHOLDER}
                            //         sx={{ height: 78, width: 78 }}
                            //     />
                            //     <span>{option.listingName}</span>
                            // </li>
                            // <div>
                            //     <ul>
                            //         {data?.getUsersAnimals?.salesAnimalList.map((item, index) => (
                            //             // eslint-disable-next-line no-underscore-dangle
                            //             <li {...props} key={option._id} className="select-animal">
                            //                 {image[index] && (
                            //                     <Avatar
                            //                         alt={item.listingName}
                            //                         src={image[index]}
                            //                         variant="square"
                            //                         sx={{ height: 32, width: 32 }}
                            //                     />
                            //                 )}
                            //                 {item.listingName}
                            //                 {console.log(item, 'items')}
                            //             </li>
                            //         ))}
                            //     </ul>
                            // </div>
                            <div>
                                <ul>
                                    {imageIndex !== -1 && (
                                        <li {...props} key={option._id} className="select-animal">
                                            {/* {image[imageIndex] && ( */}
                                            <Avatar
                                                alt={data?.getUsersAnimals?.salesAnimalList[imageIndex].listingName}
                                                src={image[imageIndex] || process.env.REACT_APP_IMAGE_PLACEHOLDER}
                                                variant="square"
                                                sx={{ height: 32, width: 32 }}
                                            />
                                            {/* )} */}
                                            {data?.getUsersAnimals?.salesAnimalList[imageIndex].listingName}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            name={name}
                            placeholder={placeholder}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};

export default SelectAnimalList;
