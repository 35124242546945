import { Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const Typography = (theme: Theme, borderRadius: number, fontFamily: string): TypographyOptions => ({
    fontFamily,
    h6: {
        fontWeight: 500,
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: '17px'
    },
    h5: {
        fontSize: '14px',
        color: theme.palette.primary[200],
        fontWeight: 500,
        lineHeight: '20px'
    },
    h4: {
        fontSize: '1rem',
        color: theme.palette.grey[900],
        fontWeight: 400,
        lineHeight: 1.5
    },
    h3: {
        fontSize: '20px',
        color: theme.palette.grey[900],
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: '28px'
    },
    h2: {
        fontSize: '1.5rem',
        color: `${theme.palette.text.primary}!important`,
        lineHeight: '2rem',
        fontWeight: 700,
        marginBottom: '0.563rem'
    },
    h1: {
        fontSize: '2rem',
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    subtitle1: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        color: theme.palette.primary[200]
    },
    subtitle2: {
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.palette.grey[500]
    },
    caption: {
        fontSize: '0.875rem',
        color: theme.palette.text.dark,
        fontWeight: 400
    },
    body1: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1.334em',
        color: theme.palette.text.dark
    },
    body2: {
        fontSize: '12px',
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '17px',
        color: theme.palette.grey[600]
    },
    button: {
        textTransform: 'capitalize'
    },
    customInput: {
        marginTop: 0,
        marginBottom: 0,
        '& > label': {
            top: 23,
            left: 0,
            color: theme.palette.grey[500],
            '&[data-shrink="false"]': {
                top: 5
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    mainContent: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[100],
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '16px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: '0'
    },
    menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: theme.palette.grey[600],
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px'
    },
    subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        textTransform: 'capitalize'
    },
    commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px'
    },
    smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem'
    },
    mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem'
    },
    largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem'
    }
});

export default Typography;
