// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
    const theme = useTheme();
    const { width, height } = props;

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg width={width ?? '91'} height={height ?? '23'} viewBox="0 0 91 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3239 15.1446C22.4637 15.1446 21.6906 14.9559 21.0046 14.5785C20.3186 14.2012 19.7741 13.6836 19.3712 13.0259C18.9792 12.3682 18.7832 11.6296 18.7832 10.8102C18.7832 9.97994 18.9792 9.24136 19.3712 8.59443C19.7741 7.93672 20.3186 7.41918 21.0046 7.0418C21.6906 6.65364 22.4637 6.45956 23.3239 6.45956C24.1842 6.45956 24.9519 6.65364 25.627 7.0418C26.313 7.41918 26.852 7.93672 27.244 8.59443C27.6469 9.24136 27.8483 9.97994 27.8483 10.8102C27.8483 11.6296 27.6469 12.3682 27.244 13.0259C26.852 13.6836 26.313 14.2012 25.627 14.5785C24.941 14.9559 24.1733 15.1446 23.3239 15.1446ZM23.3239 12.9612C23.7268 12.9612 24.0753 12.8642 24.3693 12.6701C24.6633 12.476 24.892 12.2172 25.0553 11.8938C25.2186 11.5703 25.3003 11.2037 25.3003 10.794C25.3003 10.3951 25.2186 10.0339 25.0553 9.71039C24.892 9.38692 24.6633 9.12815 24.3693 8.93407C24.0753 8.73999 23.7268 8.64295 23.3239 8.64295C22.921 8.64295 22.5672 8.73999 22.2623 8.93407C21.9683 9.12815 21.7396 9.38692 21.5762 9.71039C21.4129 10.0339 21.3312 10.3951 21.3312 10.794C21.3312 11.2037 21.4129 11.5703 21.5762 11.8938C21.7396 12.2172 21.9683 12.476 22.2623 12.6701C22.5672 12.8642 22.921 12.9612 23.3239 12.9612Z"
                fill="#CB3E19"
            />
            <path
                d="M33.2065 15.1446C32.3463 15.1446 31.6004 14.9829 30.9688 14.6594C30.3482 14.3252 29.869 13.8777 29.5315 13.317C29.1939 12.7456 29.0251 12.1148 29.0251 11.4248V6.65364H31.5568V11.3601C31.5568 11.6512 31.6276 11.9207 31.7692 12.1687C31.9107 12.4059 32.1013 12.6 32.3408 12.751C32.5913 12.8911 32.8744 12.9612 33.1902 12.9612C33.4951 12.9612 33.7673 12.8911 34.0069 12.751C34.2573 12.6 34.4533 12.4059 34.5949 12.1687C34.7364 11.9207 34.8072 11.6512 34.8072 11.3601V6.65364H37.3389V11.4248C37.3389 12.1148 37.1756 12.7456 36.8489 13.317C36.5222 13.8777 36.0486 14.3252 35.4279 14.6594C34.8181 14.9829 34.0777 15.1446 33.2065 15.1446Z"
                fill="#CB3E19"
            />
            <path
                d="M43.1864 14.9505C42.3261 14.9505 41.5584 14.7672 40.8833 14.4006C40.2082 14.034 39.6746 13.5435 39.2826 12.9289C38.8906 12.3035 38.6946 11.6027 38.6946 10.8263C38.6946 10.05 38.8906 9.35458 39.2826 8.73999C39.6746 8.11463 40.2082 7.61865 40.8833 7.25205C41.5584 6.88546 42.3261 6.70216 43.1864 6.70216H44.1664V7.97985H43.1374C42.582 7.97985 42.0811 8.10923 41.6347 8.36801C41.1991 8.616 40.8507 8.95564 40.5893 9.38692C40.3389 9.81821 40.2136 10.298 40.2136 10.8263C40.2136 11.3547 40.3389 11.8345 40.5893 12.2658C40.8507 12.6971 41.1991 13.0421 41.6347 13.3009C42.0811 13.5488 42.582 13.6728 43.1374 13.6728H44.1664V14.9505H43.1864Z"
                fill="#45110B"
            />
            <path
                d="M45.5565 14.9505V3.14404H47.0592V7.7696C47.3968 7.33831 47.8051 7.02024 48.2842 6.81537C48.7742 6.61051 49.2806 6.50808 49.8032 6.50808C50.5437 6.50808 51.1862 6.66442 51.7306 6.97711C52.2751 7.27901 52.6997 7.7049 53.0046 8.25479C53.3095 8.80469 53.462 9.44623 53.462 10.1794V14.9505H51.9593V10.1956C51.9593 9.69961 51.8449 9.27371 51.6163 8.9179C51.3876 8.5513 51.0827 8.27097 50.7016 8.07689C50.3314 7.88281 49.9339 7.78577 49.5092 7.78577C49.0846 7.78577 48.6871 7.88281 48.3169 8.07689C47.9467 8.26019 47.6418 8.52974 47.4022 8.88555C47.1735 9.24136 47.0592 9.66726 47.0592 10.1632V14.9505H45.5565Z"
                fill="#45110B"
            />
            <path
                d="M59.4382 15.1446C58.5888 15.1446 57.8375 14.9559 57.1841 14.5785C56.5308 14.1904 56.0135 13.6728 55.6324 13.0259C55.2513 12.3682 55.0607 11.635 55.0607 10.8263C55.0607 10.0177 55.2513 9.28988 55.6324 8.64295C56.0135 7.98524 56.5308 7.4677 57.1841 7.09032C57.8375 6.70216 58.5888 6.50808 59.4382 6.50808C60.2875 6.50808 61.0388 6.70216 61.6922 7.09032C62.3564 7.4677 62.8736 7.97985 63.2439 8.62678C63.625 9.27371 63.8156 10.0069 63.8156 10.8263C63.8156 11.635 63.625 12.3682 63.2439 13.0259C62.8736 13.6728 62.3564 14.1904 61.6922 14.5785C61.0388 14.9559 60.2875 15.1446 59.4382 15.1446ZM59.4382 13.8669C60.0153 13.8669 60.5162 13.7321 60.9408 13.4626C61.3655 13.193 61.6976 12.8318 61.9372 12.379C62.1767 11.9153 62.2965 11.3978 62.2965 10.8263C62.2965 10.2549 62.1767 9.74273 61.9372 9.28988C61.6976 8.82625 61.3655 8.45966 60.9408 8.1901C60.5162 7.92055 60.0153 7.78577 59.4382 7.78577C58.8719 7.78577 58.371 7.92055 57.9355 8.1901C57.5108 8.45966 57.1787 8.82625 56.9391 9.28988C56.6996 9.74273 56.5798 10.2549 56.5798 10.8263C56.5798 11.3978 56.6996 11.9153 56.9391 12.379C57.1787 12.8318 57.5108 13.193 57.9355 13.4626C58.371 13.7321 58.8719 13.8669 59.4382 13.8669Z"
                fill="#45110B"
            />
            <path
                d="M69.3359 15.1446C68.4866 15.1446 67.7352 14.9559 67.0819 14.5785C66.4285 14.1904 65.9113 13.6728 65.5302 13.0259C65.1491 12.3682 64.9585 11.635 64.9585 10.8263C64.9585 10.0177 65.1491 9.28988 65.5302 8.64295C65.9113 7.98524 66.4285 7.4677 67.0819 7.09032C67.7352 6.70216 68.4866 6.50808 69.3359 6.50808C70.1853 6.50808 70.9366 6.70216 71.59 7.09032C72.2542 7.4677 72.7714 7.97985 73.1417 8.62678C73.5228 9.27371 73.7133 10.0069 73.7133 10.8263C73.7133 11.635 73.5228 12.3682 73.1417 13.0259C72.7714 13.6728 72.2542 14.1904 71.59 14.5785C70.9366 14.9559 70.1853 15.1446 69.3359 15.1446ZM69.3359 13.8669C69.913 13.8669 70.4139 13.7321 70.8386 13.4626C71.2633 13.193 71.5954 12.8318 71.835 12.379C72.0745 11.9153 72.1943 11.3978 72.1943 10.8263C72.1943 10.2549 72.0745 9.74273 71.835 9.28988C71.5954 8.82625 71.2633 8.45966 70.8386 8.1901C70.4139 7.92055 69.913 7.78577 69.3359 7.78577C68.7697 7.78577 68.2688 7.92055 67.8332 8.1901C67.4086 8.45966 67.0764 8.82625 66.8369 9.28988C66.5973 9.74273 66.4775 10.2549 66.4775 10.8263C66.4775 11.3978 66.5973 11.9153 66.8369 12.379C67.0764 12.8318 67.4086 13.193 67.8332 13.4626C68.2688 13.7321 68.7697 13.8669 69.3359 13.8669Z"
                fill="#45110B"
            />
            <path
                d="M75.1993 14.9505V13.6728H79.1847C79.446 13.6728 79.6693 13.6189 79.8544 13.5111C80.0504 13.3925 80.1974 13.2416 80.2954 13.0583C80.3934 12.875 80.4424 12.6809 80.4424 12.476C80.4424 12.2819 80.3988 12.104 80.3117 11.9423C80.2246 11.7698 80.0885 11.635 79.9034 11.538C79.7292 11.4301 79.5168 11.3762 79.2664 11.3762H77.584C77.0504 11.3762 76.5822 11.29 76.1793 11.1175C75.7873 10.9342 75.477 10.67 75.2483 10.325C75.0305 9.97994 74.9216 9.55944 74.9216 9.06346C74.9216 8.64295 75.0251 8.2548 75.232 7.89898C75.4389 7.54317 75.7274 7.25744 76.0976 7.0418C76.4679 6.81537 76.8925 6.70216 77.3717 6.70216H81.2101V7.97985H77.5187C77.1811 7.97985 76.9143 8.08228 76.7183 8.28714C76.5223 8.492 76.4243 8.7346 76.4243 9.01494C76.4243 9.28449 76.5223 9.5217 76.7183 9.72656C76.9252 9.92064 77.2138 10.0177 77.584 10.0177H79.1847C79.7836 10.0177 80.2845 10.1147 80.6874 10.3088C81.1012 10.4921 81.4115 10.7617 81.6184 11.1175C81.8362 11.4733 81.9451 11.91 81.9451 12.4275C81.9451 12.8696 81.8362 13.2847 81.6184 13.6728C81.4006 14.0502 81.0957 14.3575 80.7037 14.5947C80.3226 14.8319 79.8761 14.9505 79.3644 14.9505H75.1993Z"
                fill="#45110B"
            />
            <path
                d="M87.8308 14.9505C86.9161 14.9505 86.1049 14.7834 85.3971 14.4492C84.6893 14.1041 84.1339 13.6243 83.731 13.0097C83.339 12.3844 83.143 11.6566 83.143 10.8263C83.143 10.0069 83.3118 9.27371 83.6494 8.62678C83.9869 7.96907 84.4552 7.45152 85.0541 7.07415C85.653 6.69677 86.3499 6.50808 87.1448 6.50808C88.005 6.50808 88.7182 6.69138 89.2845 7.05797C89.8616 7.42457 90.2917 7.92055 90.5748 8.54591C90.8579 9.1605 90.9995 9.85056 90.9995 10.6161C90.9995 10.7347 90.9941 10.8587 90.9832 10.9881C90.9832 11.1175 90.9723 11.2307 90.9505 11.3277H84.7437C84.7982 11.8345 84.967 12.2658 85.2501 12.6216C85.5332 12.9666 85.8925 13.2308 86.3281 13.4141C86.7745 13.5866 87.2645 13.6728 87.7981 13.6728H89.9705V14.9505H87.8308ZM84.7274 10.2765H89.4805C89.4805 10.0716 89.4587 9.85595 89.4151 9.62952C89.3716 9.39231 89.2954 9.16589 89.1865 8.95024C89.0776 8.72382 88.9306 8.52435 88.7455 8.35183C88.5712 8.16854 88.348 8.02298 88.0758 7.91516C87.8144 7.80733 87.5041 7.75342 87.1448 7.75342C86.7637 7.75342 86.4261 7.8289 86.1321 7.97985C85.849 8.12002 85.604 8.3141 85.3971 8.56209C85.1902 8.79929 85.0323 9.06885 84.9234 9.37075C84.8145 9.67265 84.7492 9.97455 84.7274 10.2765Z"
                fill="#45110B"
            />
            <path
                d="M12.0213 22.7451C9.71902 22.7451 7.66448 22.2562 5.85764 21.2784C4.05081 20.3007 2.62283 18.9922 1.5737 17.353C0.524565 15.685 0 13.8157 0 11.7451C0 9.67453 0.524565 7.81963 1.5737 6.18041C2.62283 4.51244 4.05081 3.18956 5.85764 2.21178C7.66448 1.23401 9.71902 0.745117 12.0213 0.745117H17.2382V3.37649H11.8027C10.1999 3.37649 8.74275 3.75034 7.43134 4.49806C6.11993 5.21701 5.0708 6.20917 4.28395 7.47453C3.4971 8.73989 3.10368 10.1634 3.10368 11.7451C3.10368 13.3268 3.4971 14.7503 4.28395 16.0157C5.0708 17.2811 6.11993 18.2876 7.43134 19.0353C8.74275 19.7543 10.1999 20.1137 11.8027 20.1137H14.5567V22.7451H12.0213Z"
                fill="#CB3E19"
            />
            <path
                d="M11.7385 22.7445V16.677C11.0642 16.5089 10.4862 16.2387 10.0045 15.8664C9.52288 15.4822 9.15562 15.0199 8.90274 14.4795C8.64987 13.9272 8.52344 13.3328 8.52344 12.6963V7.38281H11.3231V12.6063C11.3231 12.9305 11.4014 13.2307 11.5579 13.5069C11.7144 13.7831 11.9252 14.0052 12.1901 14.1733C12.467 14.3294 12.7741 14.4075 13.1113 14.4075C13.4605 14.4075 13.7675 14.3294 14.0324 14.1733C14.3094 14.0052 14.5261 13.7831 14.6827 13.5069C14.8392 13.2307 14.9175 12.9305 14.9175 12.6063V7.38281H17.7171V12.6963C17.7171 13.3328 17.5907 13.9211 17.3378 14.4615C17.097 15.0019 16.7357 15.4642 16.2541 15.8484C15.7845 16.2327 15.2125 16.5029 14.5382 16.659V22.7445H11.7385Z"
                fill="#CB3E19"
            />
        </svg>
    );
};

export default Logo;
