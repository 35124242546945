/* eslint no-underscore-dangle: 0 */
import {
    Avatar,
    Button,
    Checkbox,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import useGQL from 'hooks/useGQL';
import useAlertDialog from 'hooks/common/useAlertDialog';

import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import Spinner from 'components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/slices/pets-table.slice';
import { useNavigate } from 'react-router-dom';
import { IconChecked, IconCheckbox } from 'constants/icons';
import { REPORTS_ROUTE } from 'constants/routes';
import moment from 'moment';
import { headCellsPinToAnimal } from './constants/header-cell';
import { Animal } from 'views/animals/constants/types';
import useSnackbar from 'hooks/common/useSnackbar';
import { openSnackbar } from 'store/slices/snackbar';
import { orange } from '@mui/material/colors';

type Sort = { order: string; orderBy: string } | null;
type AnimalTableProps = {
    animals: Animal[];
    handleRefetch: () => void;
    onSelect: (animalId: Animal) => void;
};

type Order = 'asc' | 'desc';
const AnimalTable = ({ animals, handleRefetch, onSelect }: AnimalTableProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadingCheck, setLoading] = useState(false);
    const {
        sort: { order, orderBy }
    } = useSelector((state: RootState) => state.table);

    // useEffect(() => {
    //     handleRefetch();
    // }, [order, orderBy]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        const setOrder = isAsc ? 'desc' : 'asc';
        dispatch(setSort({ order: setOrder, orderBy: property }));
    };

    const { SET_PIN_PETS_MUTATION } = useGQL();
    const [handleSubmit, { loading }] = SET_PIN_PETS_MUTATION();

    const onChecked = async (id) => {
        setLoading(true);
        await handleSubmit({
            variables: {
                id: `${id}`
            },
            onCompleted: (updateData) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Peats Pinned update successfully.',
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );

                onSelect(updateData?.AddPinPets);
            },
            onError: (updateImageError: any) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: updateImageError?.message || "Breeders Can't be pinnes",
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        });
        setLoading(false);
    };

    if (loadingCheck) {
        return <Spinner />;
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCellsPinToAnimal.map((headCell) => {
                                if (headCell.id === 'action') {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                        >
                                            <Box component="span">{headCell.label}</Box>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {animals.length !== 0 &&
                            animals.map((animal) => {
                                const labelId = `enhanced-table-checkbox-${animal._id}`;
                                return (
                                    <TableRow key={animal._id}>
                                        <TableCell align="left">
                                            <Avatar
                                                sx={{ bgcolor: orange[500] }}
                                                alt="Avatar1"
                                                // @ts-ignore
                                                src={animal.imageUrl || process.env.REACT_APP_IMAGE_PLACEHOLDER}
                                            />
                                        </TableCell>
                                        <TableCell align="left">{animal.listingName}</TableCell>
                                        <TableCell align="left">{animal.animalType}</TableCell>
                                        <TableCell align="left">{animal.breed}</TableCell>
                                        <TableCell align="left">{animal.gender}</TableCell>

                                        <TableCell align="left">{moment(animal.createdAt).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell align="right">
                                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                                <Checkbox
                                                    // @ts-ignore
                                                    checked={animal?.pinOrder > 0}
                                                    onChange={() => {
                                                        onChecked(animal._id);
                                                    }}
                                                    icon={<IconCheckbox />}
                                                    checkedIcon={<IconChecked />}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default AnimalTable;
