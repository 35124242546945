import useGQL from 'hooks/useGQL';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import accountReducer from 'store/accountReducer';
import { LOGOUT } from 'store/actions';
import { setLoginState } from 'store/slices/auth';
import { InitialLoginContextProps } from 'types/auth';
import Loader from 'ui-component/Loader';
import useSnackbar from 'hooks/common/useSnackbar';

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const AuthContext = createContext<null>(null);

export const AuthAccessProvider = ({ children }: { children: React.ReactElement }) => {
    const { handleOpenSnackbar } = useSnackbar();
    const oldRefreshToken = localStorage.getItem('refreshToken');
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const { GET_ADMIN_PROFILE, GET_ACCESS_TOKEN_FROM_REFRESH_TOKEN } = useGQL();
    const { data, error, refetch } = GET_ADMIN_PROFILE();
    const [handleRefresh, { data: refreshData, error: refreshError }] = GET_ACCESS_TOKEN_FROM_REFRESH_TOKEN({
        refreshToken: oldRefreshToken
    });
    const location = useLocation();
    const [refreshDone, setRefreshDone] = useState(false);

    useEffect(() => {
        if (error && refreshDone === false) {
            Promise.all([handleRefresh()])
                .then((response) => {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.setItem('accessToken', response[0].data?.refresh?.accessToken);
                    localStorage.setItem('refreshToken', response[0].data?.refresh?.refreshToken);
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('user', JSON.stringify(response[0].data?.refresh?.admin));

                    dispatch(
                        setLoginState({
                            isLoggedIn: true,
                            accessToken: response[0].data?.refresh?.accessToken,
                            refreshToken: response[0].data?.refresh?.refreshToken,
                            user: response[0].data?.refresh?.admin
                        })
                    );
                    setRefreshDone(true);
                })
                .catch((refreshErrors) => {
                    dispatch({ type: LOGOUT });
                    navigate('/');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('isLoggedIn');
                    localStorage.removeItem('user');
                    setRefreshDone(false);
                    handleOpenSnackbar({ message: `${refreshErrors.message}`, alertType: 'error' });
                });
        }
        setRefreshDone(false);
    }, [data, error, location.pathname, refreshData]);

    useEffect(() => {
        refetch();
    }, [location.pathname]);

    // if (state.isInitialized !== undefined && !state.isInitialized) {
    //     return <Loader />;
    // }

    return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};
