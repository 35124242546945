import 'react-image-crop/dist/ReactCrop.css';

import { Button, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import setCanvasPreview from 'utils/crop';
import Spinner from 'components/spinner';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: 'px',
                width: mediaWidth,
                height: mediaHeight
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export default function Cropper({ imageUrl, onSaveCropped }) {
    const [imgSrc, setImgSrc] = useState(imageUrl);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(1);
    const [extendedImage, setExtendedImage] = useState<Blob>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [loading, setLoading] = useState<boolean>(false);

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            console.log('foo', width, height);
            let initialLength = 200;

            if (width > height) {
                initialLength = height;
            } else {
                initialLength = width;
            }

            setCrop(centerAspectCrop(initialLength, initialLength, aspect));
        }
    }

    function onDownloadCropClick() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist');
        }

        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob');
            }
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
            }
            blobUrlRef.current = URL.createObjectURL(blob);
            hiddenAnchorRef.current!.href = blobUrlRef.current;
            hiddenAnchorRef.current!.click();
        });
    }

    function onSaveCroppedImage() {
        setLoading(true);

        if (crop && imgRef.current && previewCanvasRef?.current) {
            setCanvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                convertToPixelCrop(crop, imgRef?.current?.width, imgRef.current.height)
            );
            previewCanvasRef?.current.toBlob((blob) => {
                if (blob) {
                    onSaveCropped(blob);
                }
            });
        }
        setLoading(false);
    }

    // useDebounceEffect(
    //     async () => {
    //         if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
    //             // We use canvasPreview as it's much faster than imgPreview.
    //             canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
    //         }
    //     },
    //     100,
    //     [completedCrop, scale, rotate]
    // );

    useEffect(() => {
        if (!extendedImage) {
            const preCanvas = canvasRef.current;
            const ctx = preCanvas?.getContext('2d');

            if (preCanvas && ctx) {
                const img = new Image();
                img.src = imageUrl;
                img.setAttribute('crossorigin', 'anonymous');
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    const maxLength = Math.max(width, height);
                    preCanvas.width = maxLength;
                    preCanvas.height = maxLength;

                    ctx.fillStyle = 'black'; // Fill the canvas with black
                    ctx?.fillRect(0, 0, maxLength, maxLength);
                    const offsetX = (maxLength - width) / 2;
                    const offsetY = (maxLength - height) / 2;

                    ctx?.drawImage(img, offsetX, offsetY, width, height); // Draw the original image on top

                    if (!canvasRef.current) {
                        throw new Error('Crop canvas does not exist');
                    }

                    canvasRef.current.toBlob((blob) => {
                        if (!blob) {
                            throw new Error('Failed to create blob');
                        }
                        setExtendedImage(blob);
                        setImgSrc(URL.createObjectURL(blob));
                    });
                };
            }
        }
    }, [imageUrl]);

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined);
        } else {
            setAspect(16 / 9);

            if (imgRef.current) {
                const { width, height } = imgRef.current;
                const newCrop = centerAspectCrop(width, height, 16 / 9);
                setCrop(newCrop);
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height));
            }
        }
    }

    return (
        <Stack className="App">
            {!extendedImage && <Spinner />}
            {!!imgSrc && extendedImage && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    minWidth={200}
                    minHeight={200}
                    style={{
                        width: 'max-content',
                        margin: 'auto'
                    }}
                >
                    <img
                        crossOrigin="anonymous"
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <>
                    <Stack style={{ marginTop: '10px', marginBottom: '10px', display: 'none' }}>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                margin: 'auto',
                                width: completedCrop.width,
                                height: completedCrop.height
                            }}
                        />
                    </Stack>
                    <Stack>
                        {/* <TextField
                            id="outlined-number"
                            label="Scale"
                            type="number"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={scale}
                            disabled={!imgSrc}
                            onChange={(e) => setScale(Number(e.target.value))}
                            inputProps={{
                                step: 0.1
                            }}
                        />  */}

                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            onClick={() => onSaveCroppedImage()}
                        >
                            <span>Save Cropped Image</span>
                        </Button>
                    </Stack>
                </>
            )}

            <Stack style={{ marginTop: '10px', marginBottom: '10px', display: 'none' }}>
                <canvas
                    ref={canvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        margin: 'auto',
                        width: '100%',
                        height: 'auto'
                    }}
                />
            </Stack>
        </Stack>
    );
}
