import { gql } from '@apollo/client';

export const GET_ALL_PACKAGES = gql`
    query GetPackagesStats {
        getPackagesStats {
            message
            packagesList {
                _id
                subscriptionCount
                title
                deviceType
            }
        }
    }
`;
