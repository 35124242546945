import { gql } from '@apollo/client';

export const UPDATE_SETTINGS = gql`
    mutation UpdateSettings($input: UpdateSettingsDto!) {
        updateSettings(input: $input) {
            message
            settings {
                _id
                slug
                title
                hide
                animalType
            }
        }
    }
`;
