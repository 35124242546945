/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    FormLabel,
    FormHelperText,
    InputLabel,
    TextField,
    Divider,
    Typography,
    Stack,
    Checkbox,
    Autocomplete,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Field, FieldArray } from 'formik';
import { age, desexed, gender, priceRange, purebred } from '../forSale/constants/types';
import useGQL from 'hooks/useGQL';
// import Dropdown from './dropdown';
import { IconRadio, IconRadioChecked, IconCheckbox, IconChecked } from '../../constants/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type filterModalProps = {
    openDialog: boolean;
    purebredRadio: any;
    setPurebredRadio: any;
    genderRadio: any;
    setGenderRadio: any;
    ageRadio: any;
    // suitedFor: any;
    // geneticTraits: any;
    // handRaised: any;
    setAgeRadio: any;
    selectedOption: any;
    setSelectedOption: any;
    selectedBreedOption: any;
    setSelectedBreedOption: any;
    selectedCategoryOption: any;
    setSelectedCategoryOption: any;
    selectedSubCategoryOption: any;
    setSelectedSubCategoryOption: any;
    getFilterValue: (d: string) => any;
    getGenderFilterValue: (d: string) => any;
    getAgeFilterValue: (d: string) => any;
    getAnimalTypeFilterValue: (d: string) => any;
    getCategoryFilterValue: (d: string) => any;
    getSubCategoryFilterValue: (d: string) => any;
    getBreedFilterValue: (d: string) => any;
    getLocationValue: (d: Suburb | null) => any;
    getGeneticTraitsValue: (d: string) => any;
    getHandRaisedValue: (d: string) => any;
    getSuitedForValue: (d: string) => any;
    getChickensBantamValue: (d: string) => any;
    handleDialogClose: () => void;
    clearFilters: () => void;
    category?: string;
};

type Suburb = {
    locality: string;
    postcode: string;
    long: number;
    lat: number;
};

const AnimalFilterList = ({
    openDialog,
    purebredRadio,
    setPurebredRadio,
    genderRadio,
    setGenderRadio,
    ageRadio,
    setAgeRadio,
    selectedOption,
    setSelectedOption,
    selectedCategoryOption,
    setSelectedCategoryOption,
    selectedSubCategoryOption,
    setSelectedSubCategoryOption,
    selectedBreedOption,
    setSelectedBreedOption,
    getFilterValue,
    getGenderFilterValue,
    getAgeFilterValue,
    getAnimalTypeFilterValue,
    getBreedFilterValue,
    getCategoryFilterValue,
    getSubCategoryFilterValue,
    getGeneticTraitsValue,
    getHandRaisedValue,
    getSuitedForValue,
    getChickensBantamValue,
    getLocationValue,
    handleDialogClose,
    clearFilters,
    category
}: filterModalProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [purebredRadio, setPurebredRadio] = useState('');
    const { GET_LIST_NEW_ANIMAL_TYPE, GET_LIST_CATEGORIES_AND_BREEDS } = useGQL();

    const { error: animalTypeErr, loading: AnimalTypeLoading, data: animalTypeList, refetch } = GET_LIST_NEW_ANIMAL_TYPE();
    const { error: catBreedErr, loading: catBreedLoading, data: catBreedData, refetch: catBreedRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();
    const { error: subCatErr, loading: subCatLoading, data: subCatData, refetch: subCatRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();
    const { error: breedErr, loading: breedLoading, data: breedData, refetch: breedRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();

    const [animalType, setAnimalType] = useState(false);
    const [animalCategory, setAnimalCategory] = useState(false);
    const [animalSubCategory, setAnimalSubCategory] = useState(false);
    const [animalBreed, setAnimalBreed] = useState(false);

    const [extraFields, setExtraFields] = useState<any>([]);
    const [suitedForList, setSuitedForList] = useState([]);
    const [chickensBantamList, setChickensBantamList] = useState([]);
    const [geneticTraitsList, setgeneticTraitsList] = useState([]);

    const [chosenAnimalType, setChosenAnimalType] = useState<any>({});
    const [chosenCategory, setChosenCategory] = useState<any>({});
    const [chosenSubCategory, setChosenSubCategory] = useState<any>({});
    const [breedList, setBreedList] = useState<any>([]);
    const [chosenBreed, setChosenBreed] = useState<any>({});
    const [suitedFor, setSuitedFor] = useState<any>('');
    const [chickensBantam, setChickensBantam] = useState<any>('');
    const [geneticTraits, setGeneticTraits] = useState<any>('');
    const [handRaised, setHandRaised] = useState<any>('');

    // location
    const [currentPage, setCurrentPage] = useState(1);
    const { GET_ALL_STATE, GET_ALL_SUBURB } = useGQL();
    const { error, loading, data: stateData, refetch: staterefetch } = GET_ALL_STATE();
    const {
        error: suburbErr,
        loading: suburbLoading,
        data: suburbData,
        refetch: suburbRefetch
    } = GET_ALL_SUBURB({ page: currentPage, limit: 10 });

    const [selectedStates, setSelectedStates] = useState<string[]>([]);
    const [selectedSuburbs, setSelectedSuburbs] = useState<Suburb | null>(null);
    const [allSuburbs, setAllSuburbs] = useState<Suburb[]>([]);
    const [localHasNextPage, setlocalHasNextPage] = useState(false);
    const [statusRadio, setStatusRadio] = useState('');
    const [expandedState, setExpandedState] = useState<string | null>(null);

    const fetchSuburb = async (stateName, statePage) => {
        const { data: nextPageData } = await suburbRefetch({
            listSuburbsInput: {
                state: stateName,
                page: statePage,
                limit: 10
            }
        });
        setAllSuburbs((prevAllSuburbs) => [...prevAllSuburbs, ...nextPageData.ListSuburbs.suburbsType]);
        setlocalHasNextPage(nextPageData.ListSuburbs.pagination.hasNextPage);
    };

    useEffect(() => {
        fetchSuburb(selectedStates[0], currentPage);
    }, [selectedStates, currentPage]);

    const onhandleSubmit = () => {
        getFilterValue(purebredRadio);
        getGenderFilterValue(genderRadio);
        getAgeFilterValue(ageRadio);
        getAnimalTypeFilterValue(selectedOption);
        getCategoryFilterValue(selectedCategoryOption);
        getSubCategoryFilterValue(selectedSubCategoryOption);
        getBreedFilterValue(selectedBreedOption);
        getGeneticTraitsValue(geneticTraits);
        getHandRaisedValue(handRaised);
        getSuitedForValue(suitedFor);
        getChickensBantamValue(chickensBantam);
        getLocationValue(selectedSuburbs);
        handleDialogClose();
    };

    const clearSelectedFilters = () => {
        setSelectedStates([]);
        setSelectedSuburbs(null);
        setExpandedState(null);
        setAnimalType(false);
        setAnimalCategory(false);
        setAnimalSubCategory(false);
        setAnimalBreed(false);
        setExtraFields([]);
        setSuitedForList([]);
        setChickensBantamList([]);
        setgeneticTraitsList([]);
        setChosenAnimalType({});
        setChosenCategory({});
        setChosenSubCategory({});
        setBreedList([]);
        setChosenBreed({});
        setSuitedFor('');
        setChickensBantam('');
        setGeneticTraits('');
        setHandRaised('');
        clearFilters();
    };

    const onChangeRadio = (event) => {
        setPurebredRadio(event.target.value);
    };

    const onChangeGenderRadio = (event) => {
        setGenderRadio(event.target.value);
    };

    const onChangeAgeRadio = (event) => {
        setAgeRadio(event.target.value);
    };

    const onChangeStatusRadio = (event) => {
        setStatusRadio(event.target.value);
    };

    const handleStateCheckboxChange = async (stateName: string) => {
        if (selectedStates.includes(stateName)) {
            setSelectedStates((prevSelected) => prevSelected.filter((state) => state !== stateName));
            setAllSuburbs([]);
            // collapse the accordion
            setExpandedState(null);
        } else {
            setSelectedStates([stateName]);
            setAllSuburbs([]);
            fetchSuburb(stateName, 1);
            // expand the accordion
            setExpandedState(stateName);
        }
    };

    /* for multiple suburbs in case required */
    // const handleSuburbCheckboxChange = (event) => {
    //     setSelectedSuburbs((prevSelected) => {
    //         const suburbLocality = event.target.name;
    //         if (event.target.checked) {
    //             if (!prevSelected.includes(suburbLocality)) {
    //                 // not existed then add
    //                 return [...prevSelected, suburbLocality];
    //             }
    //         } else {
    //             return prevSelected.filter((suburb) => suburb !== suburbLocality);
    //         }
    //         return prevSelected;
    //     });
    // };

    // const handleSuburbCheckboxChange = (suburb: Suburb) => {
    //     if (!selectedSuburbs.some((selected) => selected.locality === suburb.locality)) {
    //         // If the suburb is not already selected, add it to the selectedSuburbs array
    //         setSelectedSuburbs((prevSelected) => [...prevSelected, suburb]);
    //     } else {
    //         // If the suburb is already selected, remove it from the selectedSuburbs array
    //         setSelectedSuburbs((prevSelected) => prevSelected.filter((selected) => selected.locality !== suburb.locality));
    //     }
    // };

    const handleSuburbCheckboxChange = (suburb: Suburb) => {
        console.log(suburb, 'suburb');
        if (selectedSuburbs === suburb) {
            // If the clicked suburb is already selected, unselect it
            setSelectedSuburbs(null);
        } else {
            // If a different suburb is clicked, select it
            setSelectedSuburbs(suburb);
        }
    };

    const nextPage = async () => {
        const totalPages = suburbData?.ListSuburbs?.pagination?.total;
        if (currentPage < totalPages) {
            try {
                const { data: nextPageData } = await suburbRefetch({
                    listSuburbsInput: {
                        state: selectedStates[0],
                        page: currentPage + 1,
                        limit: 10
                    }
                });
                // setSuburbData([...suburbData, ...nextPageData]);
                setAllSuburbs((prevAllSuburbs) => [...prevAllSuburbs, ...nextPageData.ListSuburbs.suburbsType]);
                setCurrentPage(currentPage + 1);
                setlocalHasNextPage(nextPageData.ListSuburbs.pagination.hasNextPage);
            } catch {
                console.error('Error fetching data:', error);
            }
        }
    };

    const renderSuburbs = (state) => {
        // if (suburbData?.ListSuburbs?.suburbsType) {
        return (
            <InfiniteScroll
                scrollableTarget={state}
                dataLength={allSuburbs.length || 0}
                next={nextPage}
                hasMore={localHasNextPage}
                loader={
                    <Stack height={60} justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Stack>
                }
            >
                {allSuburbs.map((suburb: Suburb, index) => (
                    <>
                        <Stack key={index} className="suburbs-gap">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={suburb.locality}
                                        checked={selectedSuburbs === suburb}
                                        value={suburb.locality}
                                        sx={{ padding: '1px', margin: '0 9px' }}
                                        icon={<IconCheckbox />}
                                        checkedIcon={<IconChecked />}
                                        onChange={() => handleSuburbCheckboxChange(suburb)}
                                    />
                                }
                                label={`${suburb.locality}, ${suburb.postcode}`}
                            />
                        </Stack>
                    </>
                ))}
                {suburbLoading ? (
                    <Stack>
                        <CircularProgress />
                    </Stack>
                ) : (
                    ''
                )}
            </InfiniteScroll>
        );
    };

    useEffect(() => {
        setSuitedForList([]);
        setChickensBantamList([]);
        setgeneticTraitsList([]);
        if (chosenAnimalType) {
            setExtraFields(chosenAnimalType.extraFields);
        }
    }, [chosenAnimalType]);

    useEffect(() => {
        if (chosenAnimalType && chosenAnimalType.hasCategory) {
            catBreedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: chosenAnimalType.hasCategory
                }
            });
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false
                }
            });
        }
    }, [chosenAnimalType]);

    useEffect(() => {
        if (chosenCategory && chosenCategory.hasCategory) {
            subCatRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: chosenCategory.hasCategory,
                    categoryId: chosenCategory._id,
                    categoryName: chosenCategory.name
                }
            });
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false,
                    categoryId: chosenCategory._id,
                    categoryName: chosenCategory.name
                }
            });
        }
    }, [chosenCategory]);

    useEffect(() => {
        if (chosenSubCategory && chosenSubCategory.hasCategory) {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: true,
                    categoryId: chosenCategory._id,
                    categoryName: chosenSubCategory.name
                }
            });
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false,
                    categoryId: chosenCategory._id,
                    categoryName: chosenSubCategory.name
                }
            });
        }
    }, [chosenSubCategory]);

    useEffect(() => {
        setBreedList([]);
        if (breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds) {
            setBreedList(breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds[0]?.breeds);
        }
    }, [breedData]);

    const getAnimalTypeList = () => {
        if (category) {
            return animalTypeList?.ListNewAnimalType?.newAnimalTypes.filter(
                (item) => item?.category.toLowerCase() === category.toLowerCase()
            );
        }
        return animalTypeList?.ListNewAnimalType?.newAnimalTypes;
    };

    return (
        <Dialog className="animal-filter" open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
            {openDialog && (
                <>
                    <DialogContent>
                        <Stack direction="column" spacing={3}>
                            {/*  <Dropdown
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                selectedBreedOption={selectedBreedOption}
                                setSelectedBreedOption={setSelectedBreedOption}
                            /> */}
                            <Typography variant="h3" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                Species
                                <Button size="small" variant="outlined" color="secondary" onClick={clearSelectedFilters}>
                                    Clear Filter
                                </Button>
                            </Typography>
                            <FormControl>
                                <Autocomplete
                                    id="animalType"
                                    placeholder="Select"
                                    size="small"
                                    open={animalType}
                                    onOpen={() => {
                                        setAnimalType(true);
                                    }}
                                    onClose={() => {
                                        refetch();
                                        setAnimalType(false);
                                        setAnimalCategory(false);
                                        setAnimalSubCategory(false);
                                        setAnimalBreed(false);
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option.name === value.name}
                                    getOptionLabel={(option: any) => `${option.name}`}
                                    options={getAnimalTypeList()}
                                    loading={AnimalTypeLoading}
                                    onChange={(event: any, newValue: any | null) => {
                                        if (newValue) {
                                            setChosenAnimalType(newValue);
                                            setChosenCategory({});
                                            setChosenSubCategory({});
                                            setChosenBreed({});
                                            setSelectedOption(newValue.name);
                                        } else {
                                            setChosenAnimalType('');
                                            setChosenCategory({});
                                            setChosenSubCategory({});
                                            setChosenBreed({});
                                            setSelectedOption({});
                                        }
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        // setInputValue(newInputValue);
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            // eslint-disable-next-line no-underscore-dangle
                                            <li {...props} key={option._id}>
                                                {option.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            name="animalType"
                                            placeholder="Select Animal Type"
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {AnimalTypeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            {extraFields?.find((field) => field.name === 'suitedFor') && (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="suitedFor">Suited For</InputLabel>
                                        <Autocomplete
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    paddingTop: '0px !important',
                                                    paddingBottom: '0px !important'
                                                }
                                            }}
                                            id="suitedFor"
                                            // className={errors.suitedFor && touched.suitedFor ? 'error' : ''}
                                            placeholder="Select"
                                            size="small"
                                            // value={values.suitedFor}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSuitedFor(newValue);
                                                } else {
                                                    setSuitedFor('');
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option === value}
                                            options={extraFields?.find((field) => field.name === 'suitedFor')?.value || []}
                                            getOptionLabel={(option: any) => option || []}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={option._id} aria-selected={false}>
                                                        {option}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="suitedFor"
                                                    placeholder="Select"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}

                            {extraFields?.find((field) => field.name === 'handRaised') && (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="handRaised">Hand raised</InputLabel>
                                        <Autocomplete
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    paddingTop: '0px !important',
                                                    paddingBottom: '0px !important'
                                                }
                                            }}
                                            id="handRaised"
                                            // className={errors.suitedFor && touched.suitedFor ? 'error' : ''}
                                            placeholder="Select"
                                            size="small"
                                            // value={values.suitedFor}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setHandRaised(newValue);
                                                } else {
                                                    setHandRaised('');
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option === value}
                                            options={extraFields?.find((field) => field.name === 'handRaised')?.value || []}
                                            getOptionLabel={(option: any) => option || []}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={option._id} aria-selected={false}>
                                                        {option}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="handRaised"
                                                    placeholder="Select"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}

                            {extraFields?.find((field) => field.name === 'chickensBantam') && (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="chickensBantam">Chickens Bantam</InputLabel>
                                        <Autocomplete
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    paddingTop: '0px !important',
                                                    paddingBottom: '0px !important'
                                                }
                                            }}
                                            id="chickensBantam"
                                            // className={errors.suitedFor && touched.suitedFor ? 'error' : ''}
                                            placeholder="Select"
                                            size="small"
                                            // value={values.suitedFor}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setChickensBantam(newValue);
                                                } else {
                                                    setChickensBantam('');
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option === value}
                                            options={extraFields?.find((field) => field.name === 'chickensBantam')?.value || []}
                                            getOptionLabel={(option: any) => option || []}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={option._id} aria-selected={false}>
                                                        {option}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="chickensBantam"
                                                    placeholder="Select"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}

                            {extraFields?.find((field) => field.name === 'geneticTraits') && (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="geneticTraits">Genetic traits</InputLabel>
                                        <Autocomplete
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    paddingTop: '0px !important',
                                                    paddingBottom: '0px !important'
                                                }
                                            }}
                                            id="geneticTraits"
                                            // className={errors.suitedFor && touched.suitedFor ? 'error' : ''}
                                            placeholder="Select"
                                            size="small"
                                            // value={values.suitedFor}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setGeneticTraits(newValue);
                                                } else {
                                                    setGeneticTraits('');
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option === value}
                                            options={extraFields?.find((field) => field.name === 'geneticTraits')?.value || []}
                                            getOptionLabel={(option: any) => option || []}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={option._id} aria-selected={false}>
                                                        {option}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="geneticTraits"
                                                    placeholder="Select"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}

                            {chosenAnimalType.hasCategory && (
                                <>
                                    <Typography variant="h3">Category</Typography>
                                    <FormControl>
                                        <Autocomplete
                                            id="animalCategory"
                                            placeholder="Select Category"
                                            size="small"
                                            open={animalCategory}
                                            onOpen={() => {
                                                setAnimalCategory(true);
                                            }}
                                            onClose={() => {
                                                setAnimalCategory(false);
                                                setAnimalSubCategory(false);
                                                setAnimalBreed(false);
                                                catBreedRefetch({
                                                    input: {
                                                        animalTypeId: chosenAnimalType._id,
                                                        hasCategory: chosenAnimalType.hasCategory
                                                    }
                                                });
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option.name === value.name}
                                            getOptionLabel={(option: any) => `${option.name}`}
                                            options={catBreedData?.ListCategoriesAndBreeds?.categoriesAndBreeds}
                                            loading={catBreedLoading}
                                            onChange={(event: any, newValue: any | null) => {
                                                if (newValue) {
                                                    setChosenCategory(newValue);
                                                    setSelectedCategoryOption(newValue.name);
                                                    setChosenSubCategory({});
                                                    setChosenBreed({});
                                                } else {
                                                    setChosenCategory({});
                                                    setSelectedCategoryOption('');
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                // setInputValue(newInputValue);
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={option._id}>
                                                        {option.name}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="category"
                                                    placeholder="Select category"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {catBreedLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    {chosenCategory?.hasCategory && (
                                        <>
                                            <Typography variant="h3">Sub Category</Typography>
                                            <FormControl>
                                                <Autocomplete
                                                    id="animalSubCategory"
                                                    placeholder="Select Category"
                                                    size="small"
                                                    open={animalSubCategory}
                                                    onOpen={() => {
                                                        setAnimalSubCategory(true);
                                                    }}
                                                    onClose={() => {
                                                        setAnimalSubCategory(false);
                                                        setAnimalBreed(false);
                                                        subCatRefetch({
                                                            input: {
                                                                animalTypeId: chosenAnimalType._id,
                                                                hasCategory: chosenCategory.hasCategory,
                                                                categoryId: chosenCategory._id,
                                                                categoryName: chosenSubCategory.name
                                                            }
                                                        });
                                                    }}
                                                    isOptionEqualToValue={(option: any, value) => option.name === value.name}
                                                    getOptionLabel={(option: any) => `${option.name}`}
                                                    options={subCatData?.ListCategoriesAndBreeds?.categoriesAndBreeds}
                                                    loading={subCatLoading}
                                                    onChange={(event: any, newValue: any | null) => {
                                                        if (newValue) {
                                                            setChosenSubCategory(newValue);
                                                            setSelectedSubCategoryOption(newValue.name);
                                                            setChosenBreed({});
                                                        } else {
                                                            setChosenSubCategory({});
                                                            setSelectedSubCategoryOption('');
                                                        }
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        // setInputValue(newInputValue);
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            // eslint-disable-next-line no-underscore-dangle
                                                            <li {...props} key={`subcat-${option.name.replace(' ', '-')}`}>
                                                                {option.name}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="subcategory"
                                                            placeholder="Select Sub Category"
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {subCatLoading ? (
                                                                            <CircularProgress color="inherit" size={20} />
                                                                        ) : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                </>
                            )}

                            {(!chosenSubCategory.hasCategory || !chosenCategory.hasCategory) && (
                                <>
                                    <Typography variant="h3">Breed</Typography>
                                    <FormControl>
                                        <Autocomplete
                                            id="breed"
                                            placeholder="Select Breed"
                                            size="small"
                                            // multiple
                                            open={animalBreed}
                                            onOpen={() => {
                                                setAnimalBreed(true);
                                            }}
                                            onClose={() => {
                                                const param = {
                                                    animalTypeId: chosenAnimalType._id,
                                                    hasCategory: false,
                                                    categoryId: chosenCategory._id,
                                                    categoryName: chosenSubCategory.name
                                                };

                                                if (chosenSubCategory) {
                                                    param.categoryId = chosenCategory.id;
                                                    param.categoryName = chosenSubCategory.name;
                                                }
                                                breedRefetch({
                                                    input: param
                                                });
                                                setAnimalBreed(false);
                                            }}
                                            isOptionEqualToValue={(option: any, value) => option.includes(value)}
                                            getOptionLabel={(option: any) => `${option}`}
                                            options={breedList}
                                            loading={breedLoading}
                                            onChange={(event: any, newValue: any | null) => {
                                                if (newValue) {
                                                    setChosenBreed(newValue);
                                                    setSelectedBreedOption(newValue);
                                                } else {
                                                    setChosenBreed([]);
                                                    setSelectedBreedOption([]);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                // setInputValue(newInputValue);
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    <li {...props} key={`${option.replace(' ', '-')}`}>
                                                        {option}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="breed"
                                                    placeholder="Select Breed"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {breedLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}
                            <Typography variant="h3">Purebred</Typography>
                            <FormControl>
                                <RadioGroup aria-labelledby="purebred" defaultValue="" name="purebred" row>
                                    {purebred.map((option, index) => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    name="purebred"
                                                    value={option.value}
                                                    checked={option.value === purebredRadio}
                                                    sx={{ padding: '1px', margin: '0 9px' }}
                                                    icon={<IconCheckbox />}
                                                    checkedIcon={<IconChecked />}
                                                />
                                            }
                                            label={option.label}
                                            onChange={onChangeRadio}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <Divider />
                            <Typography variant="h3">Age range</Typography>

                            <FormControl>
                                <RadioGroup aria-labelledby="age" defaultValue="" name="age" row>
                                    {age.map((option, index) => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    name="age"
                                                    value={option.value}
                                                    checked={option.value === ageRadio}
                                                    sx={{ padding: '1px', margin: '0 9px' }}
                                                    icon={<IconCheckbox />}
                                                    checkedIcon={<IconChecked />}
                                                />
                                            }
                                            label={option.label}
                                            onChange={onChangeAgeRadio}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                            <Divider />
                            <Typography variant="h3">Gender</Typography>

                            <FormControl>
                                <RadioGroup aria-labelledby="gender" defaultValue="" name="gender" row>
                                    {gender.map((option, index) => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    name="gender"
                                                    value={option.value}
                                                    checked={option.value === genderRadio}
                                                    sx={{ padding: '1px', margin: '0 9px' }}
                                                    icon={<IconCheckbox />}
                                                    checkedIcon={<IconChecked />}
                                                />
                                            }
                                            label={option.label}
                                            onChange={onChangeGenderRadio}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                            <Divider sx={{ mt: '10px!important' }} />
                            <Typography variant="h3">Location</Typography>

                            {stateData?.ListState.states.map((state, index) => (
                                <Stack key={state.state} className="location-accordion">
                                    {/* {index > 0 && <Divider sx={{ my: 2 }} />} */}
                                    <Accordion expanded={expandedState === state.state}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            onClick={() => handleStateCheckboxChange(state.state)} // Toggle accordion on click
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                py: 1
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={state.state}
                                                        checked={selectedStates.includes(state.state)}
                                                        onChange={() => handleStateCheckboxChange(state.state)}
                                                        icon={<IconCheckbox />}
                                                        checkedIcon={<IconChecked />}
                                                    />
                                                }
                                                label={state.state}
                                                sx={{ marginLeft: 0 }}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div id={state.state} style={{ height: '200px', overflowY: 'auto' }}>
                                                {renderSuburbs(state.state)}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Stack>
                            ))}

                            <Button fullWidth size="medium" type="button" variant="contained" color="secondary" onClick={onhandleSubmit}>
                                Apply
                            </Button>
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};
export default AnimalFilterList;
