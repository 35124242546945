import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    IconButton,
    Stack,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoDialog = ({ openDialog, handleDialogClose, imageUrl }) => {
    return (
        <Dialog
            className="video-play"
            open={openDialog}
            onClose={handleDialogClose}
            disableEnforceFocus
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        >
            {openDialog && (
                <>
                    <form>
                        <DialogContent>
                            <Box className="upload-video-wrap">
                                <video height="194" width="100%" controls>
                                    <track kind="captions" src="path/to/caption-file.vtt" srcLang="en" label="English Captions" default />
                                    <source src={`${imageUrl}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <IconButton aria-label="Close" color="secondary" onClick={() => handleDialogClose()}>
                                    <CloseIcon sx={{ color: '#cb3e19' }} />
                                </IconButton>
                            </Box>
                        </DialogContent>
                    </form>
                </>
            )}
        </Dialog>
    );
};

export default VideoDialog;
