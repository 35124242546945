/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import { useQuery } from '@apollo/client';
import React, { useState, useEffect, useMemo } from 'react';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    Stack,
    Pagination,
    PaginationItem,
    Checkbox,
    Avatar
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import _debounce from 'lodash.debounce';

import useAlertDialog from 'hooks/common/useAlertDialog';
import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';
// import useGQL from './hooks/useGQL';
import { AppUserFilter } from './AppFilter';
import { Button } from './index.styles';

import useTable from './hooks/useTable';
import useSnackbar from 'hooks/common/useSnackbar';
import useDebouncedSearch from './hooks/useDebounceSearch';

import { headCells } from './constants/variables';
import { AppUser, ArrangementOrder } from './constants/types';
import useGQL from 'hooks/useGQL';
import { DELETE_USER } from 'grapqhl/user/mutations';
import MainCard from 'ui-component/cards/MainCard';
import { useDialog } from './hooks/useDialogs';
import FilterModal from './forms/filterAppUser';
import moment from 'moment';

import { IconChecked, IconCheckbox } from 'constants/icons';
import { orange } from '@mui/material/colors';
import useImageUpload from 'hooks/common/useImageUpload';
import { headCellsBreeders } from './constants/header-cell';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const BreedersTable = () => {
    const [breeders, setBreeders] = useState<AppUser[]>([]);
    const [pinVlaue, setPinValue] = useState(true);
    const [loadingCheck, setLoading] = useState(false);
    const { TableContainer, EnhancedTableHead } = useTable();
    const navigate = useNavigate();
    const { getConfirmation } = useAlertDialog();
    const { handleOpenSnackbar } = useSnackbar();
    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

    const [filterAccountStatus, setFilterAccountStatus] = useState<any>(undefined);
    const [filterLocation, setFilterLocation] = useState<any>();

    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event) {
            setSearch(event?.target.value);
            setPage(0);
        }
    });

    const { GET_BREEDERS_QUERY, ADD_BREEDERS_MUTATION } = useGQL();

    // const [handleDeleteUser, { error: deleteErr, loading: deleteLoading, data: deleteUser }] = DELETE_USER();

    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>('');
    const [rows, setRows] = useState<AppUser[]>([]);
    const [count, setCount] = useState<number>(1);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 10, skip: 0 });
    const { getImageFromAWS } = useImageUpload();
    const { error, loading, data, refetch } = GET_BREEDERS_QUERY({ limit: pageMeta?.limit, skip: 0 });
    const [handleSubmit, { loading: updateLoading }] = ADD_BREEDERS_MUTATION();

    useEffect(() => {
        if (data?.GetAppBreeders?.users && data?.GetAppBreeders?.pagination) {
            const mapped = data?.GetAppBreeders?.users?.map(async (item) => {
                return {
                    imageUrl: await getImageFromAWS(item.profileImage),
                    ...item
                };
            });
            Promise.all(mapped)
                .then((results) => {
                    setBreeders(results);
                })
                .catch((e) => {
                    console.error(e);
                });

            setCount(data.GetAppBreeders.pagination.total);
        }
    }, [data]);

    const handleRefetch = () => {
        refetch({
            input: {
                search,
                limit: pageMeta?.limit,
                skip: search ? 0 : pageMeta?.skip,
                order,
                orderBy,
                verified: filterAccountStatus ? JSON.parse(filterAccountStatus) : undefined,
                location: filterLocation
            }
        });
    };

    const clearFilters = () => {
        // Clear selected states, suburbs, and statusRadio
        setFilterLocation([]);
        setFilterAccountStatus('');

        setPage(0);
        handleRefetch();
    };

    useEffect(() => {
        const limit = rowsPerPage;
        const skip = page - 1 > 0 ? limit * (page - 1) : 0;
        setPageMeta({ limit, skip });
        refetch({
            input: {
                search,
                limit,
                skip,
                order,
                orderBy,
                verified: filterAccountStatus ? JSON.parse(filterAccountStatus) : undefined,
                location: filterLocation
            }
        });
    }, [rowsPerPage, page]);

    useEffect(() => {
        handleRefetch();
    }, [orderBy, order, search, filterAccountStatus, filterLocation]);

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage === 0 ? newPage + 1 : newPage);
    };

    const settingFilterValue = (value: any) => {
        setFilterAccountStatus(value);
    };

    const settingLocationValue = (value: any) => {
        setFilterLocation(value);
    };

    const closeDialog = () => {
        handleDialogClose();
    };
    const OpenFilterModal = () => {
        handleDialogOpen();
    };

    const onSelect = (breeder: AppUser) => {
        const mapped = breeders?.map(async (item) => {
            // eslint-disable-next-line
            if (item._id === breeder._id) {
                return { ...breeder, imageUrl: await getImageFromAWS(item.profileImage) };
            }

            return { ...item };
        });

        Promise.all(mapped)
            .then((results) => {
                setBreeders(results);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const onChecked = async (id) => {
        setLoading(true);
        await handleSubmit({
            variables: {
                id: `${id}`
            },
            onCompleted: (breeder) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Breeders Pin update successfully.',
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );

                onSelect(breeder?.AddPinBreeds?.user);
            },
            onError: (updateImageError: any) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: updateImageError?.message || "Breeders Can't be pinnes",
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        });
        setLoading(false);
    };

    if (loadingCheck) {
        return <Spinner />;
    }

    const cardActions = (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center" spacing={2}>
            <Button variant="outlined" color="secondary" size="large" className="app-user-account-btn" onClick={OpenFilterModal}>
                Filter
            </Button>
            <FilterModal
                {...{ openDialog }}
                getFilterValue={settingFilterValue}
                getLocationValue={settingLocationValue}
                handleDialogClose={closeDialog}
                clearFilters={clearFilters}
            />
            <AppUserFilter debouncedSearch={debouncedSearch} />
        </Stack>
    );

    return (
        <MainCard title="Breeders" content={false} secondary={cardActions}>
            <>
                {/* table */}

                {loading ? (
                    <Spinner />
                ) : error ? (
                    <FailureLoad />
                ) : (
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    headCells={headCellsBreeders}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {breeders.map((row: any) => {
                                        const labelId = `enhanced-table-checkbox-${row._id}`;
                                        return (
                                            <TableRow key={row._id}>
                                                <TableCell align="left">
                                                    <Avatar
                                                        sx={{ bgcolor: orange[500] }}
                                                        alt="Avatar1"
                                                        // @ts-ignore
                                                        src={row.imageUrl || process.env.REACT_APP_IMAGE_PLACEHOLDER}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{row.firstName}</TableCell>
                                                <TableCell align="left">{row.lastName}</TableCell>
                                                <TableCell align="left">{row.username}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.mobile}</TableCell>
                                                <TableCell align="left">{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align="left">{row?.address?.fullAddress}</TableCell>
                                                <TableCell align="right">
                                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                                        <Checkbox
                                                            checked={row.pinOrder > 0}
                                                            onChange={() => {
                                                                onChecked(row._id);
                                                            }}
                                                            icon={<IconCheckbox />}
                                                            checkedIcon={<IconChecked />}
                                                            inputProps={{
                                                                'aria-labelledby': labelId
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Pagination
                            count={Math.ceil(count / rowsPerPage)}
                            color="primary"
                            shape="rounded"
                            onChange={handleChangePage}
                            page={page === 0 ? 1 : page}
                            renderItem={(item) => <PaginationItem {...item} />}
                            // hidePrevButton={page === 0 ?? true}
                        />
                    </>
                )}
            </>

            <Stack direction="row" justifyContent="flex-end" spacing={2.5} px={4} pb={3.5}>
                <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        navigate('/pin/animals-breeders');
                    }}
                >
                    Cancel
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        navigate('/pin/animals-breeders');
                    }}
                >
                    Done
                </Button>
            </Stack>
        </MainCard>
    );
};

export default BreedersTable;
