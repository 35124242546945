// project imports
import { Theme } from '@mui/material/styles';

export default function componentStyleOverrides(theme: Theme, borderRadius: number, outlinedFilled: boolean) {
    const mode = theme.palette.mode;
    const bgColor = mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
    const menuSelectedBack = mode === 'dark' ? theme.palette.secondary.main + 15 : theme.palette.secondary.light;
    const menuSelected = mode === 'dark' ? theme.palette.secondary.main : theme.palette.grey[900];

    return {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-outlined': {
                        position: 'relative',
                        left: 'auto',
                        top: 'auto!important',
                        transform: 'none',
                        fontSize: '.875rem',
                        fontWeight: '500',
                        color: theme.palette.text.secondary,
                        marginBottom: '0.25rem'
                    },
                    '& .MuiFormGroup-root': {
                        paddingLeft: '2px',
                        '& .MuiFormControlLabel-root': {
                            paddingBottom: '5px'
                        }
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiFormControlLabel-label': {
                        fontWeight: '500',
                        paddingLeft: '3px',
                        paddingRight: '18px'
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontWeight: '500',
                    marginLeft: '0',
                    fontSize: '0.875rem',
                    marginTop: '0.563rem',
                    '&.Mui-error': {
                        color: theme.palette.error.dark
                    },
                    '&:empty': {
                        display: 'none'
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    display: 'block',
                    fontWeight: '500',
                    fontSize: '.875rem',
                    color: theme.palette.text.secondary,
                    marginBottom: '0.25rem',
                    '&.MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: theme.palette.text.secondary
                        },
                        '&.Mui-error': {
                            color: theme.palette.error.dark
                        }
                    },
                    '&.image-upload-button span': {
                        color: '#45110B',
                        fontFamily: '"Plus Jakarta Sans", sans-serif !important',
                        textTransform: 'none'
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.MuiRadio-colorPrimary': {
                        '& .MuiSvgIcon-root': {
                            fill: theme.palette.secondary[200]
                        },
                        '&.Mui-checked': {
                            '& .MuiSvgIcon-root': {
                                fill: theme.palette.primary.light
                            }
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.MuiCheckbox-colorPrimary': {
                        '& .MuiSvgIcon-root': {
                            fill: theme.palette.secondary[200]
                        },
                        '&.Mui-checked': {
                            '& .MuiSvgIcon-root': {
                                fill: theme.palette.primary.light
                            }
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '8px',
                    boxShadow: 'none',
                    '&:after': {
                        content: '" "',
                        border: '6px solid transparent',
                        position: 'absolute',
                        top: '-6px',
                        left: '-6px',
                        width: 'calc(100% + 12px)',
                        height: 'calc(100% + 12px)',
                        backgroundColor: 'transparent',
                        borderRadius: '14px',
                        transition: 'all .15s ease-in-out'
                    },
                    '&[class*=Secondary]:hover': {
                        boxShadow: 'none',
                        '&:after': {
                            borderColor: '#F7D5AF'
                        }
                    },
                    '&[class*=Primary]:hover,&[class*=Info]:hover': {
                        boxShadow: 'none',
                        '&:after': {
                            borderColor: 'rgba(203, 62, 25, 0.5)'
                        }
                    },
                    '&.MuiButton-containedSecondary': {
                        color: theme.palette.primary.main
                    },
                    '&.MuiButton-sizeLarge': {
                        minWidth: '116px',
                        fontSize: '1rem',
                        padding: '10px 15px'
                    },
                    '&.MuiButton-outlinedPrimary': {
                        color: `${theme.palette.primary.light}!important`,
                        borderColor: theme.palette.warning.light,
                        backgroundColor: theme.palette.background.paper
                    },
                    '&.MuiButton-outlinedSecondary': {
                        color: `${theme.palette.primary.main}!important`,
                        backgroundColor: theme.palette.background.paper
                    },
                    '&.MuiButton-sizeMedium': {
                        padding: '9px 0',
                        minWidth: '40px',
                        minHeight: '40px',
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.dark
                        },
                        '& path': {
                            fill: `${theme.palette.grey[900]}!important`
                        }
                    },
                    '&.MuiButton-outlinedInfo': {
                        borderColor: theme.palette.secondary[200],
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.primary.main
                    },
                    '&.MuiButton-outlinedWarning': {
                        borderColor: theme.palette.secondary[200],
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.main,
                        fontSize: '1rem',
                        padding: '16px 24px 16px 20px',
                        borderRadius: '16px',
                        '& .MuiButton-startIcon': {
                            marginRight: '11px',
                            '& svg': {
                                fontSize: '18px!important'
                            }
                        }
                    },
                    '&.MuiButton-containedWarning': {
                        borderRadius: '9px',
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.main,
                        padding: '12px',
                        boxShadow: 'none',
                        '& svg path': {
                            fill: `${theme.palette.secondary.main}!important`
                        }
                    } /* ,
                    '&.app-user-back-btn': {
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontStyle: 'normal'
                    },
                    '& .app-user-account-btn': {
                        fontSize: '16px !important',
                        fontWeight: '500',
                        lineHeight: '20px !important'
                    } */
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.MuiIconButton-colorSecondary': {
                        color: mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.main,
                        background: mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[100],
                        borderRadius: '8px',
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
                            '& svg': {
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.grey[100]
                            }
                        }
                    }
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    '&.MuiPaper-outlined': {
                        border: `1px solid ${theme.palette.secondary.light}`,
                        height: '100%',
                        padding: '12px'
                    },
                    '& .datePickerWrap': {
                        '& .MuiTextField-root': {
                            width: '100%'
                        }
                    },
                    '&.subsTable': {
                        '& .MuiTableContainer-root': {
                            padding: '0',
                            '& .MuiTableCell-body': {
                                padding: '25px 8px 30px 0',
                                '&:last-of-type': {
                                    paddingRight: '30px!important'
                                }
                            }
                        }
                    },
                    '& .buttonInfo': {
                        border: `1px solid ${theme.palette.secondary[200]}`,
                        backgroundColor: theme.palette.background.paper,
                        textAlign: 'center',
                        padding: '12px 15px',
                        borderRadius: '8px',
                        '& .MuiTypography-root': {
                            color: theme.palette.primary.main
                        }
                    }
                },
                rounded: {
                    borderRadius: `${borderRadius}px`
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        color: theme.palette.text.dark
                    },
                    '& .MuiBreadcrumbs-separator': {
                        marginLeft: '4px',
                        marginRight: '4px',
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.grey[500]
                        }
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '& .sliderCaption': {
                        position: 'relative',
                        left: '0',
                        bottom: '0',
                        width: '100%',
                        backgroundColor: 'rgba(233, 225, 213, 0.9)',
                        padding: '11px 10px 10px 15px'
                    },
                    '& .familyCaption': {
                        position: 'absolute',
                        left: '0',
                        bottom: '0',
                        // width: '0',
                        backgroundColor: 'rgba(233, 225, 213, 0.9)',
                        padding: '11px 10px 10px 15px',
                        borderRadius: '0 8px 0 8px',

                        '&.no-left-gap': {
                            left: '0'
                        }
                    },
                    '&.primaryTitle': {
                        '& .MuiCardHeader-root': {
                            '& .MuiTypography-h3': {
                                paddingLeft: '4px',
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                color: theme.palette.primary.main
                            }
                        }
                    },
                    '& .filledCard': {
                        padding: '24px 20px 25px',
                        borderRadius: '1rem',
                        backgroundColor: theme.palette.secondary.light,
                        '& .MuiButton-outlinedPrimary': {
                            borderColor: theme.palette.secondary[200],
                            backgroundColor: theme.palette.background.paper,
                            color: `${theme.palette.primary.main}!important`
                        },
                        '& ul': {
                            margin: '0 0 0 15px',
                            padding: '0',
                            '& li': {
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                padding: '0.25rem 0 0.5rem',
                                color: theme.palette.text.secondary,
                                '&::marker': {
                                    fontSize: '12px',
                                    color: theme.palette.primary.light
                                }
                            }
                        }
                    },
                    '& .ycp-animal-list': {
                        '& button .MuiTypography-subtitle1': {
                            fontSize: '12px',
                            fontWeight: 500
                        },
                        '& .MuiTabs-scroller': {
                            overflowX: 'auto !important'
                        }
                    },
                    '& .ycp-animal-list-content': {
                        '& .MuiCardContent-root': {
                            paddingLeft: '0px',
                            paddingTop: '10px'
                        }
                    },
                    '& .profile-about': {
                        maxWidth: '362px',
                        '& img': {
                            borderRadius: '8px',
                            WebkitBorderRadius: '8px',
                            MozBorderRadius: '8px',
                            msBorderRadius: '8px',
                            maxHeight: '178px'
                        }
                    },
                    '& .profile-about-map': {
                        '& img': {
                            WebkitBorderRadius: '8px',
                            MozBorderRadius: '8px',
                            msBorderRadius: '8px',
                            maxHeight: '178px'
                        }
                    },
                    '& .animal-tab-filter-btn': {
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '8px 29px 8px 17px!important',
                        '& .MuiButton-startIcon': {
                            marginRight: '14px'
                        }
                    },
                    '& a.animal-tab-filter-btn': {
                        fontSize: '14px',
                        fontWeight: 500,
                        marginLeft: '10px',
                        padding: '8px 17px!important'
                    },
                    '& .animal-listing': {
                        fontSize: '14px',
                        fontWeight: 400,
                        '& .MuiImageListItem-root .img-wrap img': {
                            borderRadius: '8px',
                            webkitBorderRadius: '8px',
                            mozBorderRadius: '8px',
                            msBorderRadius: '8px'
                        },
                        '& .MuiImageListItemBar-title': {
                            color: '6A5757',
                            fontSize: '18px',
                            fontWeight: 700
                        },
                        '& .animal-listing-name': {
                            color: '#6A5757',
                            fontSize: '14px',
                            marginRight: '14px'
                        },
                        '& .animal-listing-gender': {
                            color: '#6A5757',
                            fontSize: '14px',
                            marginRight: '14px'
                        }
                    },
                    '& .animal-photo-gallery': {
                        fontSize: '12px !important',
                        fontWeight: '400',
                        '& .MuiTypography-body2': {
                            color: '#45110B'
                        },
                        '& .MuiTypography-subtitle1': {
                            color: '#6A5757',
                            fontSize: '12px !important',
                            fontStyle: 'normal'
                        },
                        '& .gallery-delete-icon': {
                            cursor: 'pointer',
                            textAlign: 'right',
                            display: 'flex',
                            width: '34px',
                            height: '34px',
                            padding: '5px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: 0,
                            borderRadius: '8px',
                            border: '1px solid var(--brand-cream, #E9E1D5)',
                            background: 'var(--neutrals-white, #FFF)',
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1
                        }
                    },
                    '& td.MuiTableCell-root': {
                        fontSize: '14px',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        lineHeight: '20px'
                    },
                    '& .image-upload-dialog-remove-icon': {
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 8,
                        right: 10,
                        zIndex: 1
                    },
                    '& .image-upload-dialog-edit-icon': {
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 8,
                        right: 40,
                        zIndex: 1
                    },
                    '& .animal-listing-name-bar': {
                        '& .MuiImageListItemBar-title': {
                            fontSize: '18px',
                            fontWeight: '700',
                            color: '#45110B',
                            lineHeight: '22px'
                        }
                    },
                    '& .app-user-subscription-table': {
                        '& th span': {
                            color: '#757575',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: 'normal'
                        }
                    },
                    '& .app-user-account': {
                        '& .MuiInputLabel-formControl': {
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '17px'
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '20px'
                        }
                    } /* ,
                    '& .app-user-account-btn': {
                        fontSize: '16px !important',
                        fontWeight: '500',
                        lineHeight: '20px !important'
                    } */
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    padding: '24px 24px 21px 16px',
                    '& .MuiTextField-root': {
                        width: '226px',
                        '& .MuiOutlinedInput-root': {
                            paddingLeft: '16px',
                            backgroundColor: `${theme.palette.grey[400]}!important`,
                            '& .MuiSvgIcon-root': {
                                color: theme.palette.grey[500]
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.grey[500],
                                fontSize: '14px',
                                fontWeight: '700',
                                padding: '14px 14px 14px 0!important',
                                // border: 'none!important',
                                backgroundColor: `${theme.palette.grey[400]}!important`,
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey[400]} inset`,
                                    WebkitTextFillColor: `${theme.palette.grey[500]}!important`
                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                // border: 'none!important',
                                borderColor: theme.palette.secondary.light,
                                borderRadius: '12px'
                            },
                            '&:hover, &:focus': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.text.secondary
                                }
                            }
                        }
                    },
                    '& .MuiButton-root': {
                        minWidth: '116px'
                    },
                    '& .MuiButton-sizeLarge': {
                        padding: '9px 15px'
                    }
                },
                title: {
                    // fontSize: '1.25rem'
                },
                header: {
                    padding: '20px 16px 16px'
                },
                content: {
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '31px 36px',
                    '& .tox-tinymce': {
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: `${borderRadius}px`,
                        marginBottom: '5rem'
                    }
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: '0'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[900],
                    paddingTop: '14px!important',
                    paddingBottom: '14px!important',

                    '&.Mui-selected': {
                        color: menuSelected,
                        backgroundColor: menuSelectedBack,
                        '&:hover': {
                            backgroundColor: menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: menuSelectedBack,
                        color: menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    minWidth: '24px!important',
                    minHeight: '20px',
                    margin: '0 12px 0 0!important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    margin: '0'
                },
                primary: {
                    color: theme.palette.text.dark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.dark,
                    '&::placeholder': {
                        color: theme.palette.text.secondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: `${theme.palette.grey[100]}!important`,
                    borderRadius: '1rem',
                    '& .MuiInputBase-input': {
                        backgroundColor: `${theme.palette.grey[100]}!important`,
                        borderRadius: '1rem',
                        padding: '18px 14px!important',
                        fontSize: '1rem',
                        color: `${theme.palette.primary[200]}!important`,
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey[100]} inset`,
                            WebkitTextFillColor: `${theme.palette.primary[200]}!important`
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        top: '0',
                        borderColor: theme.palette.secondary[200],
                        borderRadius: '1rem',
                        '& legend': {
                            display: 'none'
                        }
                    },

                    // '& .MuiOutlinedInput-notchedOutline': {
                    //     borderColor: mode === 'dark' ? theme.palette.text.primary + 28 : theme.palette.grey[400]
                    // },
                    '&:hover $notchedOutline': {
                        borderColor: theme.palette.primary.light
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.secondary,
                            borderWidth: '1px'
                        }
                    },
                    '&.Mui-error': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.error.dark
                        }
                    },
                    '& .MuiInputAdornment-root': {
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.grey[800]
                        }
                    }
                },
                input: {
                    fontWeight: 500,
                    background: outlinedFilled ? bgColor : 'transparent',
                    padding: '15.5px 14px',
                    borderRadius: `${borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                inputAdornedEnd: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: mode === 'dark' ? theme.palette.text.primary + 50 : theme.palette.grey[300]
                    }
                },
                mark: {
                    backgroundColor: theme.palette.background.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.secondary.light,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '.MuiChip-deleteIcon': {
                            color: mode === 'dark' ? theme.palette.text.primary + 80 : theme.palette.secondary[200]
                        }
                    }
                },
                popper: {
                    borderRadius: `${borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.divider,
                    opacity: mode === 'dark' ? 0.2 : 1,
                    '&[color="primary"]': {
                        borderColor: theme.palette.primary[200]
                    },
                    '&[color="secondary"]': {
                        borderColor: theme.palette.secondary[200]
                    },
                    '&[color="secondaryLight"]': {
                        borderColor: theme.palette.secondary.light
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                // root: {
                //     '& .MuiSelect-nativeInput': {
                //         '&:placeholder': {
                //             fontSize: '16px'
                //         }
                //     }
                // },
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    // color: mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.main,
                    // background: mode === 'dark' ? theme.palette.text.primary : theme.palette.grey[100],
                    // borderRadius: 2,
                    '&:hover': {
                        // background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
                        '& svg': {
                            // color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.grey[100]
                        }
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    },
                    '&.MuiChip-colorSecondary': {
                        borderColor: theme.palette.secondary.light
                    },
                    '&.location-chip': {
                        borderColor: '#E9E1D5',
                        borderRadius: '5px',
                        padding: '8px',
                        marginRight: '16px',
                        height: 'auto',
                        marginBottom: '10px',

                        '&:hover': {
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)'
                        },

                        '.MuiSvgIcon-root': {
                            color: '#45110B',
                            marginLeft: 0
                        },

                        '.MuiChip-label': {
                            color: '#6A5757',
                            fontSize: '16px',
                            fontWeight: 500,
                            textTransform: 'inherit'
                        }
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: mode === 'dark' ? theme.palette.dark[900] : theme.palette.primary.light,
                    '& .MuiTabs-flexContainer': {
                        borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.primary[200]
                    },
                    '& .MuiTab-root': {
                        color: mode === 'dark' ? theme.palette.text.secondary : theme.palette.grey[900]
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.primary.dark
                    },
                    '& .Mui-selected': {
                        color: theme.palette.primary.dark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-flexContainer': {
                        borderColor: theme.palette.grey[300],
                        '& .MuiTab-root': {
                            minWidth: 'auto',
                            padding: '12px 0 12px 3px',
                            // padding: '0',
                            minHeight: '48px',
                            margin: '0 38px 0 0',
                            '& .MuiTypography-root': {
                                marginLeft: '5px'
                            },
                            '& svg': {
                                width: '30px',
                                margin: '0'
                            }
                        },
                        '& .Mui-selected': {
                            // backgroundColor: 'transparent',
                            '& .MuiTypography-root': {
                                color: `${theme.palette.primary[800]}!important`
                            },
                            '& .fillIcon': {
                                '& path': {
                                    fill: theme.palette.primary[800]
                                }
                            },
                            '& .strokeIcon': {
                                '& path': {
                                    stroke: theme.palette.primary[800]
                                }
                            }
                        }
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.primary.light
                    }
                },
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.grey[200]
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiDialogActions-root': {
                        '& .MuiButton-textSuccess': {
                            padding: '5px 8px',
                            border: `1px solid ${theme.palette.warning.light}`,
                            color: `${theme.palette.primary.main}!important`,
                            backgroundColor: theme.palette.background.paper
                        },
                        '& .MuiButton-textError': {
                            padding: '5px 8px',
                            color: `${theme.palette.primary.light}!important`,
                            border: `1px solid ${theme.palette.warning.light}`,
                            backgroundColor: theme.palette.background.paper
                        }
                    }
                },
                paper: {
                    borderRadius: '0',
                    boxShadow: 'none',
                    width: '50%',
                    padding: '12px 0 12px 0',
                    '& .MuiDialogTitle-root': {
                        padding: '10px 24px 13px'
                    },
                    '& .MuiTypography-h3, & .MuiDialogTitle-root': {
                        fontSize: '1.123rem',
                        fontWeight: '700',
                        color: theme.palette.primary.main
                    },
                    '& .MuiTypography-h4': {
                        fontFamily: '"Roboto"',
                        fontSize: '1.25rem',
                        fontWeight: '700',
                        color: theme.palette.text.dark
                    },
                    '& .MuiPaper-outlined': {
                        borderColor: `${theme.palette.dark.light} !important`,
                        padding: '0!important',
                        '& .MuiCardHeader-root': {
                            padding: '6px 16px',
                            borderBottom: `1px solid ${theme.palette.dark.light}`,
                            '& .MuiCardHeader-title': {
                                fontFamily: '"Roboto"',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                color: theme.palette.text.dark
                            }
                        },
                        '& .MuiCardContent-root': {
                            padding: '10px 16px 8px',
                            '& .MuiTypography-root': {
                                fontFamily: '"Roboto"',
                                color: theme.palette.text.dark
                            },
                            '& .MuiTableCell-head': {
                                fontFamily: '"Roboto"',
                                padding: '0 0 12px',
                                color: theme.palette.grey[500]
                            },
                            '& .MuiTableCell-body': {
                                padding: '0',
                                fontFamily: '"Roboto"',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                color: theme.palette.text.dark
                            }
                        }
                    },
                    '& .image-upload-button': {
                        marginBottom: '10px',
                        '& .span': {
                            color: '#45110B',
                            fontFamily: '"Plus Jakarta Sans", sans-serif !important',
                            textTransform: 'inherit !important'
                        }
                    }
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    padding: '0 29px 0 21px'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    padding: '14px 8px 22px 0',
                    border: 'none!important',
                    '&.MuiTableCell-head': {
                        fontSize: '0.75rem',
                        color: theme.palette.grey[600],
                        fontWeight: 500,
                        border: 'none!important',
                        padding: '4px 8px 27px 0'
                    },
                    '&:last-child': {
                        textAlign: 'right',
                        paddingRight: '0!important'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.palette.background.paper,
                    background: theme.palette.text.primary
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main,
                    fontSize: '1.125rem'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDivider-root': {
                        borderColor: theme.palette.secondary.light,
                        marginTop: '0!important',
                        marginBottom: '0.25rem!important'
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    margin: '10px 16px 6px!important',
                    '& .MuiToolbar-root': {
                        padding: '0',
                        '& .MuiInputBase-root': {
                            borderRadius: `${borderRadius}px`,
                            backgroundColor: theme.palette.grey[400],
                            color: theme.palette.grey[600],
                            '& .MuiTablePagination-select': {
                                paddingRight: '32px'
                            }
                        },
                        '& .MuiTablePagination-actions': {
                            '& .MuiIconButton-sizeMedium': {
                                borderRadius: '50%',
                                backgroundColor: theme.palette.grey[400],
                                color: theme.palette.grey[600],
                                width: '24px',
                                height: '24px',
                                marginRight: '4px',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.25rem'
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    margin: '10px 16px 20px!important',
                    '& .MuiPagination-ul': {
                        '& li': {
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme.palette.grey[400],
                            '&:first-of-type': {
                                borderTopLeftRadius: `${borderRadius}px`,
                                borderBottomLeftRadius: `${borderRadius}px`,
                                paddingLeft: '8px'
                            },
                            '&:last-of-type': {
                                borderTopRightRadius: `${borderRadius}px`,
                                borderBottomRightRadius: `${borderRadius}px`,
                                paddingRight: '8px'
                            },
                            '& button[aria-label$="page 1"], & button[aria-label$="page 2"], & button[aria-label$="page 3"], & button[aria-label$="page 4"], & button[aria-label$="page 5"],& button[aria-label$="page 6"], & button[aria-label$="page 7"], & button[aria-label$="page 8"], & button[aria-label$="page 9"]':
                                {
                                    '&:before': {
                                        content: '"0"'
                                    }
                                }
                        }
                    },
                    '& .MuiPaginationItem-root': {
                        margin: '4px 8px 4px 4px',
                        minWidth: '22px',
                        width: '22px',
                        height: '22px',
                        padding: '0px',
                        fontSize: '.75rem',
                        fontWeight: '500',
                        color: theme.palette.primary.light,
                        borderRadius: `${borderRadius / 3}px`,
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.background.paper,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main
                            }
                        }
                    },
                    '& .MuiPaginationItem-previousNext': {
                        borderRadius: '50%',
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.grey[600],
                        width: '24px',
                        height: '24px',
                        marginRight: '4px'
                    }
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '& .MuiButton-sizeSmall': {
                        fontSize: '1rem',
                        fontWeight: '500',
                        padding: '4px 16px',
                        minWidth: '145px'
                    }
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    '& .MuiPickersDay-root, .MuiYearPicker-root, .MuiMonthPicker-root': {
                        borderRadius: '0',
                        '&.MuiPickersDay-today': {
                            borderColor: theme.palette.secondary.main
                        },
                        '&.Mui-selected, .Mui-selected': {
                            borderRadius: '0',
                            backgroundColor: `${theme.palette.secondary.main}!important`,
                            color: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.primary.main
                            }
                        },
                        '& button:hover, button:active,  button:focus': {
                            borderRadius: '0'
                        }
                    }
                }
            }
        }
    };
}
