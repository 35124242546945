import { gql } from '@apollo/client';

export const GET_ALL_REPORT_USERS = gql`
    query GetReportUserList($input: GetReportUserListDTO!) {
        getReportUserList(input: $input) {
            message
            reportUserList {
                _id
                reason
                lastName
                createdAt
                firstName
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_REPORT_DETAILS = gql`
    query GetReportUserDetails($input: ReportIdInput!) {
        getReportUserDetails(input: $input) {
            reason
            description
            firstName
            lastName
            userId
            reporterFirstName
            reporterLastName
            createdAt
        }
    }
`;
