import { gql } from '@apollo/client';

export const GET_ALL_ACCOUNT_SUBSCRIPTIONS = gql`
    query GetAccountSubscriptionList($input: GetAccountSubscriptionListDTO!) {
        getAccountSubscriptionList(input: $input) {
            message
            accountSubscriptionList {
                _id
                status
                nextPayment
                firstName
                lastName
                packageName
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ACCOUNT_SUBSCRIPTION_DETAILS = gql`
    query GetAccountSubscriptionDetails($input: AccountSubscriptionIdInput!) {
        getAccountSubscriptionDetails(input: $input) {
            firstName
            lastName
            userId
            title
            subtitle
            detail
            price
            duration
            expirationDate
        }
    }
`;

export const GET_ACCOUNT_SUBSCRIPTION_DETAILS_BY_USER = gql`
    query AccountSubscriptionDetailByUser($input: AccountSubscriptionUserIdInput!) {
        AccountSubscriptionDetailByUser(input: $input) {
            message
            subscriptionDetail {
                firstName
                lastName
                userId
                title
                subtitle
                detail
                price
                duration
                expirationDate
            }
        }
    }
`;
