/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import { Grid, FormLabel, Switch, Stack, Button, Divider } from '@mui/material';
import { Formik } from 'formik';
import Spinner from 'components/spinner';
import { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useMutation, useQuery } from '@apollo/client';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';
import { GET_SETTINGS } from 'grapqhl/settings/queries';
import { UPDATE_SETTINGS } from 'grapqhl/settings/mutation';

const Setting = () => {
    const dispatch = useDispatch();
    const [initialData, setInitialData] = useState<{ isLoading: boolean; data: Array<any> }>({ isLoading: true, data: [] });
    const { data, refetch } = useQuery(GET_SETTINGS);
    const [handleUpdate] = useMutation(UPDATE_SETTINGS);
    useEffect(() => {
        if (data?.getSettings) {
            setInitialData({ isLoading: false, data: data?.getSettings.settings });
        }
    }, [data]);
    const onhandleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedSettings = await handleUpdate({ variables: { input: { input: values.data } } });
            dispatch(
                openSnackbar({
                    open: true,
                    message: updatedSettings?.data?.updateSettings?.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
            refetch();
        } catch (err: any) {
            setSubmitting(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: err.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
    return (
        <MainCard title="Settings" className="primaryTitle" contentSX={{ padding: '0 20px' }} headerSX={{ padding: '20px' }}>
            <Formik initialValues={initialData} enableReinitialize onSubmit={onhandleSubmit}>
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        {values.isLoading ? (
                            <Spinner />
                        ) : (
                            <Grid container spacing={3}>
                                {values?.data?.map((rowData: any, key: number) => {
                                    return (
                                        rowData?.slug === 'hide-hunting-dogs' && (
                                            <>
                                                <Grid item xs={6} sm={4} ml={2}>
                                                    <FormLabel component="legend">Hide hunting dogs</FormLabel>
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <Switch
                                                        checked={!!values?.data[key]?.hide}
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name={`data.${key}.hide`}
                                                        color="secondary"
                                                        value={values.data[key].hide}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    );
                                })}
                                <Grid item xs={12} sx={{ pt: '34px!important', pb: '8px' }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2.75} sx={{ pr: 2 }}>
                                        <Button disabled={isSubmitting} size="large" type="submit" variant="contained" color="secondary">
                                            Save Changes
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};

export default Setting;
