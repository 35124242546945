import { gql } from '@apollo/client';

export const CHANGE_PIN_BREED_INDEX = gql`
    mutation ChangeBreederIndex($id: String!, $index: Float!) {
        ChangeBreederIndex(id: $id, index: $index) {
            message
            pagination {
                total
                hasNextPage
            }
            users {
                _id
                about
                active
                authProvider
                authProviderId
                bannerImage
                bio
                clubOrAssociations
                createdAt
                email
                facebookLink
                firstName
                googleStaticMapImage
                instagramLink
                isParent
                lastLoggedInAt
                lastName
                location {
                    coordinates
                    type
                }
                mobile
                password
                pinOrder
                profileImage
                status
                totalCredit
                username
                updatedAt
                verified
                website
            }
        }
    }
`;

export const PIN_BREEDERS = gql`
    mutation AddPinBreeds($id: String!) {
        AddPinBreeds(id: $id) {
            user {
                _id
                authProvider
                authProviderId
                password
                firstName
                username
                lastName
                mobile
                email
                status
                bio
                instagramLink
                facebookLink
                googleStaticMapImage
                verified
                about
                active
                pinOrder
                isParent
                clubOrAssociations
                totalCredit
                bannerImage
                profileImage
                updatedAt
                createdAt
                lastLoggedInAt
            }
        }
    }
`;

export const UNPIN_BREEDERS = gql`
    mutation UnPinBreeders($id: String!) {
        UnPinBreeders(id: $id) {
            message
        }
    }
`;

export const SET_PIN_PETS = gql`
    mutation AddPinPets($id: String!) {
        AddPinPets(id: $id) {
            _id
            listingName
            animalType
            breed
            animalCategory
            animalSubCategory
            desexed
            gender
            price
            pureBred
            createdAt
            dateOfBirth
            media {
                isCover
                caption
                file
            }
            pinOrder
        }
    }
`;

export const UNPINNED_PETS = gql`
    mutation UnpinnedPets($id: String!) {
        UnpinnedPets(id: $id) {
            message
        }
    }
`;

export const UPDATE_PETS_INDEX = gql`
    mutation ChangePetsIndex($input: ChangeIndexOptions!) {
        ChangePetsIndex(input: $input) {
            message
            salesAnimalList {
                _id
                listingName
                animalType
                animalCategory
                breed
                animalSubCategory
                desexed
                gender
                price
                pureBred
                createdAt
                dateOfBirth
                media {
                    isCover
                    caption
                    file
                }
                pinOrder
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

// livestock

export const CHANGE_LIVESTOCK_INDEX = gql`
    mutation ChangeLivestockIndex($input: ChangeIndexOptions!) {
        ChangeLivestockIndex(input: $input) {
            message
            pagination {
                total
                hasNextPage
            }
            salesAnimalList {
                _id
                animalCategory
                animalSubCategory
                animalType
                breed
                createdAt
                dateOfBirth
                desexed
                gender
                listingName
                media {
                    isCover
                    file
                    caption
                }
                pinOrder
                price
                pureBred
            }
        }
    }
`;

export const ADD_PIN_LIVESTOCK = gql`
    mutation AddPinLivestock($id: String!) {
        AddPinLivestock(id: $id) {
            _id
            animalCategory
            animalSubCategory
            animalType
            breed
            createdAt
            dateOfBirth
            desexed
            gender
            listingName
            media {
                caption
                file
                isCover
            }
            pinOrder
            price
            pureBred
        }
    }
`;

export const UNPIN_LIVESTOCK = gql`
    mutation UnpinnedLivestock($id: String!) {
        UnpinnedLivestock(id: $id) {
            message
        }
    }
`;
