import { gql } from '@apollo/client';

export const GET_ALL_TRANSACTIONS_HISTORY = gql`
    query GetTransactionHistoryList($input: GetTransactionHistoryListDTO!) {
        getTransactionHistoryList(input: $input) {
            message
            transactionHistoryList {
                _id
                nextPayment
                firstName
                lastName
                price
                packageName
                createdAt
                paymentType
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ALL_TRANSACTIONS_HISTORY_BY_USER = gql`
    query getTransactionHistoryListByUser($input: GetTransactionHistoryListDTO!) {
        getTransactionHistoryListByUser(input: $input) {
            message
            transactionHistoryList {
                _id
                nextPayment
                firstName
                lastName
                price
                packageName
                createdAt
                paymentType
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_TRANSACTION_HISTORY_DETAILS = gql`
    query GetTransactionHistoryDetails($input: TransactionHistoryIdInput!) {
        getTransactionHistoryDetails(input: $input) {
            firstName
            lastName
            userId
            title
            detail
            price
            duration
            invoiceId
            expirationDate
            createdAt
            paymentTitle
            paymentType
        }
    }
`;
