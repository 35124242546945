import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
    mutation ($id: String!, $input: UpdateAdminDTO!) {
        updateAdmin(id: $id, input: $input) {
            message
            admin {
                _id
                firstName
                lastName
                email
                status
                role
                phone
                profileImage
            }
        }
    }
`;

export const UPDATE_ADMIN_PROFILE_IMAGE = gql`
    mutation ($id: String!, $input: UpdateAdminProfileDTO!) {
        updateAdminProfileImage(id: $id, input: $input) {
            message
            admin {
                _id
                name
                email
                status
                role
                phone
            }
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation ($id: String!) {
        removeAdmin(id: $id) {
            message
            admin {
                _id
            }
        }
    }
`;

export const PRESIGNURL = gql`
    mutation ($input: PresignedUrlInput!) {
        FetchPutPresignedUrl(input: $input) {
            key
            url
            extension
        }
    }
`;
