/* eslint-disable import/prefer-default-export */

import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'animalName',
        numeric: false,
        label: 'Name',
        align: 'left',
        sort: true
    },
    {
        id: 'animalType',
        numeric: false,
        label: 'Animal Type',
        align: 'left',
        sort: true
    },
    {
        id: 'breed',
        numeric: false,
        label: 'Breed',
        align: 'left',
        sort: true
    },
    {
        id: 'breedType',
        numeric: false,
        label: 'Breed Type',
        align: 'left',
        sort: true
    },
    {
        id: 'gender',
        numeric: false,
        label: 'Gender',
        align: 'left',
        sort: true
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];

export const headCellsPinAnimal: HeadCell1[] = [
    {
        id: 'sortAnimal',
        numeric: false,
        label: 'Sort',
        align: 'left',
        sort: false
    },
    {
        id: 'coverImage',
        numeric: false,
        label: 'Cover image',
        align: 'left',
        sort: false
    },
    {
        id: 'animalName',
        numeric: false,
        label: 'Name of Listing',
        align: 'left',
        sort: true
    },
    {
        id: 'species',
        numeric: false,
        label: 'Species',
        align: 'left',
        sort: true
    },
    {
        id: 'breedType',
        numeric: false,
        label: 'Breed',
        align: 'left',
        sort: true
    },
    {
        id: 'desexed',
        numeric: false,
        label: 'Desexed',
        align: 'left',
        sort: true
    },
    {
        id: 'gender',
        numeric: false,
        label: 'Gender',
        align: 'left',
        sort: true
    },
    {
        id: 'price',
        numeric: false,
        label: 'Price',
        align: 'left',
        sort: true
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];

export const defaultAnimal = {
    _id: '',
    animalType: '',
    listingName: '',
    breed: '',
    gender: '',
    pureBred: '',
    createdAt: ''
};
