import { HeadCell1 } from 'types';

export const headCellsBreedersPin: HeadCell1[] = [
    {
        id: 'sortAnimal',
        numeric: false,
        label: 'Sort',
        align: 'left',
        sort: false
    },
    {
        id: 'profilePic',
        numeric: false,
        label: 'Profile Pic',
        align: 'left',
        sort: false
    },
    {
        id: 'firstName',
        numeric: false,
        label: 'First Name',
        align: 'left',
        sort: false
    },
    {
        id: 'lastName',
        numeric: false,
        label: 'Last Name',
        align: 'left',
        sort: false
    },
    {
        id: 'username',
        numeric: false,
        label: 'Username',
        align: 'left',
        sort: false
    },
    {
        id: 'email',
        numeric: false,
        label: 'Email',
        align: 'left',
        sort: false
    },
    {
        id: 'mobile',
        numeric: false,
        label: 'Mobile',
        align: 'left',
        sort: false
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'Created Date',
        align: 'left',
        sort: false
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'right',
        sort: false
    }
];

export const headCellsBreeders: HeadCell1[] = [
    {
        id: 'profilePic',
        numeric: false,
        label: 'Profile Pic',
        align: 'left',
        sort: false
    },
    {
        id: 'firstName',
        numeric: false,
        label: 'First Name',
        align: 'left',
        sort: false
    },
    {
        id: 'lastName',
        numeric: false,
        label: 'Last Name',
        align: 'left',
        sort: false
    },
    {
        id: 'username',
        numeric: false,
        label: 'Username',
        align: 'left',
        sort: false
    },
    {
        id: 'email',
        numeric: false,
        label: 'Email',
        align: 'left',
        sort: false
    },
    {
        id: 'mobile',
        numeric: false,
        label: 'Mobile',
        align: 'left',
        sort: false
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'Created Date',
        align: 'left',
        sort: false
    }
];

export const headCellsPinAnimal: HeadCell1[] = [
    {
        id: 'sortAnimal',
        numeric: false,
        label: 'Sort',
        align: 'left',
        sort: false
    },
    {
        id: 'coverImage',
        numeric: false,
        label: 'Cover image',
        align: 'left',
        sort: false
    },
    {
        id: 'animalName',
        numeric: false,
        label: 'Name of Listing',
        align: 'left',
        sort: true
    },
    {
        id: 'species',
        numeric: false,
        label: 'Species',
        align: 'left',
        sort: true
    },
    {
        id: 'breedType',
        numeric: false,
        label: 'Breed',
        align: 'left',
        sort: true
    },
    {
        id: 'desexed',
        numeric: false,
        label: 'Desexed',
        align: 'left',
        sort: true
    },
    {
        id: 'gender',
        numeric: false,
        label: 'Gender',
        align: 'left',
        sort: true
    },
    {
        id: 'price',
        numeric: false,
        label: 'Price',
        align: 'left',
        sort: true
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];

export const headCellsPinToAnimal: HeadCell1[] = [
    {
        id: 'coverImage',
        numeric: false,
        label: 'Cover image',
        align: 'left',
        sort: false
    },
    {
        id: 'listingName',
        numeric: false,
        label: 'Name of Listing',
        align: 'left',
        sort: true
    },
    {
        id: 'animalType',
        numeric: false,
        label: 'Species',
        align: 'left',
        sort: true
    },
    {
        id: 'breed',
        numeric: false,
        label: 'Breed',
        align: 'left',
        sort: true
    },
    {
        id: 'gender',
        numeric: false,
        label: 'Gender',
        align: 'left',
        sort: true
    },
    {
        id: 'createdAt',
        numeric: false,
        label: 'Date',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];
