import { gql } from '@apollo/client';

export const REMOVE_ENQUIRY_TEMP = gql`
    mutation ($input: DeleteEnquiryTempInput!) {
        deleteEnquiryTemp(input: $input) {
            message
        }
    }
`;

export const CREATE_ENQUIRY_TEMP = gql`
    mutation ($input: CreateFormTemplate!) {
        CreateEnquiryFormTemplate(input: $input) {
            message
        }
    }
`;

export const UPDATE_ENQUIRY_TEMP = gql`
    mutation ($input: EditFormTemplate!) {
        EditEnquiryFormTemplate(input: $input) {
            message
        }
    }
`;
