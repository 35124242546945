import { gql } from '@apollo/client';

export const GET_ALL_ANIMALS_TYPE = gql`
    query ($userId: String!, $input: GetAnimalKind!) {
        getAnimalTypeList(userId: $userId, input: $input) {
            message
            animalTypeList {
                _id
                animalType
                breed
                type
                requiresMicrochip
                animals {
                    _id
                    listingName
                    animalType
                    gender
                    price
                    breed
                    createdAt
                    kind
                    media {
                        file
                        caption
                        isCover
                    }
                }
            }
        }
    }
`;

export const GET_ALL_ANIMALS_BREED = gql`
    query GetAnimalByBreedList($userId: String!, $input: GetAnimalKind!, $forSale: Boolean!) {
        getAnimalByBreedList(userId: $userId, input: $input, forSale: $forSale) {
            animalByBreed {
                _id
                animalType
                breed
                type
                requiresMicrochip
                animals {
                    _id
                    listingName
                    animalType
                    kind
                    breed
                    gender
                    media {
                        file
                        caption
                        isCover
                    }
                    price
                    createdAt
                    services {
                        _id
                        listingType
                    }
                    serviceType
                }
            }
        }
    }
`;

export const GET_ALL_ANIMALS_BY_USER = gql`
    query ($input: GetAnimalListDTO!) {
        getAnimalList(input: $input) {
            message
            animalList {
                _id
                listingName
                createdAt
                media {
                    file
                    caption
                    isCover
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ALL_ANIMAL_DETAILS_VLA = gql`
    query ($input: ViewAnimalDetailVlaInput!) {
        ViewAnimalDetailVla(input: $input) {
            message
            animal {
                _id
                userId
                listingName
                media {
                    file
                    caption
                    isCover
                }
                father {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
                mother {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
                fatherGrandFather {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
                fatherGrandMother {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
                motherGrandFather {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
                motherGrandMother {
                    _id
                    listingName
                    media {
                        file
                        caption
                        isCover
                    }
                }
            }
        }
    }
`;

export const GET_ANIMALS = gql`
    query ($animalId: String!) {
        GetAnimalDetail(animalId: $animalId) {
            message
            animal {
                _id
                userId
                animalId
                animalType
                animalCategory
                animalSubCategory
                breed
                breederId
                category
                gender
                pureBred
                listingName
                description
                dateOfBirth
                microchipNumber
                identificationNumber
                geneticReports
                achievements
                structuralReports
                customFields {
                    title
                    detail
                    files
                }
                media {
                    file
                    caption
                    isCover
                }
                fatherId
                father {
                    media {
                        file
                        caption
                        isCover
                    }
                    listingName
                }
                fatherDetail {
                    media {
                        file
                        caption
                        isCover
                    }
                    listingName
                }
                motherId
                mother {
                    media {
                        file
                        caption
                        isCover
                    }
                    listingName
                }
                motherDetail {
                    media {
                        file
                        caption
                        isCover
                    }
                    listingName
                }
                geneticTraits
                suitedFor
                chickensBantam
                handRaised
            }
        }
    }
`;

export const PHOTOS_TAB = gql`
    query ($userId: String!, $input: PaginationInput!) {
        ListPhotosTab(userId: $userId, input: $input) {
            message
            resultMedia {
                _id
                userId
                media {
                    file
                    caption
                }
                date
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;
