import { gql } from '@apollo/client';

export const GET_ANIMAL_SERVICE_TEMPLATE = gql`
    query GetAnimalService($id: String!) {
        GetAnimalService(id: $id) {
            adsAmount
            adsDuration
            description
            animal
            displayName
            listingType
            enquiryPreference {
                enquiryValue
                enquiryType
            }
            price
            showOnProfile
            userId
        }
    }
`;

export const UPDATE_ANIMAL_SERVICE_TEMP = gql`
    mutation AnimalServiceUpdate($id: String!, $input: AnimalServiceInputCms!) {
        AnimalServiceUpdate(id: $id, body: $input) {
            message
        }
    }
`;

export const GET_ANIMAL_SERVICE_LISTING = gql`
    query Data($serviceId: String!) {
        GetAllAnimalServiceListingType(serviceId: $serviceId) {
            data {
                displayName
                name
                slug
            }
            message
        }
    }
`;

export const GET_SERVICE_ADS_AMOUNT = gql`
    query ($name: String!) {
        AnimalServiceAds(name: $name) {
            adsAmount
            adsDuration
        }
    }
`;

export const GET_SERVICE_DETAILS = gql`
    query GetAnimalServiceDetails($serviceId: String!) {
        GetAnimalServiceDetails(serviceId: $serviceId) {
            _id
            listingType
            displayName
            adsDuration
            adsAmount
            price
            description
            showOnProfile
            enquiryPreference {
                enquiryValue
                enquiryType
            }
            userId
            animal {
                _id
                animalType
                animalId
                breed
                pureBred
                age
                dateOfBirth
                gender
                listingName
                description
                motherId
                media {
                    isCover
                    caption
                    file
                }
                identificationNumber
                animalCategory
                animalSubCategory
                geneticTraits
                chickensBantam
                suitedFor
                handRaised
            }
        }
    }
`;
