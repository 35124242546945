import { useEffect, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from '@mui/material';
import { status } from '../constants/types';
import { IconCheckbox, IconChecked, IconRadio, IconRadioChecked } from '../../../constants/icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useGQL from 'hooks/useGQL';
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseIcon from '@mui/icons-material/Close';

type filterModalProps = {
    openDialog: boolean;
    getFilterValue: (d: string) => any;
    getLocationValue: (d: string[]) => any;
    handleDialogClose: () => void;
    clearFilters: () => void;
};

interface Suburb {
    locality: string;
    postcode: string;
    long: string;
    lat: string;
}

const FilterModal = ({ openDialog, getFilterValue, getLocationValue, handleDialogClose, clearFilters }: filterModalProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState(1);
    const { GET_ALL_STATE, GET_ALL_SUBURB } = useGQL();
    const { error, loading, data: stateData, refetch } = GET_ALL_STATE();
    const {
        error: suburbErr,
        loading: suburbLoading,
        data: suburbData,
        refetch: suburbRefetch
    } = GET_ALL_SUBURB({ page: currentPage, limit: 10 });

    const [selectedStates, setSelectedStates] = useState<string[]>([]);
    const [selectedSuburbs, setSelectedSuburbs] = useState<string[]>([]);
    const [allSuburbs, setAllSuburbs] = useState<Suburb[]>([]);
    const [localHasNextPage, setlocalHasNextPage] = useState(false);
    const [statusRadio, setStatusRadio] = useState('');

    const [expandedState, setExpandedState] = useState<string | null>(null);

    const fetchSuburb = async (stateName, statePage) => {
        const { data: nextPageData } = await suburbRefetch({
            listSuburbsInput: {
                state: stateName,
                page: statePage,
                limit: 10
            }
        });
        setAllSuburbs((prevAllSuburbs) => [...prevAllSuburbs, ...nextPageData.ListSuburbs.suburbsType]);
        setlocalHasNextPage(nextPageData.ListSuburbs.pagination.hasNextPage);
    };

    useEffect(() => {
        fetchSuburb(selectedStates[0], currentPage);
    }, [selectedStates, currentPage]);

    const onhandleSubmit = () => {
        getFilterValue(statusRadio);
        getLocationValue(selectedSuburbs);
        handleDialogClose();
    };

    const clearSelectedFilters = () => {
        setSelectedStates([]);
        setSelectedSuburbs([]);
        setStatusRadio('');
        setExpandedState(null);
        clearFilters();
    };

    const onChangeStatusRadio = (event) => {
        setStatusRadio(event.target.value);
    };

    const handleStateCheckboxChange = async (stateName: string) => {
        if (selectedStates.includes(stateName)) {
            setSelectedStates((prevSelected) => prevSelected.filter((state) => state !== stateName));
            setAllSuburbs([]);
            // collapse the accordion
            setExpandedState(null);
        } else {
            setSelectedStates([stateName]);
            setAllSuburbs([]);
            fetchSuburb(stateName, 1);
            // expand the accordion
            setExpandedState(stateName);
        }
    };

    const handleSuburbCheckboxChange = (event) => {
        setSelectedSuburbs((prevSelected) => {
            const suburbLocality = event.target.name;
            if (event.target.checked) {
                if (!prevSelected.includes(suburbLocality)) {
                    // not existed then add
                    return [...prevSelected, suburbLocality];
                }
            } else {
                return prevSelected.filter((suburb) => suburb !== suburbLocality);
            }
            return prevSelected;
        });
    };

    const handleChripDelete = (position) => {
        setSelectedSuburbs((prevSelected) => {
            const updatedSelected = prevSelected.filter((item, pos) => pos !== position);
            return updatedSelected;
        });
    };

    const nextPage = async () => {
        const totalPages = suburbData?.ListSuburbs?.pagination?.total;
        if (currentPage < totalPages) {
            try {
                const { data: nextPageData } = await suburbRefetch({
                    listSuburbsInput: {
                        state: selectedStates[0],
                        page: currentPage + 1,
                        limit: 10
                    }
                });
                setAllSuburbs((prevAllSuburbs) => [...prevAllSuburbs, ...nextPageData.ListSuburbs.suburbsType]);

                setCurrentPage(currentPage + 1);
                setlocalHasNextPage(nextPageData.ListSuburbs.pagination.hasNextPage);
            } catch {
                console.error('Error fetching data:', error);
            }
        }
    };

    const renderSuburbs = (state) => {
        return (
            <InfiniteScroll
                scrollableTarget={state}
                dataLength={allSuburbs.length || 0}
                next={nextPage}
                hasMore={localHasNextPage}
                loader={
                    <Stack height={60} justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Stack>
                }
            >
                {(allSuburbs || []).map((suburb: Suburb, index) => (
                    <>
                        <Stack key={index} className="suburbs-gap">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={suburb.locality}
                                        checked={selectedSuburbs.includes(suburb.locality)}
                                        value={suburb.locality}
                                        sx={{ padding: '1px', margin: '0 9px' }}
                                        icon={<IconCheckbox />}
                                        checkedIcon={<IconChecked />}
                                        onChange={handleSuburbCheckboxChange}
                                    />
                                }
                                label={`${suburb.locality}, ${suburb.postcode}`}
                            />
                        </Stack>
                    </>
                ))}
                {suburbLoading ? (
                    <Stack>
                        <CircularProgress />
                    </Stack>
                ) : (
                    ''
                )}
            </InfiniteScroll>
        );
    };

    return (
        <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
            {openDialog && (
                <>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }} style={{ fontSize: '18px' }}>
                        Filter Types
                        <Button size="small" variant="outlined" color="secondary" onClick={clearSelectedFilters}>
                            Clear Filter
                        </Button>
                    </DialogTitle>

                    <DialogContent>
                        <Stack direction="column" spacing={3}>
                            <Divider />
                            <Typography variant="h3" className="app-filter-typography">
                                Account Status
                            </Typography>
                            <FormControl sx={{ mt: '1rem!important' }}>
                                <RadioGroup aria-labelledby="status" defaultValue="active" name="status">
                                    {status.map((option, index) => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Radio
                                                    name="status"
                                                    value={option.value}
                                                    checked={option.value === statusRadio}
                                                    icon={<IconRadio />}
                                                    checkedIcon={<IconRadioChecked />}
                                                />
                                            }
                                            label={option.label}
                                            onChange={onChangeStatusRadio}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>

                            <Divider sx={{ mt: '10px!important' }} />
                            <Typography variant="h3" className="app-filter-typography">
                                Location
                            </Typography>

                            {stateData?.ListState.states.map((state, index) => (
                                <Stack key={state.state} className="location-accordion">
                                    {/* {index > 0 && <Divider sx={{ my: 2 }} />} */}
                                    <Accordion expanded={expandedState === state.state}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            onClick={() => handleStateCheckboxChange(state.state)} // Toggle accordion on click
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                py: 1
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={state.state}
                                                        checked={selectedStates.includes(state.state)}
                                                        icon={<IconCheckbox />}
                                                        checkedIcon={<IconChecked />}
                                                        onChange={() => handleStateCheckboxChange(state.state)}
                                                    />
                                                }
                                                label={state.state}
                                                sx={{ marginLeft: 0 }}
                                            />

                                            {/* <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // name={suburb.locality}
                                                        // checked={selectedSuburbs.includes(suburb.locality)}
                                                        // value={suburb.locality}
                                                        sx={{ padding: '1px', margin: '0 9px' }}
                                                        icon={<IconCheckbox />}
                                                        checkedIcon={<IconChecked />}
                                                        onChange={handleSuburbCheckboxChange}
                                                    />
                                                }
                                                // label={suburb.locality}
                                            /> */}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div id={state.state} style={{ height: '200px', overflowY: 'auto' }}>
                                                {renderSuburbs(state.state)}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Stack>
                            ))}
                            {selectedSuburbs.length > 0 && (
                                <Stack direction="row" flexWrap="wrap">
                                    {selectedSuburbs.map((urb: any, pos) => (
                                        <Chip
                                            icon={<CloseIcon onClick={() => handleChripDelete(pos)} />}
                                            variant="outlined"
                                            size="small"
                                            label={urb}
                                            className="location-chip"
                                        />
                                    ))}
                                </Stack>
                            )}
                            <Box sx={{ mt: '1rem' }}>
                                <Button
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={onhandleSubmit}
                                    sx={{ fontSize: '14px' }}
                                >
                                    Apply
                                </Button>
                            </Box>
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};
export default FilterModal;
