import { TableCellProps } from '@mui/material';
import { initialValues } from './variables';

export interface EnhancedTableHeadProps extends TableCellProps {
    headCells: HeadCell[];
    order: ArrangementOrder;
    orderBy: string;
    onRequestSort: (e: React.SyntheticEvent, p: string) => void;
}

export type ArrangementOrder = 'asc' | 'desc' | undefined;

export type HeadCell = {
    id: string;
    numeric: boolean;
    label: string;
    disablePadding?: string | boolean | undefined;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
    sort: boolean;
};

type Point = {
    type: string;
    coordinates: [number, number];
};

type Address = {
    fullAddress?: string;
    state?: string;
    suburb?: string;
    postCode?: number;
};

type Pagination = {
    total: number;
    hasNextPage: boolean;
};

export type AppUser = {
    _id?: string;
    __typename?: string;
    authProvider: string;
    authProviderId: string;
    password?: string;
    firstName: string;
    lastName: string;
    username: string;
    address?: Address;
    mobile?: string;
    email?: string;
    status?: string;
    about?: string;
    location?: Point;
    profileImage?: string;
    bannerImage?: string;
    facebookLink?: string;
    instagramLink?: string;
    website?: string;
    googleStaticMapImage?: string;
    lastLoggedInAt: Date;
    createdAt: Date;
    updatedAt: Date;
    verified: boolean;
    active: boolean;
    totalCredit: number;
    isParent: boolean;
    clubOrAssociations?: string;
};

export type GetAppUsersDTO = {
    input: {
        searchText?: string;
        orderBy?: string;
        order?: string;
        limit?: number;
        skip?: number;
    };
};

export interface CreateAppUserDTO {
    input: {
        firstName: string;
        lastName: string;
        address?: Address;
        location?: Point;
        about: string;
        email: string;
        mobile?: string;
        profileImage: string;
        bannerImage: string;
    };
}

export interface UpdateAppUserDTO {
    firstName?: string;
    lastName?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    address?: Address;
    location?: Point;
    bio?: string;
    profileImage?: string;
    bannerImage?: string;
}

export interface UpdateAppUserStatusDTO {
    active?: boolean;
    totalCredit?: number;
}

export interface IAppUser {
    message: string;
    user?: AppUser;
    users?: [AppUser];
    pagination?: Pagination;
}

export type UserResponse = {
    getAllAppUsers?: IAppUser;
    getAppUser?: IAppUser;
    GetAppSubUsers?: IAppUser;
    createAppUser?: IAppUser;
    updateAppUser?: IAppUser;
    deleteAppUser?: IAppUser;
};

export type FormInputType = typeof initialValues;

export enum AuthType {
    EMAIL = 'email',
    PHONE = 'phone'
}

// review state options
export const status = [
    {
        value: 'true',
        label: 'Active'
    },
    {
        value: 'false',
        label: 'Pending'
    }
];

export const AnimalGenderType = [
    {
        value: 'Any',
        label: 'Any'
    },
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    }
];

export type AnimalTypeResponse = {
    _id: string;
    animalType: string;
    breed: string[];
    type: string;
    requiresMicrochip: boolean;
};

export type AnimalTypeListResponse = {
    message: string;
    animalTypeList: [AnimalTypeResponse];
};

export type AnimalTypeKind = {
    kind: string;
};

export type AnimalListInput = {
    userId?: string;
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    kind?: string;
};
export type PaginateInput = {
    page?: number;
    limit?: number;
};

export type PhotosTabInput = {
    file?: string;
    caption?: string;
};

export type AddPhotosTabInput = {
    media?: [PhotosTabInput];
    userId?: string;
};

export type DeletePhotosTabInput = {
    userId?: string;
    _id?: string;
};

export type DeleteMyAccountInput = {
    userIdToMakeParent?: string;
    userIdToDelete: string;
};

export type SubscriptionByAdminInput = {
    subscriptionId?: string;
    userId: string;
};
