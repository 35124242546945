import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconPhoto } from '@tabler/icons';
import Spinner from 'components/spinner';
import { IconMoney, IconPets } from 'constants/icons';
import { useTabs } from 'hooks/common/useTabs';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PinBreeders from './breeders';
import { useNavigate } from 'react-router-dom';
import PinPets from './pin-pets';
import PinLivestock from './livestock-pinned';

const PinnedAnimals = () => {
    const [pinAnimal, setPinAnimal] = useState(false);
    const [pinValue, setPinValue] = useState<string>('pets');
    const { TabPanel, allyProps } = useTabs();
    const navigate = useNavigate();

    const tabsOption = [
        {
            label: 'Pets',
            icon: <IconPets />,
            caption: '',
            value: 'pets'
        },
        {
            label: 'Livestocks',
            icon: <IconMoney />,
            caption: '',
            value: 'livestocks'
        },
        {
            label: 'Breeders',
            icon: <IconPhoto />,
            caption: '',
            value: 'breeders'
        }
    ];

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setPinValue(newValue);
    };

    const title = () => {
        if (pinValue === 'breeders') {
            return 'Pinned Breeders';
        }
        if (pinValue === 'livestocks') {
            return 'Pinned Livestock';
        }

        return 'Pinned Pets';
    };

    return (
        <MainCard
            title={title()}
            content={false}
            secondary={
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                        if (pinValue === 'breeders') {
                            navigate('/pin/animals-breeders/list');
                        }
                        if (pinValue === 'pets') {
                            navigate('/pin/animals-breeders/pets-list');
                        }
                        if (pinValue === 'livestocks') {
                            navigate('/pin/animals-breeders/livestock-list');
                        }
                    }}
                >
                    {pinValue === 'breeders' && 'Pin Breeders'}
                    {pinValue === 'pets' && 'Pin Pets'}
                    {pinValue === 'livestocks' && 'Pin Livestock'}
                </Button>
            }
        >
            <Box p={2}>
                <Tabs value={pinValue} onChange={handleChange}>
                    {tabsOption.map((tab, index) => (
                        <Tab
                            key={index}
                            icon={tab.icon}
                            iconPosition="start"
                            value={tab.value}
                            label={
                                <Grid container direction="column">
                                    <Typography variant="subtitle1" color="inherit">
                                        {tab.label}
                                    </Typography>
                                    <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                        {tab.caption}
                                    </Typography>
                                </Grid>
                            }
                            {...allyProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>

            {pinValue === 'breeders' && <PinBreeders />}
            {pinValue === 'pets' && <PinPets />}
            {pinValue === 'livestocks' && <PinLivestock />}
        </MainCard>
    );
};

export default PinnedAnimals;
