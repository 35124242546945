import { TableCell, TableContainer as MuiTableContainer, TableHead as MuiTableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { HeadCell } from 'types/page';

type Children = {
    children: React.ReactElement;
};

type TableHeadProps = {
    headCells: HeadCell[];
};

const useTable = () => {
    const TableContainer = ({ children }: Children) => {
        return <MuiTableContainer>{children} </MuiTableContainer>;
    };

    const TableHead = ({ headCells }: TableHeadProps) => {
        return (
            <MuiTableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell key={headCell.id} id={headCell.id} align={headCell.align} padding={headCell.padding}>
                            <Box component="span">{headCell.label}</Box>
                        </TableCell>
                    ))}
                </TableRow>
            </MuiTableHead>
        );
    };

    return { TableContainer, TableHead };
};

export default useTable;
