import { gql } from '@apollo/client';

export const GET_ADMIN_LIST = gql`
    query ($input: GetAdminListDTO!) {
        getAdminList(input: $input) {
            message
            adminList {
                _id
                name
                email
                phone
                status
                role
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ADMIN = gql`
    query ($id: String!) {
        getAdmin(id: $id) {
            message
            admin {
                _id
                firstName
                lastName
                name
                phone
                email
                role
                status
                profileImage
            }
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query {
        getUserProfile {
            _id
            name
            email
            phone
            status
            role
            profileImage
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation ($refreshToken: String!) {
        refresh(refreshToken: $refreshToken) {
            admin {
                _id
                firstName
                lastName
                name
                phone
                email
                role
                status
                profileImage
            }
            accessToken
            refreshToken
        }
    }
`;
