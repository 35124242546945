import { gql } from '@apollo/client';

export const GET_PIN_BREEDS = gql`
    query GetAppPinBreeders($input: FilterPinnedOptions!) {
        GetAppPinBreeders(input: $input) {
            message
            pagination {
                total
                hasNextPage
            }
            users {
                _id
                about
                website
                verified
                username
                updatedAt
                totalCredit
                status
                profileImage
                pinOrder
                password
                mobile
                lastName
                lastLoggedInAt
                isParent
                instagramLink
                googleStaticMapImage
                firstName
                facebookLink
                email
                createdAt
                clubOrAssociations
                bio
                bannerImage
                authProviderId
                authProvider
                active
            }
        }
    }
`;

export const GET_BREEDERS = gql`
    query GetAppBreeders($input: FilterToPinOptions!) {
        GetAppBreeders(input: $input) {
            users {
                _id
                authProvider
                authProviderId
                password
                firstName
                username
                lastName
                email
                mobile
                bio
                status
                pinOrder
                profileImage
                updatedAt
                createdAt
                lastLoggedInAt
                about
                active
                verified
                website
                isParent
                clubOrAssociations
                totalCredit
                bannerImage
            }
            pagination {
                total
                hasNextPage
            }
            message
        }
    }
`;

export const PINNED_PETS = gql`
    query GetAppPinPets($input: FilterPinnedAnimalOptions!) {
        GetAppPinPets(input: $input) {
            message
            salesAnimalList {
                _id
                listingName
                animalType
                breed
                animalCategory
                animalSubCategory
                desexed
                gender
                price
                pureBred
                createdAt
                dateOfBirth
                media {
                    isCover
                    caption
                    file
                }
                pinOrder
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_PETS_LIST = gql`
    query GetAppPets($input: GetSalesAnimalListDTO!) {
        GetAppPets(input: $input) {
            message
            salesAnimalList {
                _id
                listingName
                animalType
                breed
                animalCategory
                animalSubCategory
                desexed
                gender
                price
                pureBred
                createdAt
                dateOfBirth
                media {
                    isCover
                    caption
                    file
                }
                pinOrder
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

// livestock

export const GET_APP_LIVESTOCK = gql`
    query GetAppLivestock($input: GetSalesAnimalListDTO!) {
        GetAppLivestock(input: $input) {
            message
            pagination {
                total
                hasNextPage
            }
            salesAnimalList {
                _id
                animalCategory
                animalSubCategory
                animalType
                breed
                createdAt
                dateOfBirth
                desexed
                gender
                media {
                    isCover
                    file
                    caption
                }
                listingName
                pinOrder
                price
                pureBred
            }
        }
    }
`;

export const GET_APP_PIN_LIVESTOCK = gql`
    query GetAppPinLivestock($input: FilterPinnedAnimalOptions!) {
        GetAppPinLivestock(input: $input) {
            message
            pagination {
                total
                hasNextPage
            }
            salesAnimalList {
                _id
                animalCategory
                animalSubCategory
                animalType
                breed
                createdAt
                dateOfBirth
                desexed
                gender
                listingName
                media {
                    isCover
                    file
                    caption
                }
                pinOrder
                price
                pureBred
            }
        }
    }
`;
