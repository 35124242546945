import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
    query GetNotificationList($input: GetNotificationListDTO!) {
        getNotificationList(input: $input) {
            message
            notificationList {
                _id
                id
                firstName
                lastName
                notificationMessage
                createdAt
                date
                redirectTo
                isRead
                notificationType
                profileImage
                relativeTime
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_NOTIFICATION_COUNTS = gql`
    query GetUnreadNotificationCount {
        getUnreadNotificationCount {
            totalUnreadNotifications
        }
    }
`;
