import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterModal from './forms/filterAppUser';

type Props = {
    debouncedSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void;
};

export const AppUserFilter = ({ debouncedSearch }: Props) => {
    return (
        <Grid item xs={6}>
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: '1rem' }} />
                        </InputAdornment>
                    )
                }}
                onChange={debouncedSearch}
                placeholder="Search"
                size="small"
            />
        </Grid>
    );
};
