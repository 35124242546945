// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import editorReducer from './slices/editor';
import authReducer from './slices/auth';
import tableReducer from './slices/table';
import pageReducer from './slices/page';
import formReducer from './slices/form';
import reportReducer from './slices/reportSlice';
import dashboardReducer from './slices/dashboard.stat.slice';
import livestockTableReducer from './slices/livestock-table.slice';
import petsTableReducer from './slices/pets-table.slice';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    editor: editorReducer,
    auth: authReducer,
    table: tableReducer,
    petsTable: petsTableReducer,
    livestockTable: livestockTableReducer,
    page: pageReducer,
    form: formReducer,
    report: reportReducer,
    dashboard: dashboardReducer
});

export default reducer;
