import { gql } from '@apollo/client';

export const UPDATE_ANIMALS = gql`
    mutation ($input: UpdateNewAnimalInput!) {
        UpdateAnimal(input: $input) {
            message
            animal {
                _id
                userId
                animalId
                listingName
                category
                animalType
                breed
                animalCategory
                animalSubCategory
                gender
                kind
                pureBred
                price
                desexed
                description
                dateOfBirthFormatted
                microchipNumber
                identificationNumber
                media {
                    file
                    caption
                    isCover
                }
                status
                geneticReports
                structuralReports
                achievements
                customFields {
                    title
                    detail
                    files
                }
                fatherId
                motherId
                geneticTraits
                suitedFor
                chickensBantam
                handRaised
            }
        }
    }
`;

export const PHOTOS_TAB_SAVE = gql`
    mutation ($input: AddPhotosTabInput!) {
        AddPhotosTab(addPhotosTabInput: $input) {
            message
            resultMedia {
                _id
                userId
                media {
                    file
                    caption
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const PHOTOS_GALLERY_DELETE = gql`
    mutation ($input: DeletePhotosTabInput!) {
        DeletePhotosTab(input: $input) {
            message
        }
    }
`;

export const CREATE_ANIMALS = gql`
    mutation ($input: CreateNewAnimalInput!) {
        CreateNewAnimal(input: $input) {
            message
            animal {
                _id
                userId
                animalId
                listingName
                category
                animalType
                breed
                animalCategory
                animalSubCategory
                gender
                kind
                pureBred
                description
                dateOfBirthFormatted
                microchipNumber
                identificationNumber
                media {
                    file
                    caption
                    isCover
                }
                status
                geneticReports
                structuralReports
                achievements
                customFields {
                    title
                    detail
                    files
                }
                fatherId
                motherId
                geneticTraits
                suitedFor
                chickensBantam
                handRaised
            }
        }
    }
`;

export const deleteAnimal = gql`
    mutation ($deleteAnimalInput: DeleteAnimalInput!) {
        DeleteAnimal(deleteAnimalInput: $deleteAnimalInput) {
            message
        }
    }
`;

export const USER_SUBSCRIBE = gql`
    mutation ($input: SubscriptionByAdminInput!) {
        SubscriptionByAdmin(input: $input) {
            message
        }
    }
`;
