import { gql } from '@apollo/client';

export const GET_ALL_NEW_USERS_AND_ANIMAL_SALES_STATS = gql`
    query GetNewAnimalSalesAndUserStats($input: NewAnimalSalesUserAndStatInput!) {
        getNewAnimalSalesAndUserStats(input: $input) {
            totalNewUsers
            totalNewAnimalSales
        }
    }
`;

export const GET_TOTAL_USER = gql`
    query GetTotalUserDashboard($input: GetTotalUserInput!) {
        GetTotalUserDashboard(input: $input) {
            total
        }
    }
`;

export const GET_TOTAL_NEW_USER = gql`
    query GetTotalNewUserDashboard($input: GetTotalUserInput!) {
        GetTotalNewUserDashboard(input: $input) {
            total
        }
    }
`;

export const GET_TOTAL_BREEDERS = gql`
    query GetTotalBreedersDashboard($input: GetTotalUserInput!) {
        GetTotalBreedersDashboard(input: $input) {
            total
        }
    }
`;

export const GET_TOTAL_NO_ANIMAL_USER = gql`
    query GetTotalNoAnimalUserDashboard($input: GetTotalUserInput!) {
        GetTotalNoAnimalUserDashboard(input: $input) {
            total
        }
    }
`;

export const GET_TOTAL_ANIMALS = gql`
    query GetTotalAnimalDashboard($input: GetTotalAnimalsInput!) {
        GetTotalAnimalDashboard(input: $input) {
            total
        }
    }
`;

export const GET_ANIMAL_BY_TYPE = gql`
    query GetTotalAnimalByTypeDashboard($input: GetTotalAnimalsInput!) {
        GetTotalAnimalByTypeDashboard(input: $input) {
            category
            count
        }
    }
`;

export const GET_TOTAL_REVENUE = gql`
    query GetClassifiedRevenue($filter: RevenueFilterInput!) {
        GetClassifiedRevenue(filter: $filter) {
            totalRevenue
            animalRevenue {
                ycCommissionTotal
                animalType
            }
            serviceSire {
                ycCommissionTotal
                animalType
            }
            serviceSemen {
                ycCommissionTotal
                animalType
            }
            serviceEmbryo {
                ycCommissionTotal
                animalType
            }
        }
    }
`;

export const GET_TOTAL_CLASSIFIED = gql`
    query GetClassifiedTotal($input: GetTotalAnimalsInput!) {
        GetClassifiedTotal(input: $input) {
            total
            animals {
                count
                category
            }
            sireData {
                count
                category
            }
            semenData {
                count
                category
            }
            embryoData {
                count
                category
            }
        }
    }
`;

export const GET_TOTAL_SALES = gql`
    query AnimalRevenue($filter: RevenueFilterInput!) {
        GetTotalSales(filter: $filter) {
            animalRevenue {
                animalType
                ycCommissionTotal
                totalInvoice
            }
            totalRevenue
            serviceSire {
                ycCommissionTotal
                totalInvoice
                animalType
            }
            serviceSemen {
                totalInvoice
                ycCommissionTotal
                animalType
            }
            serviceEmbryo {
                totalInvoice
                ycCommissionTotal
                animalType
            }
        }
    }
`;

export const GET_AVERAGE_TRANSACTION = gql`
    query GetAverageTransaction($filter: RevenueFilterInput!) {
        GetAverageTransaction(filter: $filter) {
            totalRevenue
            animalRevenue {
                animalType
                ycCommissionTotal
                totalInvoice
            }
            serviceSire {
                animalType
                ycCommissionTotal
                totalInvoice
            }
            serviceSemen {
                animalType
                ycCommissionTotal
                totalInvoice
            }
            serviceEmbryo {
                animalType
                ycCommissionTotal
                totalInvoice
            }
        }
    }
`;

export const GET_AVERAGE_BY_USER = gql`
    query GetAverageUserSales($filter: RevenueFilterInput!) {
        GetAverageUserSales(filter: $filter) {
            totalRevenue
            animalRevenue {
                totalInvoice
                ycCommissionTotal
                animalType
            }
            serviceSire {
                totalInvoice
                animalType
                ycCommissionTotal
            }
            serviceEmbryo {
                totalInvoice
                ycCommissionTotal
                animalType
            }
            serviceSemen {
                totalInvoice
                ycCommissionTotal
                animalType
            }
        }
    }
`;

export const GET_LIVE_USERS = gql`
    query GetLiveUserDashboard($input: GetTotalLiveUserInput!) {
        GetLiveUserDashboard(input: $input) {
            total
        }
    }
`;
