import React, { MouseEvent, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { IconCheckbox, IconChecked } from '../../../constants/icons';
import useGQL from 'hooks/useGQL';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { Formik } from 'formik';
import { setValues } from 'store/slices/form';
import { useNavigate } from 'react-router-dom';

const typeOptions = [
    { label: 'Basic input', value: 'BASICINPUT' },
    { label: 'Large input', value: 'LARGEINPUT' },
    { label: 'Dropdown', value: 'DROPDOWN' },
    { label: 'Checkbox', value: 'CHECKBOX' }
];

const AddEnquiryTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ADD_ENQUIRY_TEMP } = useGQL();
    const [handleCreate, { loading, data }] = ADD_ENQUIRY_TEMP();
    const [isEnquiryFormOpen, isSetEnquiryFormOpen] = useState(false);
    const [isEnquiryFormPreviewOpen, isSetEnquiryFormPreviewOpen] = useState(false);
    const handleClickOpen = () => {
        isSetEnquiryFormOpen(true);
        setFormFields({ fieldName: '', fieldType: '', items: [''] });
    };
    const handlePreviewOpen = () => {
        isSetEnquiryFormPreviewOpen(true);
    };

    const handleClickClose = () => {
        isSetEnquiryFormOpen(false);
    };
    const handlePreviewClose = () => {
        isSetEnquiryFormPreviewOpen(false);
    };

    const [formFields, setFormFields] = useState<any>([{ fieldName: '', fieldType: '', items: [''] }]);

    const [initialValues, setInitialValues] = useState<any>({
        name: '',
        dynamicFields: []
    });

    const handleAddFormField = (values, setFieldValue) => {
        const updatedDynamicFields = [
            ...values.dynamicFields,
            {
                fieldName: formFields.fieldName,
                fieldType: formFields.fieldType,
                items: formFields.items
            }
        ];

        setFieldValue('dynamicFields', updatedDynamicFields);

        handleClickClose();
    };

    const handleRemoveField = (setFieldValue, values, index) => {
        const updatedFields = [...values.dynamicFields];
        updatedFields.splice(index, 1);
        setFieldValue('dynamicFields', [...updatedFields]);
    };

    const handleAddNew = () => {
        setFormFields({ ...formFields, items: [...formFields.items, ''] });
    };

    const handleRemoveItem = (indexToRemove) => {
        const updatedItems = formFields.items.filter((item, index) => index !== indexToRemove);
        setFormFields({
            ...formFields,
            items: updatedItems
        });
    };

    const handleOnSubmit = async (values, { setSubmitting }) => {
        const updateParam: any = { name: values.name, dynamicFields: values.dynamicFields };
        try {
            const response: any = await handleCreate({
                variables: {
                    input: updateParam
                }
            });
            if (response?.data?.CreateEnquiryFormTemplate?.message === 'Enquiry template created sucessfully.') {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${response?.data?.CreateEnquiryFormTemplate?.message || 'Enquiry template created sucessfully.'}`,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            }
            setTimeout(() => {
                navigate(`/enquiryform-templates/list`);
            }, 2000);
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.message || 'Template addition failed. Try again...',
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            setSubmitting(false);
        }
    };

    return (
        <>
            <MainCard className="enquiry-content" title="Add new form template" style={{ padding: '0' }}>
                <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
                    {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack rowGap="18px" sx={{ maxWidth: '543px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="name">Form name</InputLabel>
                                        <TextField
                                            placeholder="Form Name"
                                            value={values.name}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            size="small"
                                            type="text"
                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error id="name-error">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <Typography variant="h3" fontSize="18px" color="#45110B">
                                        Form details
                                    </Typography>

                                    {values?.dynamicFields?.map((formField, index) => (
                                        <div className="add-field" key={index}>
                                            <Stack rowGap="18px">
                                                <FormControl fullWidth>
                                                    <Stack flexDirection="row">
                                                        <Box sx={{ width: 'calc(100% - 46px)' }}>
                                                            <TextField
                                                                placeholder="Name"
                                                                value={formField.fieldName}
                                                                name={`fieldName-${index}`}
                                                                // onBlur={handleBlur}
                                                                // onChange={handleChange}
                                                                type="text"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                            {touched.fieldName && errors.fieldName && (
                                                                <FormHelperText error id="name-error">
                                                                    {errors.fieldName}
                                                                </FormHelperText>
                                                            )}
                                                        </Box>
                                                        <IconButton
                                                            disableRipple
                                                            sx={{ width: '28px', marginLeft: '18px', padding: '0' }}
                                                            onClick={() => handleRemoveField(setFieldValue, values, index)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="28"
                                                                height="28"
                                                                viewBox="0 0 28 28"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M11.5 6H16.5C16.5 4.61929 15.3807 3.5 14 3.5C12.6193 3.5 11.5 4.61929 11.5 6ZM10 6C10 3.79086 11.7909 2 14 2C16.2091 2 18 3.79086 18 6H24.25C24.6642 6 25 6.33579 25 6.75C25 7.16421 24.6642 7.5 24.25 7.5H22.9401L21.7233 22.1029C21.5398 24.3057 19.6984 26 17.488 26H10.5123C8.3019 26 6.46053 24.3057 6.27695 22.103L5.05991 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75C3 6.33579 3.33579 6 3.75 6H10ZM7.77177 21.9784C7.89055 23.4037 9.08203 24.5 10.5123 24.5H17.488C18.9183 24.5 20.1097 23.4037 20.2285 21.9784L21.4349 7.5H6.56511L7.77177 21.9784ZM11.75 11C12.1642 11 12.5 11.3358 12.5 11.75V20.25C12.5 20.6642 12.1642 21 11.75 21C11.3358 21 11 20.6642 11 20.25V11.75C11 11.3358 11.3358 11 11.75 11ZM17 11.75C17 11.3358 16.6642 11 16.25 11C15.8358 11 15.5 11.3358 15.5 11.75V20.25C15.5 20.6642 15.8358 21 16.25 21C16.6642 21 17 20.6642 17 20.25V11.75Z"
                                                                    fill="#CB3E19"
                                                                />
                                                            </svg>
                                                        </IconButton>
                                                    </Stack>
                                                </FormControl>

                                                {/* <FormControl fullWidth>
                                            <Stack flexDirection="row">
                                                <Box sx={{ width: 'calc(100% - 46px)' }}>
                                                    <TextField
                                                        placeholder="Email"
                                                        value={values.formName}
                                                        name="largeInput"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                    /> */}
                                                {/* {touched.listingName && errors.listingName && (
                                        <FormHelperText error id="name-error">
                                            {errors.listingName}
                                        </FormHelperText>
                                    )} */}
                                                {/* </Box>
                                                <IconButton disableRipple sx={{ width: '28px', marginLeft: '18px', padding: '0' }}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M11.5 6H16.5C16.5 4.61929 15.3807 3.5 14 3.5C12.6193 3.5 11.5 4.61929 11.5 6ZM10 6C10 3.79086 11.7909 2 14 2C16.2091 2 18 3.79086 18 6H24.25C24.6642 6 25 6.33579 25 6.75C25 7.16421 24.6642 7.5 24.25 7.5H22.9401L21.7233 22.1029C21.5398 24.3057 19.6984 26 17.488 26H10.5123C8.3019 26 6.46053 24.3057 6.27695 22.103L5.05991 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75C3 6.33579 3.33579 6 3.75 6H10ZM7.77177 21.9784C7.89055 23.4037 9.08203 24.5 10.5123 24.5H17.488C18.9183 24.5 20.1097 23.4037 20.2285 21.9784L21.4349 7.5H6.56511L7.77177 21.9784ZM11.75 11C12.1642 11 12.5 11.3358 12.5 11.75V20.25C12.5 20.6642 12.1642 21 11.75 21C11.3358 21 11 20.6642 11 20.25V11.75C11 11.3358 11.3358 11 11.75 11ZM17 11.75C17 11.3358 16.6642 11 16.25 11C15.8358 11 15.5 11.3358 15.5 11.75V20.25C15.5 20.6642 15.8358 21 16.25 21C16.6642 21 17 20.6642 17 20.25V11.75Z"
                                                            fill="#CB3E19"
                                                        />
                                                    </svg>
                                                </IconButton>
                                            </Stack>
                                        </FormControl> */}
                                            </Stack>
                                        </div>
                                    ))}

                                    <Button
                                        disableRipple
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        fullWidth
                                        onClick={handleClickOpen}
                                        sx={{ color: '#45110B' }}
                                    >
                                        Add new field
                                    </Button>
                                </Stack>
                                <Stack flexDirection="row" justifyContent="flex-end" columnGap="22px" mt="40px">
                                    <Button variant="outlined" size="large" color="secondary" onClick={handlePreviewOpen}>
                                        View preview
                                    </Button>
                                    <Button variant="contained" size="large" color="secondary" type="submit">
                                        Save template
                                    </Button>
                                </Stack>
                                <Dialog open={isEnquiryFormOpen} onClose={handleClickClose} maxWidth="xs">
                                    <DialogTitle>Add new field</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Stack rowGap="24px">
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="fieldType">Type</InputLabel>
                                                    <Select
                                                        id="fieldType"
                                                        name="fieldType"
                                                        placeholder="Select"
                                                        value={formFields.fieldType}
                                                        // onChange={handleChange}
                                                        onChange={(event) => {
                                                            setFormFields({
                                                                // fieldName: values.fieldName,
                                                                ...formFields,
                                                                fieldType: event.target.value
                                                            });
                                                        }}
                                                        size="small"
                                                        input={<OutlinedInput />}
                                                        // MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                    >
                                                        {typeOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {(formFields.fieldType === 'BASICINPUT' ||
                                                    formFields.fieldType === 'DROPDOWN' ||
                                                    formFields.fieldType === 'CHECKBOX') && (
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="fieldName">Field text</InputLabel>
                                                        <TextField
                                                            placeholder="Field text"
                                                            value={formFields.fieldName}
                                                            name="fieldName"
                                                            onBlur={handleBlur}
                                                            // onChange={handleChange}
                                                            onChange={(event) => {
                                                                setFormFields({
                                                                    ...formFields,
                                                                    fieldName: event.target.value
                                                                    // fieldType: values.fieldType
                                                                });
                                                            }}
                                                            size="small"
                                                        />
                                                        {touched.fieldName && errors.fieldName && (
                                                            <FormHelperText error id="name-error">
                                                                {errors.fieldName}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                )}

                                                {formFields.fieldType === 'LARGEINPUT' && (
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="largeInput">Field text</InputLabel>
                                                        <TextField
                                                            placeholder="Large input"
                                                            value={formFields.fieldName}
                                                            name="largeInput"
                                                            onBlur={handleBlur}
                                                            // onChange={handleChange}
                                                            onChange={(event) => {
                                                                setFormFields({
                                                                    ...formFields,
                                                                    fieldName: event.target.value
                                                                    // fieldType: values.fieldType
                                                                });
                                                            }}
                                                            type="text"
                                                            size="small"
                                                            multiline
                                                            rows={1}
                                                        />
                                                        {touched.listingName && errors.listingName && (
                                                            <FormHelperText error id="name-error">
                                                                {errors.listingName}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                )}

                                                {formFields?.fieldType === 'DROPDOWN' && (
                                                    <>
                                                        <Typography variant="h5" color="#45110B">
                                                            Dropdown items
                                                        </Typography>
                                                        <FormControl>
                                                            <InputLabel htmlFor="largeInput">Dropdown name</InputLabel>
                                                            {formFields?.items?.map((item, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom: index !== formFields?.items.length - 1 ? '10px' : '0'
                                                                    }}
                                                                >
                                                                    <Stack flexDirection="row" alignItems="center">
                                                                        <Box sx={{ width: 'calc(100% - 46px)' }}>
                                                                            <TextField
                                                                                placeholder="Dropdown text"
                                                                                value={item}
                                                                                name="largeInput"
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) => {
                                                                                    const items = [...formFields.items];
                                                                                    items.splice(index, 1, event.target.value);
                                                                                    setFormFields({
                                                                                        ...formFields,
                                                                                        items: [...items]
                                                                                    });
                                                                                }}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                            />
                                                                        </Box>
                                                                        <IconButton
                                                                            sx={{ width: '28px', marginLeft: '18px', padding: '0' }}
                                                                            onClick={() => handleRemoveItem(index)}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="28"
                                                                                height="28"
                                                                                viewBox="0 0 28 28"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M11.5 6H16.5C16.5 4.61929 15.3807 3.5 14 3.5C12.6193 3.5 11.5 4.61929 11.5 6ZM10 6C10 3.79086 11.7909 2 14 2C16.2091 2 18 3.79086 18 6H24.25C24.6642 6 25 6.33579 25 6.75C25 7.16421 24.6642 7.5 24.25 7.5H22.9401L21.7233 22.1029C21.5398 24.3057 19.6984 26 17.488 26H10.5123C8.3019 26 6.46053 24.3057 6.27695 22.103L5.05991 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75C3 6.33579 3.33579 6 3.75 6H10ZM7.77177 21.9784C7.89055 23.4037 9.08203 24.5 10.5123 24.5H17.488C18.9183 24.5 20.1097 23.4037 20.2285 21.9784L21.4349 7.5H6.56511L7.77177 21.9784ZM11.75 11C12.1642 11 12.5 11.3358 12.5 11.75V20.25C12.5 20.6642 12.1642 21 11.75 21C11.3358 21 11 20.6642 11 20.25V11.75C11 11.3358 11.3358 11 11.75 11ZM17 11.75C17 11.3358 16.6642 11 16.25 11C15.8358 11 15.5 11.3358 15.5 11.75V20.25C15.5 20.6642 15.8358 21 16.25 21C16.6642 21 17 20.6642 17 20.25V11.75Z"
                                                                                    fill="#CB3E19"
                                                                                />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </div>
                                                            ))}
                                                        </FormControl>
                                                    </>
                                                )}

                                                {formFields?.fieldType === 'CHECKBOX' && (
                                                    <>
                                                        <Typography variant="h5" color="#45110B">
                                                            Checkbox items
                                                        </Typography>
                                                        <FormControl>
                                                            <InputLabel htmlFor="largeInput">Checkbox items</InputLabel>
                                                            {formFields?.items?.map((item, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom: index !== formFields?.items.length - 1 ? '10px' : '0'
                                                                    }}
                                                                >
                                                                    <Stack flexDirection="row" alignItems="center">
                                                                        <Box sx={{ width: 'calc(100% - 46px)' }}>
                                                                            <TextField
                                                                                placeholder="Checkbox text"
                                                                                value={item}
                                                                                name="largeInput"
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) => {
                                                                                    const items = [...formFields.items];
                                                                                    items.splice(index, 1, event.target.value);
                                                                                    setFormFields({
                                                                                        ...formFields,
                                                                                        items: [...items]
                                                                                    });
                                                                                }}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                            />
                                                                        </Box>
                                                                        <IconButton
                                                                            sx={{ width: '28px', marginLeft: '18px', padding: '0' }}
                                                                            onClick={() => handleRemoveItem(index)}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="28"
                                                                                height="28"
                                                                                viewBox="0 0 28 28"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M11.5 6H16.5C16.5 4.61929 15.3807 3.5 14 3.5C12.6193 3.5 11.5 4.61929 11.5 6ZM10 6C10 3.79086 11.7909 2 14 2C16.2091 2 18 3.79086 18 6H24.25C24.6642 6 25 6.33579 25 6.75C25 7.16421 24.6642 7.5 24.25 7.5H22.9401L21.7233 22.1029C21.5398 24.3057 19.6984 26 17.488 26H10.5123C8.3019 26 6.46053 24.3057 6.27695 22.103L5.05991 7.5H3.75C3.33579 7.5 3 7.16421 3 6.75C3 6.33579 3.33579 6 3.75 6H10ZM7.77177 21.9784C7.89055 23.4037 9.08203 24.5 10.5123 24.5H17.488C18.9183 24.5 20.1097 23.4037 20.2285 21.9784L21.4349 7.5H6.56511L7.77177 21.9784ZM11.75 11C12.1642 11 12.5 11.3358 12.5 11.75V20.25C12.5 20.6642 12.1642 21 11.75 21C11.3358 21 11 20.6642 11 20.25V11.75C11 11.3358 11.3358 11 11.75 11ZM17 11.75C17 11.3358 16.6642 11 16.25 11C15.8358 11 15.5 11.3358 15.5 11.75V20.25C15.5 20.6642 15.8358 21 16.25 21C16.6642 21 17 20.6642 17 20.25V11.75Z"
                                                                                    fill="#CB3E19"
                                                                                />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </div>
                                                            ))}
                                                        </FormControl>
                                                    </>
                                                )}

                                                {(formFields?.fieldType === 'DROPDOWN' || formFields?.fieldType === 'CHECKBOX') && (
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        startIcon={<AddIcon />}
                                                        disableRipple
                                                        fullWidth
                                                        onClick={handleAddNew}
                                                    >
                                                        Add new
                                                    </Button>
                                                )}

                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="large"
                                                    disableRipple
                                                    fullWidth
                                                    disabled={!formFields.fieldName}
                                                    onClick={() => handleAddFormField(values, setFieldValue)}
                                                >
                                                    Save
                                                </Button>
                                            </Stack>
                                        </DialogContentText>
                                    </DialogContent>

                                    <IconButton
                                        onClick={handleClickClose}
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            // textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: '#6A5757'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Dialog>

                                <Dialog open={isEnquiryFormPreviewOpen} onClose={handlePreviewClose} maxWidth="xs">
                                    <DialogTitle>Preview</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Stack rowGap="18px">
                                                <Typography variant="h3" fontSize="18px" color="#45110B">
                                                    {values?.name}
                                                </Typography>

                                                {values.dynamicFields?.map((dynamicField, index) => {
                                                    if (dynamicField.fieldType === 'BASICINPUT') {
                                                        return (
                                                            <FormControl fullWidth key={index}>
                                                                <InputLabel htmlFor="name">{dynamicField.fieldName}</InputLabel>
                                                                <TextField
                                                                    placeholder="Name"
                                                                    value={dynamicField.fieldName}
                                                                    name="name"
                                                                    // onBlur={handleBlur}
                                                                    // onChange={handleChange}
                                                                    size="small"
                                                                />
                                                            </FormControl>
                                                        );
                                                    }

                                                    if (dynamicField.fieldType === 'LARGEINPUT') {
                                                        return (
                                                            <FormControl fullWidth key={index}>
                                                                <InputLabel htmlFor="largeInput">{dynamicField.fieldName}</InputLabel>
                                                                <TextField
                                                                    placeholder="Large input"
                                                                    value={dynamicField.fieldName}
                                                                    name="largeInput"
                                                                    // onBlur={handleBlur}
                                                                    // onChange={handleChange}
                                                                    type="text"
                                                                    size="small"
                                                                    multiline
                                                                    rows={1}
                                                                />
                                                            </FormControl>
                                                        );
                                                    }

                                                    if (dynamicField.fieldType === 'DROPDOWN') {
                                                        return (
                                                            <FormControl fullWidth>
                                                                <InputLabel htmlFor="dropDownExample">{dynamicField.fieldName}</InputLabel>
                                                                <Select
                                                                    id="dropDownExample"
                                                                    name="dropDownExample"
                                                                    // className={values.enquiryType ? '' : 'GenderSelectPlaceholder'}
                                                                    placeholder="Select"
                                                                    // value={values.enquiryType}
                                                                    // onChange={(event) => {
                                                                    //     handleChange(event);
                                                                    //     handleEnquiryTypeChange(event);
                                                                    // }}
                                                                    size="small"
                                                                    input={<OutlinedInput />}
                                                                    // MenuProps={MenuProps}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    fullWidth
                                                                >
                                                                    {dynamicField?.items?.map((option) => (
                                                                        // {console.log(option, 'option')}
                                                                        <MenuItem key={option} value={option}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        );
                                                    }

                                                    if (dynamicField.fieldType === 'CHECKBOX') {
                                                        return (
                                                            <FormGroup>
                                                                <InputLabel htmlFor="largeInput">{dynamicField.fieldName}</InputLabel>
                                                                {dynamicField?.items?.map((option) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                icon={<IconCheckbox />}
                                                                                checkedIcon={<IconChecked />}
                                                                                defaultChecked={false}
                                                                            />
                                                                        }
                                                                        label={option}
                                                                    />
                                                                ))}
                                                                {/* <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            icon={<IconCheckbox />}
                                                                            checkedIcon={<IconChecked />}
                                                                            color="secondary"
                                                                        />
                                                                    }
                                                                    label="No"
                                                                /> */}
                                                            </FormGroup>
                                                        );
                                                    }

                                                    // Handle other field types or provide a default case
                                                    return null;
                                                })}

                                                <Button variant="contained" color="secondary" disableRipple onClick={handlePreviewClose}>
                                                    Save template
                                                </Button>
                                            </Stack>
                                        </DialogContentText>
                                    </DialogContent>

                                    <IconButton
                                        onClick={handlePreviewClose}
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            // textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: '#6A5757'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Dialog>
                            </form>
                        );
                    }}
                </Formik>
            </MainCard>
        </>
    );
};
export default AddEnquiryTemplate;
