/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
import React, { Fragment, useEffect, useState } from 'react';
// material-ui
import {
    Divider,
    TextField,
    FormHelperText,
    Button,
    Typography,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Input,
    Stack,
    IconButton
} from '@mui/material';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { PhoneInput } from 'react-international-phone';
import { Box, display } from '@mui/system';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import { AppUser } from 'views/userManagement/appUsers/constants/types';

interface Preference {
    index: number;
    enquiryType: string;
    enquiryValue?: string | null;
}

interface Props {
    handleChange: (index: number, value?: string | null) => void;
    handleAddField: (index: number) => void;
    handelTypeChange: (index: number, type?: string | null) => void;
    values: Array<Preference>;
    errors: any;
    handleDelete: (index?: number | null) => void;
}

const enquiryOptions = [
    { label: 'Enquire In App', value: 'ENQUIRE_IN_APP' },
    { label: 'Text', value: 'TEXT' },
    { label: 'Phone', value: 'PHONE' },
    { label: 'Email', value: 'EMAIL' }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const ContactPreference: React.FC<Props> = ({ handleChange, handleDelete, handleAddField, handelTypeChange, values, errors }) => {
    const [newEnquiryOptions, setEnqueriyOptions] = useState(enquiryOptions);
    const dispatch = useDispatch();

    const getEnquiryPlaceholder = (enquiryType) => {
        if (enquiryType === 'EMAIL') {
            return 'Email';
        }
        if (enquiryType === 'TEXT' || enquiryType === 'PHONE') {
            return 'Phone';
        }
        return '';
    };

    useEffect(() => {
        const mappedVal = values.reduce((pre: string[], current) => {
            return [...pre, current.enquiryType];
        }, []);

        const newOption = enquiryOptions.filter((item) => !mappedVal.includes(item.value));

        setEnqueriyOptions(newOption);
    }, [values]);

    return (
        <Stack gap="6px">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <FormControl sx={{ marginBottom: '4px' }}>
                        <Typography variant="h3" component="div" marginTop="1rem" className="color-red h4">
                            Contact preferences
                        </Typography>
                    </FormControl>
                </Box>
                <Button
                    onClick={() => {
                        if (values.length < enquiryOptions.length) {
                            let index = 0;
                            const insideValue = values
                                ?.filter((item) => item.index !== null && item.index !== undefined)
                                .map((item) => item.index) || [0];
                            if (insideValue.length) index = Math.max.apply(Math, insideValue);
                            handleAddField(index + 1);
                        } else {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: "Can't select more enquiry  preferences",
                                    anchorOrigin: { horizontal: 'center' },
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    }
                                })
                            );
                        }
                    }}
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    Add Enquiry Field
                </Button>
            </Box>
            {values.map((value, key) => (
                <Fragment key={key}>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            // alignItems: 'center',
                            gap: '4'
                        }}
                    >
                        <Stack sx={{ width: '100%' }}>
                            <FormControl>
                                <Select
                                    id="enquiryType"
                                    label="Select"
                                    className={value ? '' : 'GenderSelectPlaceholder'}
                                    sx={{ marginBottom: '5px' }}
                                    placeholder="Select"
                                    value={value?.enquiryType || ''}
                                    onChange={(event) => {
                                        handelTypeChange(value.index, event.target.value);
                                    }}
                                    size="small"
                                    input={<OutlinedInput />}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    displayEmpty
                                    renderValue={value?.enquiryType !== '' ? undefined : () => 'Select'}
                                >
                                    {enquiryOptions
                                        .filter((option) => option.value === value.enquiryType)
                                        .map((option, idx) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    {newEnquiryOptions.map((option, idx) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {errors && errors[key]?.enquiryType && (
                                    <FormHelperText error id="enquiryValue-error">
                                        {errors[key]?.enquiryType}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {value?.enquiryType && value.enquiryType !== 'ENQUIRE_IN_APP' && (
                                <FormControl>
                                    {/* <InputLabel htmlFor="enquiryValue">
                                        {value?.enquiryType === 'Text' && 'Phone Number'}
                                        {value?.enquiryType === 'Phone' && 'Phone Number'}
                                        {value?.enquiryType === 'EMAIL' && 'Email Address'}
                                    </InputLabel> */}

                                    {value?.enquiryType === 'EMAIL' ? (
                                        <TextField
                                            size="small"
                                            id="enquiryValue"
                                            type={value.enquiryType === 'EMAIL' ? 'email' : 'text'}
                                            value={value?.enquiryValue || ''}
                                            onChange={(e) => {
                                                handleChange(value.index, e.target.value);
                                            }}
                                            placeholder={getEnquiryPlaceholder(value.enquiryType)}
                                        />
                                    ) : (
                                        <PhoneInput
                                            defaultCountry="ua"
                                            value={value.enquiryValue || ''}
                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            onChange={(phone) => {
                                                handleChange(value.index, phone);
                                            }}
                                            placeholder={getEnquiryPlaceholder(value.enquiryType)}
                                        />
                                    )}

                                    {errors && errors[key]?.enquiryValue && (
                                        <FormHelperText error id="enquiryValue-error">
                                            {errors[key]?.enquiryValue}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Stack>
                        {values && values?.length >= 2 && (
                            <Button
                                onClick={() => handleDelete(Number(value?.index))}
                                sx={{ display: 'flex', marginLeft: '4px', color: 'red' }}
                                variant="outlined"
                                color="error"
                            >
                                <DeleteOutline sx={{ color: 'red' }} />
                            </Button>
                        )}
                    </Stack>
                    <Divider sx={{ margin: '12px 0 12px 0' }} light />
                </Fragment>
            ))}
        </Stack>
    );
};
