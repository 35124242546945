/* eslint-disable prefer-promise-reject-errors */
// export const getVideoCover = (urlOfFIle: string, seekTo = 0.0, width = 800, height = 600): Promise<string> => {
//     return new Promise((resolve, reject) => {
//         try {
//             const videoPlayer = document.createElement('video');
//             videoPlayer.setAttribute('src', urlOfFIle);
//             videoPlayer.crossOrigin = 'Anonymous';
//             videoPlayer.autoplay = true;
//             videoPlayer.muted = true;

//             videoPlayer.addEventListener('loadeddata', () => {
//                 if (videoPlayer.duration < seekTo) {
//                     reject('Video is too short.');
//                     return;
//                 }
//                 const videoWidth = videoPlayer.videoWidth;
//                 const videoHeight = videoPlayer.videoHeight;
//                 const videoAspectRatio = videoWidth / videoHeight;

//                 const canvas = document.createElement('canvas');

//                 let canvasWidth = width;
//                 let canvasHeight = height;

//                 // Adjust canvas size to match video aspect ratio
//                 if (videoAspectRatio > 1) {
//                     canvasHeight = width / videoAspectRatio;
//                 } else {
//                     canvasWidth = height * videoAspectRatio;
//                 }

//                 canvas.width = canvasWidth;
//                 canvas.height = canvasHeight;

//                 videoPlayer.currentTime = seekTo;

//                 videoPlayer.addEventListener('seeked', () => {
//                     const ctx = canvas.getContext('2d');
//                     ctx?.drawImage(videoPlayer, 0, 0, canvasWidth, canvasHeight);
//                     resolve(canvas.toDataURL('image/png'));
//                 });
//             });

//             videoPlayer.load();
//         } catch (error) {
//             reject(error);
//         }
//     });
// };

// export const getVideoCover = (urlOfFile, seekTo = 0.0) => {
//     return new Promise((resolve, reject) => {
//         const videoPlayer = document.createElement('video');
//         videoPlayer.setAttribute('src', urlOfFile);
//         videoPlayer.crossOrigin = 'Anonymous';
//         videoPlayer.autoplay = true;
//         videoPlayer.muted = true;

//         videoPlayer.addEventListener('canplay', () => {
//             if (videoPlayer.duration < seekTo) {
//                 reject('Video is too short.');
//                 return;
//             }

//             videoPlayer.currentTime = seekTo;
//             videoPlayer.addEventListener('seeked', () => {
//                 const videoWidth = videoPlayer.videoWidth;
//                 const videoHeight = videoPlayer.videoHeight;
//                 const videoAspectRatio = videoWidth / videoHeight;

//                 const canvas = document.createElement('canvas');
//                 let canvasWidth = videoWidth;
//                 let canvasHeight = videoHeight;

//                 // Adjust canvas size to match video aspect ratio
//                 if (videoAspectRatio > 1) {
//                     canvasHeight = videoWidth / videoAspectRatio;
//                 } else {
//                     canvasWidth = videoHeight * videoAspectRatio;
//                 }

//                 canvas.width = canvasWidth;
//                 canvas.height = canvasHeight;

//                 const ctx = canvas.getContext('2d');
//                 ctx?.drawImage(videoPlayer, 0, 0, canvasWidth, canvasHeight);
//                 resolve(canvas.toDataURL('image/png'));
//             });
//         });

//         videoPlayer.load();
//     });
// };

export const getVideoCover = (videoURL, seekTo = 0, width = 800, height = 600) => {
    return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        videoElement.src = videoURL;
        videoElement.crossOrigin = 'Anonymous';
        videoElement.muted = true;
        videoElement.autoplay = true;

        videoElement.addEventListener('canplay', () => {
            if (videoElement.duration < seekTo) {
                reject('Video is too short.');
                // return;
            }
        });

        videoElement.currentTime = seekTo;
        videoElement.addEventListener('seeked', () => {
            const videoWidth = videoElement.videoWidth;
            const videoHeight = videoElement.videoHeight;
            const videoAspectRatio = videoWidth / videoHeight;
            console.log(videoWidth, videoHeight, videoAspectRatio, 'videoH');

            const canvas = document.createElement('canvas');
            let canvasWidth = width;
            let canvasHeight = height;

            if (videoAspectRatio > 1) {
                canvasHeight = width / videoAspectRatio;
            } else {
                canvasWidth = height * videoAspectRatio;
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(videoElement, 0, 0, canvasWidth, canvasHeight);
            resolve(canvas.toDataURL('image/png'));
        });

        videoElement.load();
    });
};
