import { TableCellProps } from '@mui/material';

export interface EnhancedTableHeadProps extends TableCellProps {
    headCells: HeadCell[];
    order: ArrangementOrder;
    orderBy: string;
    onRequestSort: (e: React.SyntheticEvent, p: string) => void;
}

export type ArrangementOrder = 'asc' | 'desc' | undefined;

export type HeadCell = {
    id: string;
    numeric: boolean;
    label: string;
    disablePadding?: string | boolean | undefined;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
    sort: boolean;
};

type Pagination = {
    total: number;
    hasNextPage: boolean;
};

export type SalesAnimals = {
    _id: string;
    listingName: string;
    animalType: string;
    breed: string;
    desexed: string;
    pureBred?: string;
    gender: string;
    price: number;
    dateOfBirth: Date;
    createdAt: Date;
    fatherId: string;
    fatherDetail: FatherDetail;
    motherId: string;
    motherDetail: MotherDetail;
};

export type FatherDetail = {
    listingName: string;
    image: string;
};

export type MotherDetail = {
    listingName: string;
    image: string;
};

export type AddSalesAnimals = {
    _id?: string;
    userId?: string;
    animalId?: string;
    listingName?: string;
    category?: string;
    animalType?: string;
    breed?: string;
    gender?: string;
    price?: number;
    pureBred?: string;
    desexed?: string;
    enquiryType?: string;
    enquiryValue?: string;
    showOnProfile?: false;
    description?: string;
    dateOfBirth?: string;
    dateOfBirthFormatted?: string;
    microchipNumber?: string;
    identificationNumber?: string;
    media?: Media;
    status?: string;
    fromDateLocal: null;
    toDateLocal: null;
};

export type Media = {
    file?: string;
    caption?: string;
    isCover?: boolean;
};

export type PriceRange = {
    min: number;
    max: number;
};

export type AgeRange = {
    min: number;
    minType: Type;
    max: number;
    maxType: Type;
};

export enum Type {
    YEARS = 'YEARS',
    MONTHS = 'MONTHS'
}

export type GetSalesAnimalListDTO = {
    input: {
        searchText?: string;
        orderBy?: string;
        order?: string;
        limit?: number;
        skip?: number;
        animalType?: string;
        animalCategory?: string;
        animalSubCategory?: string;
        breed?: [string];
        pureBred?: string;
        priceRange?: PriceRange;
        age: AgeRange;
        gender: string;
        desexed: string;
    };
};

export type ListAnimalTypeInput = {
    listAnimalTypeInput: {
        type?: string;
    };
};

export type ListBreedInput = {
    listBreedInput: {
        type?: string;
        animalType?: string;
    };
};

export type GetUsersAnimalsInput = {
    input: {
        orderBy?: string;
        order?: string;
        limit?: number;
        skip?: number;
        userId?: string;
        gender?: string;
    };
};

export const purebred = [
    {
        value: '',
        label: 'Any'
    },
    {
        value: 'purebred',
        label: 'Purebred'
    },
    {
        value: 'crossbred',
        label: 'Crossbred'
    }
];

export const gender = [
    {
        value: '',
        label: 'Any'
    },
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    }
];

export const desexed = [
    {
        value: '',
        label: 'Any'
    },
    {
        value: 'Yes',
        label: 'Yes'
    },
    {
        value: 'No',
        label: 'No'
    }
];

export const priceRange = [
    {
        value: 'min',
        label: 'Min'
    },
    {
        value: 'max',
        label: 'Max'
    }
];

export const age = [
    {
        value: '',
        label: 'Any'
    },
    {
        value: 'min',
        label: 'More than 6 months'
    },
    {
        value: 'max',
        label: 'Less than 6 months'
    }
];

export const showOnProfile = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
];

// export type FormInputType = typeof initialValues;
