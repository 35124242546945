import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterProps {
    startDate?: string | null;
    endDate?: string | null;
    filterKeyword?: string | null;
}
interface InitialStateProps {
    startDate?: string | null;
    endDate?: string | null;
    filterKeyword?: string | null;
}

const initialState: InitialStateProps = {
    startDate: null,
    endDate: null,
    filterKeyword: 'today'
};

export const dashBoardStatSlice = createSlice({
    name: 'dashboardStat',
    initialState,

    reducers: {
        setDashboardFilter: (state, action: PayloadAction<FilterProps>) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.filterKeyword = action.payload.filterKeyword;
        },

        resetDashboardFilter: () => initialState
    }
});

export const { resetDashboardFilter, setDashboardFilter } = dashBoardStatSlice.actions;

export const selectDashBoardStat = (state: any) => state.dashboard;

export default dashBoardStatSlice.reducer;
