/* eslint-disable no-underscore-dangle */
import {
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    InputLabel,
    Snackbar,
    Stack,
    TextField,
    Typography,
    IconButton
} from '@mui/material';
import SelectAnimalList from 'components/admins/AdminList/SelectAnimalList';
import { IconDismissSquare, IconEdit } from 'constants/icons';
import useImageUpload from 'hooks/common/useImageUpload';
import React, { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ImageCropperDialog from 'hooks/common/useImageCropperDialog';
import { v4 as uuidv4 } from 'uuid';

const AnimalDialog = ({
    title,
    openDialog,
    name,
    setField,
    placeholder,
    classes,
    handleDialogClose,
    gender,
    userId,
    clearChildFatherState,
    setClearChildFatherState,
    clearChildMotherState,
    setClearChildMotherState,
    values,
    setFatherLineagePhoto,
    setMotherLineagePhoto
}) => {
    const [file, setFile] = useState<Blob>(new Blob());
    console.log(file, 'file');
    const [animalListingName, setAnimalListingName] = useState('');
    console.log(animalListingName, 'name');
    const [selectAnimal, setSelectAnimal] = useState<any>({});
    console.log(selectAnimal, 'selectAnimal');
    const { getImageFromAWS } = useImageUpload();
    const [openCropper, setOpenCropper] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>(null);

    // const [validationError, setValidationError] = useState<any>();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [fatherUrl, setFatherUrl] = useState<any>('');
    const [motherUrl, setMotherUrl] = useState<any>('');
    console.log(motherUrl, 'motherUrl');
    const [fUrl, setFUrl] = useState<any>('');
    const [mUrl, setMUrl] = useState<any>('');

    const validateForm = () => {
        if (!animalListingName && file?.size === 0) {
            if (name === 'dam' || name === 'mother') {
                setField('motherDetail', {});
            }
            if (name === 'sire' || name === 'father') {
                setField('fatherDetail', {});
            }
        }
        if (!selectAnimal && file) {
            if (name === 'dam' || name === 'mother') {
                setField('mother', null);
                setField('motherId', null);
                setMotherLineagePhoto('');
            }
            if (name === 'sire' || name === 'father') {
                setField('father', null);
                setField('fatherId', null);
                setFatherLineagePhoto('');
            }
        }
        if (selectAnimal && Object.keys(selectAnimal).length !== 0 && (animalListingName || file?.size > 0)) {
            setSnackbarMessage('Both fields cannot be added on the same time.');
            setOpenSnackbar(true);
            return false;
        }
        if (name === 'sire' || name === 'father') {
            if (selectAnimal) {
                setField('fatherId', selectAnimal._id);
                setField('father', { image: fUrl, listingName: selectAnimal.listingName });
                setFatherLineagePhoto('');
            }

            if (animalListingName && !values?.father?.image && !values?.fatherDetail?.image && !file) {
                setSnackbarMessage('Image must be provided manually.');
                setOpenSnackbar(true);
                return false;
            }

            if (values?.fatherDetail?.media && (!file || file?.size === 0) && !selectAnimal) {
                setField('fatherDetail', {
                    media: values?.fatherDetail?.media,
                    listingName: animalListingName
                });
                setField('father', null);
            }

            if (file && file?.size > 0) {
                setField('fatherDetail', {
                    image: file,
                    listingName: animalListingName
                });
                setFatherLineagePhoto('');
            }
        }
        if (name === 'dam' || name === 'mother') {
            if (selectAnimal) {
                setField('motherId', selectAnimal._id);
                setField('mother', { image: mUrl, listingName: selectAnimal.listingName });
                setMotherLineagePhoto('');
            }

            if (animalListingName && !values?.mother?.image && !values?.motherDetail?.image && !file) {
                setSnackbarMessage('Image must be provided manually.');
                setOpenSnackbar(true);
                return false;
            }

            if (values?.motherDetail?.media && (!file || file?.size === 0) && !selectAnimal) {
                setField('motherDetail', {
                    media: values?.motherDetail?.media,
                    listingName: animalListingName
                });
                setField('mother', null);
            }

            if (file && file?.size > 0) {
                setField('motherDetail', {
                    image: file,
                    listingName: animalListingName
                });
                setMotherLineagePhoto('');
            }
        }
        setSnackbarMessage('');
        setOpenSnackbar(false);
        return true;
    };

    const handleSnackbarClose = () => {
        // Close the Snackbar when the user dismisses it
        setOpenSnackbar(false);
    };

    const onclickedContinue = (event) => {
        const isValid = validateForm();
        if (isValid) {
            handleDialogClose();
        }
    };

    const onclickedRemoveImage = () => {
        setFile(new Blob());
        if (name === 'dam' || name === 'mother') {
            // setField('motherDetail', {});
            setAnimalListingName('');
            setMotherUrl('');
        }
        if (name === 'sire' || name === 'father') {
            // setField('fatherDetail', {});
            setAnimalListingName('');
            setFatherUrl('');
        }
    };

    const handleCloseIcon = async () => {
        // if (name === 'dam' || name === 'mother') {
        //     await setField('motherDetail', null);
        //     await setField('motherId', null);
        //     await setField('mother', null);
        // }
        // await setSelectAnimal({});
        // if (name === 'sire' || name === 'father') {
        //     await setField('fatherDetail', null);
        //     await setField('father', null);
        //     await setField('fatherId', null);
        // }
        setFile(new Blob());
        setSelectAnimal({});
        setAnimalListingName('');
        setSelectedImage(null);
        handleDialogClose();
    };

    const handleCropperOpen = (media, imageURL) => {
        setSelectedImage(imageURL);
        setOpenCropper(true);
    };

    const handleCropperClose = () => {
        setOpenCropper(false);
    };

    const handleCroppedData = async (cropDataBlob) => {
        const fileData = new File([cropDataBlob], `${uuidv4()}-cropped_image.png`, { type: 'image/png' });
        if (name === 'sire' || name === 'father') {
            // await setField('fatherDetail', {
            //     image: fileData,
            //     listingName: animalListingName
            // });
            await setFile(fileData);
        } else if (name === 'dam' || name === 'mother') {
            // await setField('motherDetail', {
            //     image: fileData,
            //     listingName: animalListingName
            // });
            await setFile(fileData);
        }
    };

    // useEffect(() => {
    //     if (clearChildFatherState && (name === 'sire' || name === 'father')) {
    //         setSelectAnimal({});
    //         setAnimalListingName('');
    //         setFile(new Blob());
    //         setClearChildFatherState(false);
    //     } else if (clearChildMotherState && (name === 'dam' || name === 'mother')) {
    //         setSelectAnimal({});
    //         setAnimalListingName('');
    //         setFile(new Blob());
    //         setClearChildMotherState(false);
    //     }
    // }, [clearChildFatherState, clearChildMotherState]);

    useEffect(() => {
        const animalSelected = async () => {
            let furl = '';
            if (selectAnimal) {
                if (name === 'sire' || name === 'father') {
                    // eslint-disable-next-line no-underscore-dangle
                    // setField('fatherId', selectAnimal._id);
                    // await setField('fatherDetail', null);
                    if (selectAnimal?.media?.length >= 1) {
                        let selFatherImage = selectAnimal?.media[0];
                        const selFatherImageArray = selectAnimal?.media.filter((img) => img.isCover);
                        if (!selFatherImageArray) {
                            selFatherImage = selectAnimal?.media[0];
                        }
                        furl = await getImageFromAWS(`${selFatherImage.file}`);
                        setFUrl(furl);
                    }
                    if (selectAnimal?.image) {
                        setFUrl(selectAnimal?.image);
                    }
                    // setField('father', { image: furl, listingName: selectAnimal.listingName });
                    // setLineage(selectAnimal);
                } else if (name === 'dam' || name === 'mother') {
                    // eslint-disable-next-line no-underscore-dangle
                    // setField('motherId', selectAnimal._id);
                    // await setField('motherDetail', null);
                    if (selectAnimal?.media?.length >= 1) {
                        let selMotherImage = selectAnimal?.media[0];
                        const selMotherImageArray = selectAnimal?.media.filter((img) => img.isCover);
                        if (!selMotherImageArray) {
                            selMotherImage = selectAnimal?.media[0];
                        }
                        furl = await getImageFromAWS(`${selMotherImage.file}`);
                        setMUrl(furl);
                    }
                    if (selectAnimal?.image) {
                        setFUrl(selectAnimal?.image);
                    }
                    // setField('mother', { image: furl, listingName: selectAnimal.listingName });
                    // setLineage(selectAnimal);
                }
            }
        };
        animalSelected();
    }, [selectAnimal]);

    useEffect(() => {
        const fatherLineage = async () => {
            if (name === 'sire' || name === 'father') {
                if (values?.father && values?.fatherId) {
                    setSelectAnimal({
                        ...values?.father, // Copy existing father data
                        _id: values.fatherId // Set id property from fatherId
                    });
                    setAnimalListingName('');
                    setFatherUrl('');

                    //  else {
                    //     await setAnimalListingName(values?.father?.listingName);
                    //     if (values?.father?.media?.[0]?.file) {
                    //         const furl = await getImageFromAWS(values?.father?.media?.[0]?.file);
                    //         setFatherUrl(furl);
                    //         // setFile(values?.father?.media?.[0]?.file);
                    //         // await setField('father', {
                    //         //     media: values?.father?.media?.[0]?.file,
                    //         //     listingName: animalListingName
                    //         // });
                    //     }
                    // }
                }
                if (values?.fatherDetail) {
                    setAnimalListingName(values?.fatherDetail?.listingName);
                    if (values?.fatherDetail?.media) {
                        const furl = await getImageFromAWS(values?.fatherDetail?.media?.[0]?.file);
                        setFatherUrl(furl);
                    } else {
                        setFile(values?.fatherDetail?.image);
                    }
                }
            }
        };
        fatherLineage();
    }, [values?.father, values?.fatherId, values?.fatherDetail]);

    useEffect(() => {
        const motherLineage = async () => {
            if (name === 'dam' || name === 'mother') {
                if (values?.mother && values.motherId) {
                    setSelectAnimal({
                        ...values?.mother, // Copy existing mother data
                        _id: values.motherId // Set id property from motherId
                    });
                    setAnimalListingName('');
                    setMotherUrl('');
                    //  else {
                    //     await setAnimalListingName(values?.mother?.listingName);
                    //     if (values?.mother?.media?.[0]?.file) {
                    //         const furl = await getImageFromAWS(values?.mother?.media?.[0]?.file);
                    //         setMotherUrl(furl);
                    //         // setFile(values?.mother?.media?.[0]?.file);
                    //         // await setField('mother', {
                    //         //     media: values?.mother?.media?.[0]?.file,
                    //         //     listingName: animalListingName
                    //         // });
                    //     }
                    // }
                }
                if (values?.motherDetail) {
                    setAnimalListingName(values?.motherDetail?.listingName);
                    if (values?.motherDetail?.media) {
                        const furl = await getImageFromAWS(values?.motherDetail?.media?.[0]?.file);
                        setMotherUrl(furl);
                    } else {
                        setFile(values?.motherDetail?.image);
                    }
                }
            }
        };
        motherLineage();
    }, [values?.mother, values?.motherId, values?.motherDetail]);

    const imageURL = file ? URL.createObjectURL(file) : null;

    return (
        <Dialog open={openDialog} onClose={handleSnackbarClose} sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}>
            {openDialog && (
                <>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {title}
                        <IconButton aria-label="Close" color="secondary" onClick={() => handleCloseIcon()}>
                            <CloseIcon sx={{ color: '#cb3e19' }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Stack gap={2}>
                            <FormControl>
                                <Typography>Select animal</Typography>
                            </FormControl>

                            <FormControl>
                                <SelectAnimalList
                                    name={name}
                                    placeholder={placeholder}
                                    classes={classes}
                                    chosenAnimal={setSelectAnimal}
                                    preChosenAnimal={selectAnimal}
                                    gender={gender}
                                    userId={userId}
                                    setField={setField}
                                />
                            </FormControl>

                            <FormControl>
                                <Typography>OR</Typography>
                            </FormControl>

                            <FormControl>
                                <Typography variant="h5" component="div" marginTop="1rem" marginBottom="1rem">
                                    Add Basic animal information
                                </Typography>
                            </FormControl>

                            <Stack gap={2}>
                                <FormControl>
                                    <InputLabel htmlFor="animalListingName">Animal listing name</InputLabel>
                                    <TextField
                                        size="small"
                                        value={animalListingName}
                                        name="animalListingName"
                                        onChange={async (event) => {
                                            setAnimalListingName(event.target.value);
                                            // if (name === 'sire' || name === 'father') {
                                            //     if (file) {
                                            //         await setField('fatherDetail', { image: file, listingName: event.target.value });
                                            //     } else await setField('fatherDetail', { listingName: event.target.value });
                                            //     // await setField('father', {});
                                            //     // await setSelectAnimal({});
                                            // } else if (name === 'dam' || name === 'mother') {
                                            //     if (file) {
                                            //         await setField('motherDetail', { image: file, listingName: event.target.value });
                                            //     } else await setField('motherDetail', { listingName: event.target.value });
                                            //     // await setField('mother', {});
                                            //     // await setSelectAnimal({});
                                            // }
                                        }}
                                    />
                                </FormControl>

                                {(file?.size > 0 || fatherUrl || motherUrl) && (
                                    <FormControl>
                                        <Card sx={{ maxWidth: 345, display: 'inline-block', position: 'relative' }}>
                                            <Box
                                                component="span"
                                                onClick={onclickedRemoveImage}
                                                sx={{ position: 'absolute', top: 8, right: 10 }}
                                            >
                                                <IconDismissSquare />
                                            </Box>
                                            <Box
                                                component="span"
                                                className="image-upload-dialog-edit-icon"
                                                onClick={() => handleCropperOpen(file, imageURL)}
                                            >
                                                <IconEdit htmlColor="#CB3E19" />
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={`${fatherUrl || motherUrl || URL.createObjectURL(file)}`}
                                                alt=""
                                            />
                                        </Card>
                                    </FormControl>
                                )}

                                <FormControl>
                                    <Button
                                        variant="outlined"
                                        component={FormLabel}
                                        size="large"
                                        color="secondary"
                                        style={{ marginBottom: '10px', textTransform: 'inherit' }}
                                    >
                                        <input
                                            type="file"
                                            name="image"
                                            accept="image/png, image/gif, image/jpeg"
                                            id="image"
                                            onChange={async (event) => {
                                                invariant(event.target.files, 'file not empty');
                                                if (event.target.files[0]) {
                                                    const uploadFile = await event.target.files[0];
                                                    setFile(uploadFile);
                                                    // if (name === 'sire' || name === 'father') {
                                                    //     // await setField('father', {});
                                                    //     // await setField('fatherId', null);
                                                    //     await setField('fatherDetail', {
                                                    //         image: uploadFile,
                                                    //         listingName: animalListingName
                                                    //     });
                                                    // } else if (name === 'dam' || name === 'mother') {
                                                    //     // await setField('mother', {});
                                                    //     // await setField('motherId', null);
                                                    //     await setField('motherDetail', {
                                                    //         image: uploadFile,
                                                    //         listingName: animalListingName
                                                    //     });
                                                    // }
                                                }
                                            }}
                                            hidden
                                        />
                                        <span style={{ color: '#45110B' }}>Add media</span>
                                    </Button>
                                </FormControl>
                            </Stack>

                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                style={{ marginBottom: '10px' }}
                                onClick={onclickedContinue}
                            >
                                <span style={{ color: '#45110B' }}>Continue</span>
                            </Button>
                        </Stack>
                        <>
                            {openCropper && (
                                <ImageCropperDialog
                                    openDialog={openCropper}
                                    imageUrl={selectedImage}
                                    handleCroppedData={handleCroppedData}
                                    handleDialogClose={() => {
                                        handleCropperClose();
                                    }}
                                />
                            )}
                        </>
                    </DialogContent>
                </>
            )}
            <Snackbar open={openSnackbar} onClose={handleSnackbarClose} className="animal-alert">
                <MuiAlert elevation={6} variant="filled" severity="error">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Dialog>
    );
};

export default React.memo(AnimalDialog);
