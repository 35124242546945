// material-ui
import { Avatar, CircularProgress, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// assets
import { useMutation } from '@apollo/client';
import { Box } from '@mui/system';
import { SET_NOTIFICATION_READ } from 'grapqhl/notification/mutation';
import useImageUpload from 'hooks/common/useImageUpload';
import useGQL from 'hooks/useGQL';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationListItemProps } from './notification.types';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ countNotification, setCountNotification, setOpen }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState<Array<NotificationListItemProps>>([]);
    const { GET_ALL_NOTIFICATION } = useGQL();
    const { error: pgError, loading, data, refetch } = GET_ALL_NOTIFICATION();

    const { getImageFromAWS } = useImageUpload();

    // hasRead mutation
    const [setNotificationRead] = useMutation(SET_NOTIFICATION_READ);

    const getImage = async (imageLink: string) => {
        const image = await getImageFromAWS(imageLink);
        return image;
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            if (data?.getNotificationList?.notificationList) {
                let notificationData = JSON.parse(JSON.stringify(data?.getNotificationList?.notificationList));
                notificationData = await Promise.all(
                    notificationData?.map(async (notification, ind) => {
                        notification.profileImage = await getImageFromAWS(`${notification.profileImage}`);
                        return notification;
                    })
                );
                setNotifications(notificationData);
            }
        };
        fetchNotifications().catch(console.error);
    }, [data]);

    // handle notification click
    const handleNotificationClick = async (item: any) => {
        if (!item.isRead) {
            setCountNotification(countNotification - 1 < 0 ? 0 : countNotification - 1);

            try {
                await setNotificationRead({
                    variables: {
                        input: {
                            notificationId: item.id
                        }
                    }
                });

                refetch();
            } catch (error) {
                console.log('Cannot set notification to read.');
            }
        }
        if (item.notificationType === 'report') {
            navigate(item.redirectTo);
            setOpen(false);
        }
    };
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 300,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((item, index) => {
                return (
                    <ListItemWrapper
                        key={index}
                        sx={{
                            background: item?.isRead ? '#ffff' : '#F6F4EF',
                            mb: '3px',
                            borderRadius: '6px',
                            '&:hover': { background: '#E9E1D5' }
                        }}
                        onClick={() => handleNotificationClick(item)}
                    >
                        <ListItem alignItems="center" sx={{ gap: '10px', paddingInline: '8px !important' }}>
                            <ListItemAvatar>
                                {item?.profileImage && <Avatar src={item?.profileImage} sx={{ height: 50, width: 50 }} />}
                            </ListItemAvatar>
                            <Stack direction="column">
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#2B2929'
                                            }}
                                        >
                                            {item?.firstName} {item?.lastName}
                                        </Typography>
                                    }
                                />
                                <ListItemText>
                                    <Typography variant="caption" sx={{ color: '#040303', fontSize: '14px', fontWeight: 500 }}>
                                        {item?.notificationMessage}
                                    </Typography>
                                </ListItemText>
                                <ListItemText>
                                    <Typography variant="caption" sx={{ color: '#757575', fontSize: '12px', fontWeight: 500 }}>
                                        {item?.relativeTime}
                                    </Typography>
                                </ListItemText>
                            </Stack>
                        </ListItem>
                    </ListItemWrapper>
                );
            })}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: '400'
                }}
            >
                {!notifications?.length && !loading && <p>No Notification Available</p>}

                {loading && <CircularProgress color="secondary" />}
            </Box>
        </List>
    );
};

export default NotificationList;
