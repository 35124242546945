import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
    query GetSettings {
        getSettings {
            settings {
                _id
                slug
                title
                hide
                animalType
            }
        }
    }
`;
