import { gql } from '@apollo/client';

export const GET_ANIMAL_TYPES = gql`
    query GetAnimalCategoryStats {
        getAnimalCategoryStats {
            message
            data {
                _id
                name
                animalCount
            }
        }
    }
`;
