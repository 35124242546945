import { gql } from '@apollo/client';

export const GET_ALL_STATES = gql`
    query ($listStatesInput: ListStateInput!) {
        ListState(listStatesInput: $listStatesInput) {
            message
            states {
                state
                details {
                    postcode
                    locality
                    long
                    lat
                }
            }
        }
    }
`;

export const GET_ALL_SUBURBS = gql`
    query ($listSuburbsInput: ListSuburbsInput!) {
        ListSuburbs(listSuburbsInput: $listSuburbsInput) {
            message
            suburbsType {
                postcode
                locality
                long
                lat
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;
