import { Dialog, DialogContent, DialogTitle, Button, Stack } from '@mui/material';
import { createRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import CropperComponent from '../../components/cropper';

const ImageCropperDialog = ({ openDialog, handleDialogClose, imageUrl, handleCroppedData }) => {
    const [img, setImg] = useState(imageUrl);
    const onSaveCropped = async (blob) => {
        console.log('cropData', blob);
        handleCroppedData(blob);
        handleDialogClose();
    };

    return (
        <Dialog open={openDialog} onClose={handleDialogClose} disableEnforceFocus maxWidth="lg">
            {openDialog && (
                <>
                    <DialogTitle>Crop Image</DialogTitle>
                    <DialogContent>
                        <Stack>
                            <Stack style={{ width: '100%' }}>
                                <CropperComponent imageUrl={img} onSaveCropped={onSaveCropped} />
                            </Stack>
                            <Stack>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="success"
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    onClick={handleDialogClose}
                                >
                                    <span>Close</span>
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default ImageCropperDialog;
