// ycp routes

export const REPORTS_ROUTE = '/report-form/list';
export const REPORTS_DETAILS_ROUTE = '/report-form/list/:id';
export const ACCOUNT_SUBSCRIPTION_ROUTE = '/account-subscriptions/list';
export const ACCOUNT_SUBSCRIPTION_DETAILS_ROUTE = '/account-subscriptions/list/:id';
export const TRANSACTION_HISTORY_ROUTE = '/transaction-history/list';
export const TRANSACTION_HISTORY_DETAILS_ROUTE = '/transaction-history/list/:id';
export const NOTIFICATION_ROUTE = '/notification/list';
export const ACTIVITY_LOGS_ROUTE = '/activity-log/list';
export const ACTIVITY_LOGS_DETAIL_ROUTE = '/activity-log/list/:id';
export const PACKAGES_ROUTE = '/packages/list';
export const PACKAGES_ROUTE_DETAIL = '/packages/list/:id';
