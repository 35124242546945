import { gql } from '@apollo/client';

export const CREATE_SALES_ANIMALS = gql`
    mutation ($input: CreateOverallSalesAnimalInput!) {
        CreateOverallSalesAnimal(input: $input) {
            message
            animal {
                _id
                userId
                animalId
                listingName
                category
                animalType
                breed
                gender
                price
                desexed
                enquiryType
                enquiryValue
                showOnProfile
                description
                dateOfBirthFormatted
                microchipNumber
                picNumber
                enquiryPreference {
                    enquiryType
                    enquiryValue
                }
                identificationNumber
                media {
                    file
                    caption
                    isCover
                }
                status
            }
        }
    }
`;

export const UPDATE_SALES_ANIMALS = gql`
    mutation ($input: UpdateOverallSalesAnimalInput!) {
        UpdateOverallSalesAnimal(input: $input) {
            message
            animal {
                _id
                userId
                listingName
                category
                animalType
                breed
                gender
                price
                desexed
                enquiryType
                enquiryValue
                showOnProfile
                description
                dateOfBirthFormatted
                microchipNumber
                picNumber
                identificationNumber
                media {
                    file
                    caption
                    isCover
                }
                status
            }
        }
    }
`;
