import { gql } from '@apollo/client';

export const GET_ALL_ACTIVITY_LOGS = gql`
    query GetActivityLogsList($input: GetActivityLogsListDTO!) {
        getActivityLogsList(input: $input) {
            message
            activityLogsList {
                _id
                activity
                firstName
                lastName
                activityType
                createdAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ALL_ACTIVITY_LOGS_DETAILS = gql`
    query GetActivityLogsDetails($input: ActivityLogsIdInput!) {
        getActivityLogsDetails(input: $input) {
            firstName
            lastName
            adminId
            activityType
            changedData
            createdAt
            activity
        }
    }
`;
