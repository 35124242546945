import { gql } from '@apollo/client';

export const GET_ALL_ENQUIRY_TEMPLATES = gql`
    query ($input: GetAllEnquiryTemp!) {
        getAllEnquiryTemp(input: $input) {
            message
            enquiriesTemplates {
                _id
                name
                dynamicFields {
                    fieldName
                    fieldType
                }
                status
                createdAt
                updatedAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ENQUIRY_TEMPLATE = gql`
    query ($input: GetEnquiryTemp!) {
        getEnquiryTemp(input: $input) {
            enquiryTemplate {
                _id
                name
                dynamicFields {
                    fieldName
                    fieldType
                    items
                }
                status
                createdAt
                updatedAt
            }
        }
    }
`;
